import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { getAllOperations } from '../../actions/operation';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import Operations from '../../components/operations/Operations';

import STYLES from './OperationsPage.module.scss';
import { withHook } from '../../util/withHook';

class OperationsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    operations: instanceOf(List),
    operationsOrder: string,
    isDevModeOn: bool,
    isFetchingOperations: bool,
    getAllOperations: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    operations: new List(),
    operationsOrder: 'desc',
    isDevModeOn: false,
    isFetchingOperations: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.operationsTop = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/operations',
        title: 'Operations',
      });
    }

    this.props.getAllOperations();
  };

  getNextPage = () => {
    const { operations } = this.props;
    const { currentPage } = this.state;
    const pagingToken = operations.get(-1).get('paging_token');
    const operationsTop = ReactDOM.findDOMNode(this.operationsTop.current)
      .offsetTop;

    this.props.getAllOperations(pagingToken, 'desc');
    window.scrollTo(0, operationsTop - 20);
    this.setState({ currentPage: currentPage + 1 });
  };

  getPreviousPage = () => {
    const { operations } = this.props;
    const { currentPage } = this.state;
    const pagingToken = operations.get(0).get('paging_token');
    const operationsTop = ReactDOM.findDOMNode(this.operationsTop.current)
      .offsetTop;

    this.props.getAllOperations(pagingToken, 'asc');
    window.scrollTo(0, operationsTop - 20);
    this.setState({ currentPage: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Operations';
    const description =
      'A list containing all of the recent Operations on the Stellar cryptocurrency network';
    const operationsUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={operationsUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      operations,
      operationsOrder,
      isFetchingOperations,
      isDevModeOn,
      onToggleCodeModal,
    } = this.props;
    const { currentPage } = this.state;

    return (
      <div className={STYLES.OperationsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header} ref={this.operationsTop}>
                <h2>OPERATIONS</h2>
              </div>
              <div>
                <Operations
                  operations={operations}
                  operationsOrder={operationsOrder}
                  currentPage={currentPage}
                  isDevModeOn={isDevModeOn}
                  isMoreOperations={true}
                  isFetchingOperations={isFetchingOperations}
                  toggleCodeModal={onToggleCodeModal}
                  onGetNextPage={this.getNextPage}
                  onGetPreviousPage={this.getPreviousPage}
                />
                <div className={STYLES.adWrapBottom}>
                  <GoogleAd placement="Bottom" adSlot="7799510885" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  operations: SELECTORS.getAllOperations(state),
  operationsOrder: SELECTORS.getOperationsOrder(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
  isFetchingOperations: SELECTORS.getIsFetchingOperations(state),
});

const mapDispatchToProps = {
  getAllOperations,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperationsPage))
);
