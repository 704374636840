import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './OperationComponent.module.scss';

const Payment = ({ operation, matchId, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>PAYMENT</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm:ss a')}
    </p>
    <div className={STYLES.flexDetails}>
      Payment from{' '}
      <HashPopover
        path="accounts"
        hash={operation.get('from')}
        matchId={matchId}
      />
      to{' '}
      <HashPopover
        path="accounts"
        hash={operation.get('to')}
        matchId={matchId}
      />
      of {operation.get('amount')}{' '}
      {operation.get('asset_type') === 'native'
        ? 'XLM'
        : operation.get('asset_code')}
      .
    </div>
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

Payment.propTypes = {
  matchId: string,
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

Payment.defaultProps = {
  matchId: '',
  isDevModeOn: false,
};

export default Payment;
