import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import * as types from './types';

const getAllAssetsSuccess = ({ assets, assetsOrder }) => ({
  type: types.GET_ALL_ASSETS_SUCCESS,
  payload: {
    assets,
    assetsOrder,
    isFetchingAssets: false,
  },
});

const getAllAssetsError = error => ({
  type: types.GET_ALL_ASSETS_ERROR,
  payload: {
    error,
    isFetchingAssets: false,
  },
});

const requestAllAssets = () => ({
  type: types.REQUEST_ALL_ASSETS,
  payload: {
    isFetchingAssets: true,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const getAllAssets = (pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestAllAssets());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const assetOrder = order ? order : 'desc';

    const res = await server
      .assets()
      .cursor(cursor)
      .order(assetOrder)
      .limit(10)
      .call();

    dispatch(
      getAllAssetsSuccess({
        assets: res.records,
        assetsOrder: assetOrder,
      })
    );
  } catch (err) {
    dispatch(getAllAssetsError(err));
  }
};
