export default [
  {
    id: 1,
    title: 'DASHBOARD',
    content:
      'The Dashboard is designed to help give users a quick overview of the current state of the Stellar Network. New data visualization tools will continue to be added to help users consume the important information they are seeking in the most efficient and effective way.',
  },
  {
    id: 2,
    title: 'ACCOUNT',
    content:
      'The Account page is a very helpful resource for discovering the details of an account on the Stellar Network. After entering the public key into the search bar, the user will be brought to the account details page. On the account details page, the user will be able to view the current balance, operations, transactions, effects, payments, offers, signers, and much more statistics that are associated with that account.',
    linkTitle: 'Accounts',
    link: 'https://www.stellar.org/developers/guides/concepts/accounts.html',
  },
  {
    id: 3,
    title: 'OPERATIONS',
    content:
      'The Operations page contains a list of the most recent operations that have happened on the Stellar Network. The list is in descending order with the most recent operations first. Users can page through the list of operations or click on the operation id to see a more detailed view of that operation. The operations details page will include all of the effects and details associated with that operation.',
    linkTitle: 'Operations',
    link: 'https://www.stellar.org/developers/guides/concepts/operations.html',
  },
  {
    id: 4,
    title: 'TRANSACTIONS',
    content:
      'The Transactions page contains a list of the most recent transactions that have happened on the Stellar Network. The list is in descending order with the most recent transactions first. Users can page through the list of transactions or click on the transaction hash to see a more detailed view of that transaction. The transaction details page will include the operations, payments, effects, and details associated with that transaction.',
    linkTitle: 'Transactions',
    link:
      'https://www.stellar.org/developers/guides/concepts/transactions.html',
  },
  {
    id: 5,
    title: 'ASSETS',
    content:
      'The Assets page contains a list of the most recent assets that have been added to the Stellar Network. The list is in descending order with the most recent assets first. Continued development of this section looks to improve the ability for users to filter assets to more effectively narrow down the selection they are seeking.',
    linkTitle: 'Assets',
    link: 'https://www.stellar.org/developers/guides/concepts/assets.html',
  },
  {
    id: 6,
    title: 'LEDGERS',
    content:
      'The Ledgers page contains a list of the most recent ledgers that have been added to the Stellar Network. The list is in descending order with the most recent ledgers first. You can page through the list of ledgers or click on the ledger sequence to see a more detailed view of that ledger. The ledger details page will also include the operations, transactions, payments, effects, and details associated with that ledger.',
    linkTitle: 'Ledgers',
    link: 'https://www.stellar.org/developers/guides/concepts/ledger.html',
  },
];
