export const REQUEST_ALL_LEDGERS = 'REQUEST_ALL_LEDGERS';
export const GET_ALL_LEDGERS_SUCCESS = 'GET_ALL_LEDGERS_SUCCESS';
export const GET_ALL_LEDGERS_ERROR = 'GET_ALL_LEDGERS_ERROR';

export const REQUEST_LEDGER_DETAILS = 'REQUEST_LEDGER_DETAILS';
export const GET_LEDGER_DETAILS_SUCCESS = 'GET_LEDGER_DETAILS_SUCCESS';
export const GET_LEDGER_DETAILS_ERROR = 'GET_LEDGER_DETAILS_ERROR';

export const REQUEST_LEDGER_EFFECTS = 'REQUEST_LEDGER_EFFECTS';
export const GET_LEDGER_EFFECTS_SUCCESS = 'GET_LEDGER_EFFECTS_SUCCESS';
export const GET_LEDGER_EFFECTS_ERROR = 'GET_LEDGER_EFFECTS_ERROR';

export const REQUEST_LEDGER_OPERATIONS = 'REQUEST_LEDGER_OPERATIONS';
export const GET_LEDGER_OPERATIONS_SUCCESS = 'GET_LEDGER_OPERATIONS_SUCCESS';
export const GET_LEDGER_OPERATIONS_ERROR = 'GET_LEDGER_OPERATIONS_ERROR';

export const REQUEST_LEDGER_TRANSACTIONS = 'REQUEST_LEDGER_TRANSACTIONS';
export const GET_LEDGER_TRANSACTIONS_SUCCESS =
  'GET_LEDGER_TRANSACTIONS_SUCCESS';
export const GET_LEDGER_TRANSACTIONS_ERROR = 'GET_LEDGER_TRANSACTIONS_ERROR';

export const REQUEST_LEDGER_PAYMENTS = 'REQUEST_LEDGER_PAYMENTS';
export const GET_LEDGER_PAYMENTS_SUCCESS = 'GET_LEDGER_PAYMENTS_SUCCESS';
export const GET_LEDGER_PAYMENTS_ERROR = 'GET_LEDGER_PAYMENTS_ERROR';
