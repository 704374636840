import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import * as SELECTORS from './selectors';

import Sidebar from './components/Sidebar';
import SearchInput from './components/SearchInput';
import Footer from './components/Footer';

import STYLES from './App.module.scss';
import { Outlet, useLocation } from 'react-router-dom';

const determineSidebarClass = isSidebarOpen =>
  cx({
    [STYLES.sideBar]: true,
    [STYLES.sideBarOpen]: isSidebarOpen,
    [STYLES.sideBarClosed]: !isSidebarOpen,
  });

const App = ({ route, isSidebarOpen }) => {
  const location = useLocation();

  return (
    <div className={STYLES.App}>
      <div className={determineSidebarClass(isSidebarOpen)}>
        <div className={STYLES.sideBarInside}>
          <Sidebar location={location} />
        </div>
      </div>
      <div className={STYLES.blueBack} />
      <div className={STYLES.mainContent}>
        <div className={STYLES.searchContainer}>
          <SearchInput />
        </div>
        <div className={STYLES.pageWrapper}>
          <Outlet />
        </div>
        <div className={STYLES.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

App.propTypes = {
  route: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

App.defaultProps = {
  isSidebarOpen: false,
};

const mapStateToProps = state => ({
  isSidebarOpen: SELECTORS.getIsSidebarOpen(state),
});

export default connect(mapStateToProps)(App);
