import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_OPERATION_DETAILS,
  GET_OPERATION_DETAILS_ERROR,
  GET_OPERATION_DETAILS_SUCCESS,
} from './types';

const getOperationDetailsSuccess = operationDetails => ({
  type: GET_OPERATION_DETAILS_SUCCESS,
  payload: { operationDetails, isFetchingOperationDetails: false },
});

const getOperationDetailsError = error => ({
  type: GET_OPERATION_DETAILS_ERROR,
  payload: { error, isFetchingOperationDetails: false },
});

const requestOperationDetails = () => ({
  type: REQUEST_OPERATION_DETAILS,
  payload: { isFetchingOperationDetails: true },
});

export default operationId => async (dispatch, getState) => {
  dispatch(requestOperationDetails());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;

    const res = await server
      .operations()
      .operation(operationId)
      .call();

    dispatch(getOperationDetailsSuccess(res));
  } catch (err) {
    dispatch(getOperationDetailsError(err));
  }
};
