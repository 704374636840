import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_TRANSACTION_DETAILS,
  GET_TRANSACTION_DETAILS_ERROR,
  GET_TRANSACTION_DETAILS_SUCCESS,
} from './types';

const getTransactionDetailsSuccess = transactionDetails => ({
  type: GET_TRANSACTION_DETAILS_SUCCESS,
  payload: { transactionDetails, isFetchingTransactionDetails: false },
});

const getTransactionDetailsError = error => ({
  type: GET_TRANSACTION_DETAILS_ERROR,
  payload: { error, isFetchingTransactionDetails: false },
});

const requestTransactionDetails = () => ({
  type: REQUEST_TRANSACTION_DETAILS,
  payload: { isFetchingTransactionDetails: true },
});

export default transactionId => async (dispatch, getState) => {
  dispatch(requestTransactionDetails());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;

    const res = await server
      .transactions()
      .transaction(transactionId)
      .call();

    dispatch(getTransactionDetailsSuccess(res));
  } catch (err) {
    dispatch(getTransactionDetailsError(err));
  }
};
