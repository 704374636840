import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ACCOUNT_TRANSACTIONS,
  GET_ACCOUNT_TRANSACTIONS_ERROR,
  GET_ACCOUNT_TRANSACTIONS_SUCCESS,
} from './types';

const getAccountTransactionsSuccess = ({
  accountTransactions,
  accountTransactionsOrder,
  isMoreAccountTransactions,
}) => ({
  type: GET_ACCOUNT_TRANSACTIONS_SUCCESS,
  payload: {
    accountTransactions,
    accountTransactionsOrder,
    isMoreAccountTransactions,
    isFetchingAccountTransactions: false,
  },
});

const getAccountTransactionsError = error => ({
  type: GET_ACCOUNT_TRANSACTIONS_ERROR,
  payload: { error, isFetchingAccountTransactions: false },
});

const requestAccountTransactions = () => ({
  type: REQUEST_ACCOUNT_TRANSACTIONS,
  payload: { isFetchingAccountTransactions: true },
});

export default (accountId, pagingToken, order) => async (
  dispatch,
  getState,
  api
) => {
  dispatch(requestAccountTransactions());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const transactionOrder = order ? order : 'desc';

    const res = await server
      .transactions()
      .cursor(cursor)
      .order(transactionOrder)
      .limit(10)
      .forAccount(accountId)
      .call();
    const nextPage =
      transactionOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getAccountTransactionsSuccess({
        accountTransactions: res.records,
        accountTransactionsOrder: transactionOrder,
        isMoreAccountTransactions: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getAccountTransactionsError(err));
  }
};
