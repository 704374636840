import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_LEDGER_PAYMENTS,
  GET_LEDGER_PAYMENTS_ERROR,
  GET_LEDGER_PAYMENTS_SUCCESS,
} from './types';

const getLedgerPaymentsSuccess = ({
  ledgerPayments,
  ledgerPaymentsOrder,
  isMoreLedgerPayments,
}) => ({
  type: GET_LEDGER_PAYMENTS_SUCCESS,
  payload: {
    ledgerPayments,
    ledgerPaymentsOrder,
    isMoreLedgerPayments,
    isFetchingLedgerPayments: false,
  },
});

const getLedgerPaymentsError = error => ({
  type: GET_LEDGER_PAYMENTS_ERROR,
  payload: { error, isFetchingLedgerPayments: false },
});

const requestLedgerPayments = () => ({
  type: REQUEST_LEDGER_PAYMENTS,
  payload: { isFetchingLedgerPayments: true },
});

export default (ledgerId, pagingToken, order) => async (dispatch, getState) => {
  dispatch(requestLedgerPayments());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const paymentsOrder = order ? order : 'desc';

    const res = await server
      .payments()
      .cursor(cursor)
      .order(paymentsOrder)
      .limit(10)
      .forLedger(ledgerId)
      .call();
    const nextPage =
      paymentsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getLedgerPaymentsSuccess({
        ledgerPayments: res.records,
        ledgerPaymentsOrder: paymentsOrder,
        isMoreLedgerPayments: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getLedgerPaymentsError(err));
  }
};
