import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';

import { MdMenu, MdSearch } from 'react-icons/md';
import { GoCode } from 'react-icons/go';
import { FaTwitter, FaConnectdevelop } from 'react-icons/fa';

import { toggleSidebar } from '../actions/ui';
// import { toggleAuthModal, signOutUser } from "../actions/user";
import { getSearchValue } from '../actions/search';
import * as SELECTORS from '../selectors';
// import {
//   getUsername,
//   getCognitoUser,
//   getIsAuthenticated
// } from "../selectors/user";

// import AuthModal from "./AuthModal";

import STYLES from './SearchInput.module.scss';

class SearchInput extends Component {
  static propTypes = {
    isDevModeOn: bool,
    isSidebarOpen: bool,
    isTestNetEnabled: bool,
    dispatch: func.isRequired,
    onToggleSidebar: func.isRequired,
    onGetSearchValue: func.isRequired,
  };

  static defaultProps = {
    isDevModeOn: false,
    isSidebarOpen: false,
    isTestNetEnabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
    };

    this.handleTextInput = this.handleTextInput.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSidebar = this.handleSidebar.bind(this);
  }

  handleTextInput(e) {
    this.setState({
      inputValue: e.currentTarget.value || '',
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    const { onGetSearchValue } = this.props;

    onGetSearchValue(inputValue);
    this.setState({ inputValue: '' });
  }

  handleSidebar() {
    const { isSidebarOpen, onToggleSidebar } = this.props;

    onToggleSidebar(isSidebarOpen);
  }

  // toggleAuthModal = authState => {
  //   const { toggleAuthModal } = this.props;

  //   return toggleAuthModal({ isAuthModalOpen: true, authState });
  // };

  // signOutUser = () => {
  //   const { signOutUser } = this.props;

  //   return signOutUser();
  // };

  render() {
    const { inputValue } = this.state;
    const { isDevModeOn, isTestNetEnabled } = this.props;

    return (
      <div className={STYLES.SearchInput}>
        <div className={STYLES.burger} onClick={this.handleSidebar}>
          <MdMenu fill="#FFF" />
        </div>
        <form className={STYLES.form} onSubmit={this.handleFormSubmit}>
          <FormGroup
            className={STYLES.formGroup}
            controlId="searchInput"
            onSubmit={this.handleFormSubmit}>
            <FormControl
              type="text"
              value={inputValue}
              placeholder="Enter a public key, transaction hash, ledger sequence or operation id here"
              className={STYLES.formControl}
              onChange={this.handleTextInput}
            />
            <MdSearch
              onClick={this.handleFormSubmit}
              className={STYLES.searchGlass}
              fill="#FFF"
            />
          </FormGroup>
        </form>
        <div className={STYLES.socialIcons}>
          {isDevModeOn && <GoCode className={STYLES.settingsIcon} />}
          {isTestNetEnabled && (
            <FaConnectdevelop className={STYLES.settingsIcon} />
          )}
          {/* {!cognitoUser && (
            <Button
              size="sm"
              color="secondary"
              onClick={this.toggleAuthModal.bind(null, 'signIn')}>
              Login
            </Button>
          )}
          {!cognitoUser && (
            <Button
              size="sm"
              color="primary"
              onClick={this.toggleAuthModal.bind(null, 'signUp')}>
              SignUp
            </Button>
          )}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {username}
              <MdPerson />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={Link} to="/profile">
                Profile
              </DropdownItem>
              <DropdownItem onClick={this.signOutUser}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <a
            href="https://twitter.com/_StellarUp_"
            target="_blank"
            rel="noopener noreferrer">
            <FaTwitter className={STYLES.icon} fill="#FFF" />
          </a>
        </div>
        {/* <AuthModal /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // username: getUsername(state),
  // cognitoUser: getCognitoUser(state),
  // isAuthenticated: getIsAuthenticated(state),
  isSidebarOpen: SELECTORS.getIsSidebarOpen(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
  isTestNetEnabled: SELECTORS.getIsTestNetEnabled(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  onToggleSidebar: () => dispatch(toggleSidebar()),
  onGetSearchValue: searchInput => dispatch(getSearchValue(searchInput)),
  // signOutUser: () => dispatch(signOutUser()),
  // toggleAuthModal: props => dispatch(toggleAuthModal(props))
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SearchInput)
);
