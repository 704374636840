import React, { Component } from 'react';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import {
  getTransactionDetails,
  getTransactionEffects,
  getTransactionOperations,
  getTransactionPayments,
} from '../../actions/transaction';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import InfoToolTip from '../../components/InfoToolTip';
import GoogleAd from '../../components/GoogleAd';
import CodeModal from '../../components/CodeModal';
import CodeModalToggle from '../../components/CodeModalToggle';
import TabbedList from './components/TabbedList';
import DetailsSummary from './components/DetailsSummary';

import STYLES from './TransactionDetailsPage.module.scss';
import { withHook } from '../../util/withHook';

class TransactionDetailsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    transactionDetails: instanceOf(Map),
    transactionEffects: instanceOf(List),
    transactionOperations: instanceOf(List),
    transactionPayments: instanceOf(List),
    transactionEffectsOrder: string,
    transactionOperationsOrder: string,
    transactionPaymentsOrder: string,
    isMoreTransactionEffects: bool,
    isMoreTransactionOperations: bool,
    isMoreTransactionPayments: bool,
    isDevModeOn: bool,
    isFetchingTransactionDetails: bool,
    isFetchingTransactionEffects: bool,
    isFetchingTransactionOperations: bool,
    isFetchingTransactionPayments: bool,
    getTransactionEffects: func.isRequired,
    getTransactionDetails: func.isRequired,
    getTransactionOperations: func.isRequired,
    getTransactionPayments: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    transactionDetails: new Map(),
    transactionEffects: new List(),
    transactionOperations: new List(),
    transactionPayments: new List(),
    transactionEffectsOrder: 'desc',
    transactionOperationsOrder: 'desc',
    transactionPaymentsOrder: 'desc',
    isMoreTransactionEffects: false,
    isMoreTransactionOperations: false,
    isMoreTransactionPayments: false,
    isDevModeOn: false,
    isFetchingTransactionDetails: false,
    isFetchingTransactionEffects: false,
    isFetchingTransactionOperations: false,
    isFetchingTransactionPayments: false,
  };

  state = {
    transactionEffectsPage: 1,
    transactionPaymentsPage: 1,
    transactionOperationsPage: 1,
  };

  componentDidMount = () => {
    const {
      match: { params, url },
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: url,
        title: 'Transaction Details',
      });
    }

    this.props.getTransactionDetails(params.transactionId);
    this.props.getTransactionEffects(params.transactionId);
    this.props.getTransactionOperations(params.transactionId);
    this.props.getTransactionPayments(params.transactionId);
  };

  getNextPage = prop => {
    const {
      match: { params },
    } = this.props;

    const category = this.props[`transaction${prop}`];
    const currentPage = this.state[`transaction${prop}Page`];
    const pagingToken = category.get(-1).get('paging_token');

    this.props[`getTransaction${prop}`](
      params.transactionId,
      pagingToken,
      'desc'
    );
    this.setState({ [`transaction${prop}Page`]: currentPage + 1 });
  };

  getPreviousPage = prop => {
    const {
      match: { params },
    } = this.props;
    const category = this.props[`transaction${prop}`];
    const currentPage = this.state[`transaction${prop}Page`];
    const pagingToken = category.get(0).get('paging_token');

    this.props[`getTransaction${prop}`](
      params.transactionId,
      pagingToken,
      'asc'
    );
    this.setState({ [`transaction${prop}Page`]: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      transactionDetails,
      match: { url },
    } = this.props;

    const title = `StellarUp - Transaction ${transactionDetails &&
      transactionDetails.id}`;
    const description = `A detailed look into Stellar Lumens (XLM) cryptocurrency transaction ${transactionDetails &&
      transactionDetails.id}. Includes information on transaction operations, payments, effects, and more.`;
    const transactionUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={transactionUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      transactionEffectsPage,
      transactionPaymentsPage,
      transactionOperationsPage,
    } = this.state;
    const {
      transactionDetails,
      transactionEffects,
      transactionOperations,
      transactionPayments,
      transactionEffectsOrder,
      transactionOperationsOrder,
      transactionPaymentsOrder,
      isMoreTransactionEffects,
      isMoreTransactionOperations,
      isMoreTransactionPayments,
      isDevModeOn,
      isFetchingTransactionDetails,
      isFetchingTransactionEffects,
      isFetchingTransactionOperations,
      isFetchingTransactionPayments,
      onToggleCodeModal,
    } = this.props;

    return (
      <div className={STYLES.TransactionDetailsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>Transaction Details </h2>
                <InfoToolTip
                  title="Transaction"
                  info="Transactions are commands that modify the ledger state. Among other things, Transactions are used to send payments, enter orders into the distributed exchange, change settings on accounts, and authorize another account to hold your currency. If you think of the ledger as a database, then transactions are SQL commands."
                  link="https://www.stellar.org/developers/guides/concepts/transactions.html"
                  width="350px"
                  placement="bottom"
                  size="20px"
                />
                {isDevModeOn && (
                  <CodeModalToggle
                    id={transactionDetails && transactionDetails.get('id')}
                    toggleCodeModal={onToggleCodeModal}
                    buttonSize="large"
                  />
                )}
              </div>
              {isFetchingTransactionDetails ? (
                <div className={STYLES.detailsLoading}>
                  <div className="loading-spinner-animation" />
                </div>
              ) : (
                <div className={STYLES.detailWrap}>
                  <DetailsSummary transactionDetails={transactionDetails} />
                  <TabbedList
                    transactionEffects={transactionEffects}
                    transactionOperations={transactionOperations}
                    transactionPayments={transactionPayments}
                    transactionSignatures={
                      transactionDetails && transactionDetails.get('signatures')
                    }
                    transactionEffectsPage={transactionEffectsPage}
                    transactionOperationsPage={transactionOperationsPage}
                    transactionPaymentsPage={transactionPaymentsPage}
                    transactionEffectsOrder={transactionEffectsOrder}
                    transactionOperationsOrder={transactionOperationsOrder}
                    transactionPaymentsOrder={transactionPaymentsOrder}
                    isMoreTransactionEffects={isMoreTransactionEffects}
                    isMoreTransactionOperations={isMoreTransactionOperations}
                    isMoreTransactionPayments={isMoreTransactionPayments}
                    isDevModeOn={isDevModeOn}
                    isFetchingTransactionEffects={isFetchingTransactionEffects}
                    isFetchingTransactionOperation={
                      isFetchingTransactionOperations
                    }
                    isFetchingTransactionPayments={
                      isFetchingTransactionPayments
                    }
                    toggleCodeModal={onToggleCodeModal}
                    onGetNextPage={this.getNextPage}
                    onGetPreviousPage={this.getPreviousPage}
                  />
                  {isDevModeOn && (
                    <CodeModal
                      name="transactionDetails"
                      code={transactionDetails}
                    />
                  )}
                  <div className={STYLES.adWrapBottom}>
                    <GoogleAd placement="Bottom" adSlot="7799510885" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactionDetails: SELECTORS.getTransactionDetails(state),
  transactionEffects: SELECTORS.getTransactionEffects(state),
  transactionOperations: SELECTORS.getTransactionOperations(state),
  transactionPayments: SELECTORS.getTransactionPayments(state),
  transactionEffectsOrder: SELECTORS.getTransactionEffectsOrder(state),
  transactionOperationsOrder: SELECTORS.getTransactionOperationsOrder(state),
  transactionPaymentsOrder: SELECTORS.getTransactionPaymentsOrder(state),
  isMoreTransactionEffects: SELECTORS.getIsMoreTransactionEffects(state),
  isMoreTransactionOperations: SELECTORS.getIsMoreTransactionOperations(state),
  isMoreTransactionPayments: SELECTORS.getIsMoreTransactionPayments(state),
  isFetchingTransactionDetails: SELECTORS.getIsFetchingTransactionDetails(
    state
  ),
  isFetchingTransactionEffects: SELECTORS.getIsFetchingTransactionEffects(
    state
  ),
  isFetchingTransactionOperations: SELECTORS.getIsFetchingTransactionOperations(
    state
  ),
  isFetchingTransactionPayments: SELECTORS.getIsFetchingTransactionPayments(
    state
  ),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getTransactionDetails,
  getTransactionEffects,
  getTransactionOperations,
  getTransactionPayments,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsPage)
  )
);
