import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ACCOUNT_OFFERS,
  GET_ACCOUNT_OFFERS_ERROR,
  GET_ACCOUNT_OFFERS_SUCCESS,
} from './types';

const getAccountOffersSuccess = ({
  accountOffers,
  accountOffersOrder,
  isMoreAccountOffers,
}) => ({
  type: GET_ACCOUNT_OFFERS_SUCCESS,
  payload: {
    accountOffers,
    accountOffersOrder,
    isMoreAccountOffers,
    isFetchingAccountOffers: false,
  },
});

const getAccountOffersError = error => ({
  type: GET_ACCOUNT_OFFERS_ERROR,
  payload: { error, isFetchingAccountOffers: false },
});

const requestAccountOffers = () => ({
  type: REQUEST_ACCOUNT_OFFERS,
  payload: { isFetchingAccountOffers: true },
});

export default (accountId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestAccountOffers());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const offerOrder = order ? order : 'desc';

    const res = await server
      .offers('accounts', accountId)
      .cursor(cursor)
      .order(offerOrder)
      .limit(10)
      .call();
    const nextPage =
      offerOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getAccountOffersSuccess({
        accountOffers: res.records,
        accountOffersOrder: offerOrder,
        isMoreAccountOffers: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getAccountOffersError(err));
  }
};
