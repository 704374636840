import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './OperationComponent.module.scss';

const AccountMerge = ({ operation, matchId, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>ACCOUNT MERGE</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    <div className={STYLES.flexDetails}>
      Account{' '}
      <HashPopover
        path="accounts"
        hash={operation.get('account')}
        matchId={matchId}
      />{' '}
      merged into
      <HashPopover
        path="accounts"
        hash={operation.get('into')}
        matchId={matchId}
      />
    </div>
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

AccountMerge.propTypes = {
  matchId: string,
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

AccountMerge.defaultProps = {
  matchId: '',
  isDevModeOn: false,
};

export default AccountMerge;
