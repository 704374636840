import React, { Component } from 'react';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import STYLES from './PrivacyPolicyPage.module.scss';
import { withHook } from '../../util/withHook';

class PrivacyPolicyPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/privacy-policy',
        title: 'Privacy Policy',
      });
    }
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Privacy Policy';
    const description =
      'The Privacy Policy describes how your personal information is collected, used, and shared when you visit www.stellarup.com';
    const privacyPolicyUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={privacyPolicyUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    return (
      <div className={STYLES.PrivacyPolicyPage}>
        {this.renderHelmet()}
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>StellarUp Privacy Policy</h2>
              </div>
              <div className={STYLES.privacyWrap}>
                <div className={STYLES.privacyContent}>
                  <p>
                    This Privacy Policy describes how your personal information{' '}
                    is collected, used, and shared when you visit
                    www.stellarup.com.
                  </p>
                  <h4>Personal Information We Collect</h4>
                  <p>
                    When you visit the Site, we automatically collect certain{' '}
                    information about your device, including information about{' '}
                    your web browser, IP address, time zone, and some of the{' '}
                    cookies that are installed on your device. Additionally, as{' '}
                    you browse the Site, we collect information about the
                    individual web pages that you view, what websites or search
                    terms referred you to the Site, and information about how
                    you interact with the Site. We refer to this
                    automatically-collected information as “Device Information.”
                  </p>
                  <p>
                    We collect Device Information using the following
                    technologies:
                  </p>
                  <p>
                    - “Cookies” are data files that are placed on your device or{' '}
                    computer and often include an anonymous unique identifier.
                    For more information about cookies, and how to disable
                    cookies, visit{' '}
                    <a
                      href="http://www.allaboutcookies.org"
                      target="_blank"
                      rel="noopener noreferrer">
                      http://www.allaboutcookies.org
                    </a>
                    .
                  </p>
                  <p>
                    - “Log files” track actions occurring on the Site, and
                    collect data including your IP address, browser type,
                    Internet service provider, referring/exit pages, and
                    date/time stamps.
                  </p>
                  <p>
                    - “Web beacons,” “tags,” and “pixels” are electronic files
                    used to record information about how you browse the Site.
                  </p>
                  <p>
                    When we talk about “Personal Information” in this Privacy{' '}
                    Policy, we are talking about Device Information.
                  </p>
                  <h4>How Do We Use Your Personal Information?</h4>
                  <p>
                    We use the Device Information that we collect to help us{' '}
                    screen for potential risk and fraud (in particular, your IP
                    address), and more generally to improve and optimize our
                    Site (for example, by generating analytics about how our
                    users browse and interact with the Site, and to assess the
                    success of our marketing and advertising campaigns).
                  </p>
                  <h4>Sharing Your Personal Iinformation</h4>
                  <p>
                    We share your Personal Information with third parties to
                    help us use your Personal Information, as described above.
                    We use Google Analytics to help us understand how our users
                    use the Site--you can read more about how Google uses your
                    Personal Information here:{' '}
                    <a
                      href="https://www.google.com/intl/en/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer">
                      https://www.google.com/intl/en/policies/privacy/
                    </a>
                    . You can also opt-out of Google Analytics here:{' '}
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer">
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                    .
                  </p>
                  <p>
                    Finally, we may also share your Personal Information to
                    comply with applicable laws and regulations, to respond to a
                    subpoena, search warrant or other lawful request for
                    information we receive, or to otherwise protect our rights.
                  </p>
                  <h4>Do Not Track</h4>
                  <p>
                    Please note that we do not alter our Site’s data collection
                    and use practices when we see a Do Not Track signal from
                    your browser.
                  </p>
                  <p>
                    If you are a European resident, you have the right to access{' '}
                    personal information we hold about you and to ask that your
                    personal information be corrected, updated, or deleted. If
                    you would like to exercise this right, please contact us
                    through the contact information below.
                  </p>
                  <h4>Changes</h4>
                  <p>
                    We may update this privacy policy from time to time in order{' '}
                    to reflect, for example, changes to our practices or for
                    other operational, legal or regulatory reasons.
                  </p>
                  <h4>Contact Us</h4>
                  <p>
                    For more information about our privacy practices, if you
                    have questions, or if you would like to make a complaint,
                    please contact us by e-mail at{' '}
                    <a href="mailto:contact.stellarup@gmail.com">
                      contact.stellarup@gmail.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHook(injectIntl(PrivacyPolicyPage));
