import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_LEDGER_EFFECTS,
  GET_LEDGER_EFFECTS_ERROR,
  GET_LEDGER_EFFECTS_SUCCESS,
} from './types';

const getLedgerEffectsSuccess = ({
  ledgerEffects,
  ledgerEffectsOrder,
  isMoreLedgerEffects,
}) => ({
  type: GET_LEDGER_EFFECTS_SUCCESS,
  payload: {
    ledgerEffects,
    ledgerEffectsOrder,
    isMoreLedgerEffects,
    isFetchingLedgerEffects: false,
  },
});

const getLedgerEffectsError = error => ({
  type: GET_LEDGER_EFFECTS_ERROR,
  payload: { error, isFetchingLedgerEffects: false },
});

const requestLedgerEffects = () => ({
  type: REQUEST_LEDGER_EFFECTS,
  payload: { isFetchingLedgerEffects: true },
});

export default (ledgerId, pagingToken, order) => async (
  dispatch,
  getState,
  api
) => {
  dispatch(requestLedgerEffects());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const effectsOrder = order ? order : 'desc';

    const res = await server
      .effects()
      .cursor(cursor)
      .order(effectsOrder)
      .limit(10)
      .forLedger(ledgerId)
      .call();
    const nextPage =
      effectsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getLedgerEffectsSuccess({
        ledgerEffects: res.records,
        ledgerEffectsOrder: effectsOrder,
        isMoreLedgerEffects: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getLedgerEffectsError(err));
  }
};
