import React from 'react';
import { instanceOf } from 'prop-types';
import { List } from 'immutable';

import STYLES from './Balances.module.scss';

const Balances = ({ balances }) => (
  <div className={STYLES.Balances}>
    {balances && balances.size ? (
      <div>
        {balances.map((asset, i) => (
          <div className={STYLES.balanceRow} key={i}>
            <div className={STYLES.balanceItem}>
              <div className={STYLES.titleContainer}>
                <h4>
                  <span>
                    ASSET -{' '}
                    {asset.get('asset_type') === 'native'
                      ? 'XLM'
                      : asset.get('asset_code')}
                  </span>
                </h4>
                <div className={STYLES.label}>
                  AMOUNT {asset.get('balance')}
                </div>
              </div>
              <p className={STYLES.date}>
                Asset code:{' '}
                {asset.get('asset_type') === 'native'
                  ? 'XLM'
                  : asset.get('asset_code')}
              </p>
              <div className={STYLES.flexDetails}>
                This account currently holds {asset.get('balance')}{' '}
                {asset.get('asset_type') === 'native'
                  ? 'XLM'
                  : asset.get('asset_code')}
                .
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className={STYLES.noBalances}>
        <div className={STYLES.largeLabel}>
          There are no Balances for this item yet.
        </div>
      </div>
    )}
  </div>
);

Balances.propTypes = {
  balances: instanceOf(List),
};

export default Balances;
