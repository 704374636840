import axios from 'axios';
import queryString from 'query-string';
import {
  REQUEST_PROJECTS_SEARCH_RESULTS,
  GET_PROJECTS_SEARCH_RESULTS_ERROR,
  GET_PROJECTS_SEARCH_RESULTS_SUCCESS,
} from './types';

const getProjectsSRSuccess = ({ projectsSR, projectsSRCount }) => ({
  type: GET_PROJECTS_SEARCH_RESULTS_SUCCESS,
  payload: {
    projectsSR,
    projectsSRCount,
    isFetchingProjectsSR: false,
  },
});

const getProjectsSRError = error => ({
  type: GET_PROJECTS_SEARCH_RESULTS_ERROR,
  payload: {
    error,
    isFetchingProjectsSR: false,
  },
});

const requestProjectsSR = () => ({
  type: REQUEST_PROJECTS_SEARCH_RESULTS,
  payload: {
    isFetchingProjectsSR: true,
  },
});

const buildProjectsSRQueryString = queryParams =>
  Object.keys(queryParams)
    .map(key => queryString.stringify({ [key]: queryParams[key] }))
    .join('&');

export default queryParams => async dispatch => {
  dispatch(requestProjectsSR());
  let URL = `${process.env.REACT_APP_API_GATEWAY_URL}/projects`;

  if (queryParams) {
    URL += `?${buildProjectsSRQueryString(queryParams)}`;
  }

  try {
    const res = await axios({
      host: process.env.REACT_APP_API_GATEWAY_HOST,
      method: 'GET',
      url: URL,
      path: '/dev/projects',
    });

    dispatch(
      getProjectsSRSuccess({
        projectsSR: res.data.projects,
        projectsSRCount: res.data.projectsCount,
      })
    );
  } catch (err) {
    dispatch(getProjectsSRError(err));
  }
};
