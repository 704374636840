import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

export default history => {
  const routing = routerMiddleware(history);
  const store = createStore(
    reducers,
    {},
    compose(applyMiddleware(thunk, routing))
  );

  return store;
};
