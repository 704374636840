const getOperation = state => state.operation;
const getAsset = state => state.asset;
const getCoin = state => state.coin;
const getAccount = state => state.account;
const getTransaction = state => state.transaction;
const getLedger = state => state.ledger;
const getIntl = state => state.intl;
const getUI = state => state.ui;
const getDeveloper = state => state.developer;
// const getUser = state => state.user;
const getCommunity = state => state.community;

// Community
export const getProjects = state => getCommunity(state).get('projects');
export const getIsFetchingProjects = state =>
  getCommunity(state).get('isFetchingProjects');
export const getActiveProjectEditForm = state =>
  getCommunity(state).get('activeProjectEditForm');
export const getActiveProjectDeleteModal = state =>
  getCommunity(state).get('activeProjectDeleteModal');
export const getIsProjectCreateFormOpen = state =>
  getCommunity(state).get('isProjectCreateFormOpen');
export const getProjectsCount = state =>
  getCommunity(state).get('projectsCount');
export const getFeaturedProjects = state =>
  getCommunity(state).get('featuredProjects');
export const getIsFetchingFeaturedProjects = state =>
  getCommunity(state).get('isFetchingFeaturedProjects');
export const getProjectsSR = state => getCommunity(state).get('projectsSR');
export const getProjectsSRCount = state =>
  getCommunity(state).get('projectsSRCount');
export const getIsFetchingProjectsSR = state =>
  getCommunity(state).get('isFetchingProjectsSR');

// Developer
export const getRepoStats = state => getDeveloper(state).get('repoStats');
export const getIsDevModeOn = state => getDeveloper(state).get('isDevModeOn');
export const getIsTestNetEnabled = state =>
  getDeveloper(state).get('isTestNetEnabled');
export const getReposList = state => getDeveloper(state).get('reposList');
export const getActiveReposPage = state =>
  getDeveloper(state).get('activeReposPage');
export const getReposPerPage = state => getDeveloper(state).get('reposPerPage');
export const getReposLinks = state => getDeveloper(state).get('reposLinks');
export const getReposSortCategory = state =>
  getDeveloper(state).get('reposSortCategory');
export const getReposSortDirection = state =>
  getDeveloper(state).get('reposSortDirection');
export const getIsFetchingReposList = state =>
  getDeveloper(state).get('isFetchingReposList');

// Intl
export const getCurrentLocale = state => getIntl(state).get('locale');

// UI
export const getIsSidebarOpen = state => getUI(state).get('isSidebarOpen');
export const getGraphTimePeriod = state => getUI(state).get('graphTimePeriod');
export const getActiveCodeModal = state => getUI(state).get('activeCodeModal');

// Assets
export const getAllAssets = state => getAsset(state).get('assets');
export const getAssetsOrder = state => getAsset(state).get('assetsOrder');
export const getIsFetchingAssets = state =>
  getAsset(state).get('isFetchingAssets');

// Coins
export const getWeekHistory = state => getCoin(state).get('weekHistory');
export const getMonthHistory = state => getCoin(state).get('monthHistory');
export const getYearHistory = state => getCoin(state).get('yearHistory');
export const getCoinStats = state => getCoin(state).get('coinStats');
export const getIsFetchingCoinStats = state =>
  getCoin(state).get('isFetchingCoinStats');
export const getIsFetchingCoinHistory = state =>
  getCoin(state).get('isFetchingCoinHistory');

// Operations
export const getAllOperations = state => getOperation(state).get('operations');
export const getOperationsOrder = state =>
  getOperation(state).get('operationsOrder');
export const getOperationDetails = state =>
  getOperation(state).get('operationDetails');
// Operation Effects
export const getOperationEffects = state =>
  getOperation(state).get('operationEffects');
export const getOperationEffectsOrder = state =>
  getOperation(state).get('operationEffectsOrder');
export const getIsMoreOperationEffects = state =>
  getOperation(state).get('isMoreOperationEffects');
// Operation Fetching
export const getIsFetchingOperations = state =>
  getOperation(state).get('isFetchingOperations');
export const getIsFetchingOperationDetails = state =>
  getOperation(state).get('isFetchingOperationDetails');
export const getIsFetchingOperationEffects = state =>
  getOperation(state).get('isFetchingOperationEffects');

// Ledger
export const getLedgerDetails = state => getLedger(state).get('ledgerDetails');
export const getAllLedgers = state => getLedger(state).get('ledgers');
// Ledger Effects
export const getLedgerEffects = state => getLedger(state).get('ledgerEffects');
export const getLedgerEffectsOrder = state =>
  getLedger(state).get('ledgerEffectsOrder');
export const getIsMoreLedgerEffects = state =>
  getLedger(state).get('isMoreLedgerEffects');
// Ledger Operations
export const getLedgerOperations = state =>
  getLedger(state).get('ledgerOperations');
export const getLedgerOperationsOrder = state =>
  getLedger(state).get('ledgerOperationsOrder');
export const getIsMoreLedgerOperations = state =>
  getLedger(state).get('isMoreLedgerOperations');
// Ledger Transactions
export const getLedgerTransactions = state =>
  getLedger(state).get('ledgerTransactions');
export const getLedgerTransactionsOrder = state =>
  getLedger(state).get('ledgerTransactionsOrder');
export const getIsMoreLedgerTransactions = state =>
  getLedger(state).get('isMoreLedgerTransactions');
// Ledger Payments
export const getLedgerPayments = state =>
  getLedger(state).get('ledgerPayments');
export const getLedgerPaymentsOrder = state =>
  getLedger(state).get('ledgerPaymentsOrder');
export const getIsMoreLedgerPayments = state =>
  getLedger(state).get('isMoreLedgerPayments');

export const getLedgersOrder = state => getLedger(state).get('ledgersOrder');
// Ledger Fetching
export const getIsFetchingLedgers = state =>
  getLedger(state).get('isFetchingLedgers');
export const getIsFetchingLedgerDetails = state =>
  getLedger(state).get('isFetchingLedgerDetails');
export const getIsFetchingLedgerEffects = state =>
  getLedger(state).get('isFetchingLedgerEffects');
export const getIsFetchingLedgerOperations = state =>
  getLedger(state).get('isFetchingLedgerOperations');
export const getIsFetchingLedgerTransactions = state =>
  getLedger(state).get('isFetchingLedgerTransactions');
export const getIsFetchingLedgerPayments = state =>
  getLedger(state).get('isFetchingLedgerPayments');

// Account
export const getAccountDetails = state =>
  getAccount(state).get('accountDetails');
// Account Effects
export const getAccountEffects = state =>
  getAccount(state).get('accountEffects');
export const getAccountEffectsOrder = state =>
  getAccount(state).get('accountEffectsOrder');
export const getIsMoreAccountEffects = state =>
  getAccount(state).get('isMoreAccountEffects');
// Account Operations
export const getAccountOperations = state =>
  getAccount(state).get('accountOperations');
export const getAccountOperationsOrder = state =>
  getAccount(state).get('accountOperationsOrder');
export const getIsMoreAccountOperations = state =>
  getAccount(state).get('isMoreAccountOperations');
// Account Transactions
export const getAccountTransactions = state =>
  getAccount(state).get('accountTransactions');
export const getAccountTransactionsOrder = state =>
  getAccount(state).get('accountTransactionsOrder');
export const getIsMoreAccountTransactions = state =>
  getAccount(state).get('isMoreAccountTransactions');
// Account Payments
export const getAccountPayments = state =>
  getAccount(state).get('accountPayments');
export const getAccountPaymentsOrder = state =>
  getAccount(state).get('accountPaymentsOrder');
export const getIsMoreAccountPayments = state =>
  getAccount(state).get('isMoreAccountPayments');
// Account Offers
export const getAccountOffers = state => getAccount(state).get('accountOffers');
export const getAccountOffersOrder = state =>
  getAccount(state).get('accountOffersOrder');
export const getIsMoreAccountOffers = state =>
  getAccount(state).get('isMoreAccountOffers');
// Account Fetching
export const getIsFetchingAccountDetails = state =>
  getAccount(state).get('isFetchingAccountDetails');
export const getIsFetchingAccountEffects = state =>
  getAccount(state).get('isFetchingAccountEffects');
export const getIsFetchingAccountOperations = state =>
  getAccount(state).get('isFetchingAccountOperations');
export const getIsFetchingAccountTransactions = state =>
  getAccount(state).get('isFetchingAccountTransactions');
export const getIsFetchingAccountPayments = state =>
  getAccount(state).get('isFetchingAccountPayments');
export const getIsFetchingAccountOffers = state =>
  getAccount(state).get('isFetchingAccountOffers');

// Transaction
export const getTransactionDetails = state =>
  getTransaction(state).get('transactionDetails');
export const getAllTransactions = state =>
  getTransaction(state).get('transactions');
// Transaction Effects
export const getTransactionEffects = state =>
  getTransaction(state).get('transactionEffects');
export const getTransactionEffectsOrder = state =>
  getTransaction(state).get('transactionEffectsOrder');
export const getIsMoreTransactionEffects = state =>
  getTransaction(state).get('isMoreTransactionEffects');
// Transaction Operations
export const getTransactionOperations = state =>
  getTransaction(state).get('transactionOperations');
export const getTransactionOperationsOrder = state =>
  getTransaction(state).get('transactionOperationsOrder');
export const getIsMoreTransactionOperations = state =>
  getTransaction(state).get('isMoreTransactionOperations');
// Transaction Payments
export const getTransactionPayments = state =>
  getTransaction(state).get('transactionPayments');
export const getTransactionPaymentsOrder = state =>
  getTransaction(state).get('transactionPaymentsOrder');
export const getIsMoreTransactionPayments = state =>
  getTransaction(state).get('isMoreTransactionPayments');

export const getTransactionsOrder = state =>
  getTransaction(state).get('transactionsOrder');

// Transaction Fetching
export const getIsFetchingTransactions = state =>
  getTransaction(state).get('isFetchingTransactions');
export const getIsFetchingTransactionDetails = state =>
  getTransaction(state).get('isFetchingTransactionDetails');
export const getIsFetchingTransactionEffects = state =>
  getTransaction(state).get('isFetchingTransactionEffects');
export const getIsFetchingTransactionOperations = state =>
  getTransaction(state).get('isFetchingTransactionOperations');
export const getIsFetchingTransactionPayments = state =>
  getTransaction(state).get('isFetchingTransactionPayments');
