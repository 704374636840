import React from 'react';
import { number, string, bool, func } from 'prop-types';
import cx from 'classnames';

import STYLES from './AdvancedFilter.module.scss';

const sbcTagStyle = (sbc, value) =>
  cx({
    [STYLES.filterTag]: true,
    [STYLES.filterTagInactive]: sbc !== value,
    [STYLES.sbcTagActive]: sbc === value,
  });

const openSourceTagStyle = openSource =>
  cx({
    [STYLES.filterTag]: true,
    [STYLES.filterTagInactive]: openSource === false,
    [STYLES.openSourceTagActive]: openSource === true,
  });

const categoryTagStyle = (category, value) =>
  cx({
    [STYLES.filterTag]: true,
    [STYLES.filterTagInactive]: category !== value,
    [STYLES.categoryTagActive]: category === value,
  });

const AdvancedFilter = ({
  sbc,
  openSource,
  category,
  onFilterToggle,
  onFilterSelect,
}) => (
  <div className={STYLES.AdvancedFilter}>
    <div className={STYLES.advancedSearchBox}>
      <div className={STYLES.filterRow}>
        <div className={STYLES.licenseFilter}>
          <div className={STYLES.filterTitle}>License</div>
          <div className={STYLES.filterItems}>
            <div
              onClick={onFilterToggle.bind(null, 'openSource')}
              className={openSourceTagStyle(openSource)}>
              Open Source
            </div>
          </div>
        </div>
        <div className={STYLES.sbcFilter}>
          <div className={STYLES.filterTitle}>Stellar Build Challenge</div>
          <div className={STYLES.filterItems}>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 1)}
              className={sbcTagStyle(sbc, 1)}>
              SBC 1
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 2)}
              className={sbcTagStyle(sbc, 2)}>
              SBC 2
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 3)}
              className={sbcTagStyle(sbc, 3)}>
              SBC 3
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 4)}
              className={sbcTagStyle(sbc, 4)}>
              SBC 4
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 5)}
              className={sbcTagStyle(sbc, 5)}>
              SBC 5
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 6)}
              className={sbcTagStyle(sbc, 6)}>
              SBC 6
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'sbc', 7)}
              className={sbcTagStyle(sbc, 7)}>
              SBC 7
            </div>
          </div>
        </div>
        <div className={STYLES.categoryFilter}>
          <div className={STYLES.filterTitle}>Category</div>
          <div className={STYLES.filterItems}>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'anchors')}
              className={categoryTagStyle(category, 'anchors')}>
              Anchors
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'devTools')}
              className={categoryTagStyle(category, 'devTools')}>
              Dev Tools
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'exchanges')}
              className={categoryTagStyle(category, 'exchanges')}>
              Exchanges
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'games')}
              className={categoryTagStyle(category, 'games')}>
              Games
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'reference')}
              className={categoryTagStyle(category, 'reference')}>
              Reference
            </div>
            <div
              onClick={onFilterSelect.bind(
                null,
                'category',
                'socialNetworking'
              )}
              className={categoryTagStyle(category, 'socialNetworking')}>
              Social Networking
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'utilities')}
              className={categoryTagStyle(category, 'utilities')}>
              Utilities
            </div>
            <div
              onClick={onFilterSelect.bind(null, 'category', 'wallets')}
              className={categoryTagStyle(category, 'wallets')}>
              Wallets
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

AdvancedFilter.propTypes = {
  sbc: number,
  category: string,
  openSource: bool,
  onFilterToggle: func.isRequired,
  onFilterSelect: func.isRequired,
};

AdvancedFilter.defaultProps = {
  sbc: null,
  category: null,
  openSource: false,
};

export default AdvancedFilter;
