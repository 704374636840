import React, { Component } from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import TabTitle from './TabTitle';
import Operations from '../../../components/operations/Operations';
import Transactions from '../../../components/transactions/Transactions';
import Payments from '../../../components/payments/Payments';
import Effects from '../../../components/effects/Effects';

import STYLES from './TabbedList.module.scss';

class TabbedList extends Component {
  static propTypes = {
    ledgerEffects: instanceOf(List),
    ledgerOperations: instanceOf(List),
    ledgerTransactions: instanceOf(List),
    ledgerPayments: instanceOf(List),
    ledgerOperationsPage: number,
    ledgerEffectsPage: number,
    ledgerTransactionsPage: number,
    ledgerPaymentsPage: number,
    ledgerOperationsOrder: string,
    ledgerEffectsOrder: string,
    ledgerTransactionsOrder: string,
    ledgerPaymentsOrder: string,
    isMoreLedgerOperations: bool,
    isMoreLedgerEffects: bool,
    isMoreLedgerTransactions: bool,
    isMoreLedgerPayments: bool,
    isDevModeOn: bool,
    isFetchingLedgerEffects: bool,
    isFetchingLedgerOperations: bool,
    isFetchingLedgerTransactions: bool,
    isFetchingLedgerPayments: bool,
    toggleCodeModal: func.isRequired,
    onGetNextPage: func,
    onGetPreviousPage: func,
  };

  static defaultProps = {
    ledgerEffects: new List(),
    ledgerOperations: new List(),
    ledgerTransactions: new List(),
    ledgerPayments: new List(),
    ledgerOperationsOrder: 'desc',
    ledgerEffectsOrder: 'desc',
    ledgerTransactionsOrder: 'desc',
    ledgerPaymentsOrder: 'desc',
    ledgerOperationsPage: 1,
    ledgerEffectsPage: 1,
    ledgerTransactionsPage: 1,
    ledgerPaymentsPage: 1,
    isMoreLedgerOperations: false,
    isMoreLedgerEffects: false,
    isMoreLedgerTransactions: false,
    isMoreLedgerPayments: false,
    isDevModeOn: false,
    isFetchingLedgerEffects: false,
    isFetchingLedgerOperations: false,
    isFetchingLedgerTransactions: false,
    isFetchingLedgerPayments: false,
    onGetNextPage: () => {},
    onGetPreviousPage: () => {},
  };

  state = { activeTabKey: 1 };

  handleTabSelect = activeTabKey => {
    this.setState({ activeTabKey });
  };

  render() {
    const {
      ledgerEffects,
      ledgerOperations,
      ledgerTransactions,
      ledgerPayments,
      ledgerOperationsPage,
      ledgerEffectsPage,
      ledgerTransactionsPage,
      ledgerPaymentsPage,
      ledgerOperationsOrder,
      ledgerEffectsOrder,
      ledgerTransactionsOrder,
      ledgerPaymentsOrder,
      isMoreLedgerOperations,
      isMoreLedgerEffects,
      isMoreLedgerTransactions,
      isMoreLedgerPayments,
      isDevModeOn,
      isFetchingLedgerEffects,
      isFetchingLedgerOperations,
      isFetchingLedgerTransactions,
      isFetchingLedgerPayments,
      toggleCodeModal,
      onGetNextPage,
      onGetPreviousPage,
    } = this.props;
    const { activeTabKey } = this.state;

    return (
      <Tabs
        id="ledger-tabs"
        activeKey={activeTabKey}
        onSelect={this.handleTabSelect}
        className={STYLES.TabbedList}>
        <Tab
          eventKey={1}
          title={
            <TabTitle title="Effects" tabKey={1} activeTabKey={activeTabKey} />
          }>
          <Effects
            effects={ledgerEffects}
            effectsOrder={ledgerEffectsOrder}
            currentPage={ledgerEffectsPage}
            isMoreEffects={isMoreLedgerEffects}
            isDevModeOn={isDevModeOn}
            isFetchingEffects={isFetchingLedgerEffects}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={2}
          title={
            <TabTitle
              title="Operations"
              tabKey={2}
              activeTabKey={activeTabKey}
            />
          }>
          <Operations
            operations={ledgerOperations}
            operationsOrder={ledgerOperationsOrder}
            currentPage={ledgerOperationsPage}
            isMoreOperations={isMoreLedgerOperations}
            isDevModeOn={isDevModeOn}
            isFetchingOperations={isFetchingLedgerOperations}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={3}
          title={
            <TabTitle
              title="Transactions"
              tabKey={3}
              activeTabKey={activeTabKey}
            />
          }>
          <Transactions
            transactions={ledgerTransactions}
            transactionsOrder={ledgerTransactionsOrder}
            currentPage={ledgerTransactionsPage}
            isMoreTransactions={isMoreLedgerTransactions}
            isDevModeOn={isDevModeOn}
            isFetchingTransactions={isFetchingLedgerTransactions}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={4}
          title={
            <TabTitle title="Payments" tabKey={4} activeTabKey={activeTabKey} />
          }>
          <Payments
            payments={ledgerPayments}
            paymentsOrder={ledgerPaymentsOrder}
            currentPage={ledgerPaymentsPage}
            isMorePayments={isMoreLedgerPayments}
            isDevModeOn={isDevModeOn}
            isFetchingPayments={isFetchingLedgerPayments}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default TabbedList;
