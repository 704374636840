export const REQUEST_CREATE_PROJECT = 'REQUEST_CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const REQUEST_FEATURED_PROJECTS = 'REQUEST_FEATURED_PROJECTS';
export const GET_FEATURED_PROJECTS_SUCCESS = 'GET_FEATURED_PROJECTS_SUCCESS';
export const GET_FEATURED_PROJECTS_ERROR = 'GET_FEATURED_PROJECTS_ERROR';

export const REQUEST_PROJECTS_SEARCH_RESULTS =
  'REQUEST_PROJECTS_SEARCH_RESULTS';
export const GET_PROJECTS_SEARCH_RESULTS_SUCCESS =
  'GET_PROJECTS_SEARCH_RESULTS_SUCCESS';
export const GET_PROJECTS_SEARCH_RESULTS_ERROR =
  'GET_PROJECTS_SEARCH_RESULTS_ERROR';

export const REQUEST_COMMUNITY_PROJECTS = 'REQUEST_COMMUNITY_PROJECTS';
export const GET_COMMUNITY_PROJECTS_SUCCESS = 'GET_COMMUNITY_PROJECTS_SUCCESS';
export const GET_COMMUNITY_PROJECTS_ERROR = 'GET_COMMUNITY_PROJECTS_ERROR';

export const REQUEST_TOP_WALLETS = 'REQUEST_TOP_WALLETS';
export const GET_TOP_WALLETS_SUCCESS = 'GET_TOP_WALLETS_SUCCESS';
export const GET_TOP_WALLETS_ERROR = 'GET_TOP_WALLETS_ERROR';

export const REQUEST_TOP_ANCHORS = 'REQUEST_TOP_ANCHORS';
export const GET_TOP_ANCHORS_SUCCESS = 'GET_TOP_ANCHORS_SUCCESS';
export const GET_TOP_ANCHORS_ERROR = 'GET_TOP_ANCHORS_ERROR';

export const REQUEST_TOP_EXCHANGES = 'REQUEST_TOP_EXCHANGES';
export const GET_TOP_EXCHANGES_SUCCESS = 'GET_TOP_EXCHANGES_SUCCESS';
export const GET_TOP_EXCHANGES_ERROR = 'GET_TOP_EXCHANGES_ERROR';

export const REQUEST_TOP_DEV_TOOLS = 'REQUEST_TOP_DEV_TOOLS';
export const GET_TOP_DEV_TOOLS_SUCCESS = 'GET_TOP_DEV_TOOLS_SUCCESS';
export const GET_TOP_DEV_TOOLS_ERROR = 'GET_TOP_DEV_TOOLS_ERROR';

export const REQUEST_TOP_UTILITIES = 'REQUEST_TOP_UTILITIES';
export const GET_TOP_UTILITIES_SUCCESS = 'GET_TOP_UTILITIES_SUCCESS';
export const GET_TOP_UTILITIES_ERROR = 'GET_TOP_UTILITIES_ERROR';

export const REQUEST_EDIT_PROJECT = 'REQUEST_EDIT_PROJECT';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';

export const REQUEST_DELETE_PROJECT = 'REQUEST_DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const TOGGLE_PROJECT_EDIT_FORM = 'TOGGLE_PROJECT_EDIT_FORM';
export const TOGGLE_PROJECT_CREATE_FORM = 'TOGGLE_PROJECT_CREATE_FORM';
export const TOGGLE_PROJECT_DELETE_MODAL = 'TOGGLE_PROJECT_DELETE_MODAL';
