import React from 'react';

import STYLES from './Exchanges.module.scss';

const Exchanges = () => (
  <div className={STYLES.Exchanges}>
    <div className={STYLES.header}>
      <div className={STYLES.headerInside}>
        <h2>EXCHANGES</h2>
      </div>
    </div>
    <div className={STYLES.exchangesWrapper}>
      <div className={STYLES.exchangeWrap}>
        <a
          href="https://www.binance.com/?ref=13859220"
          target="_blank"
          rel="noopener noreferrer"
          className={STYLES.binanceWrapper}>
          <div className={STYLES.binance} />
        </a>
      </div>
      <div className={STYLES.exchangeWrap}>
        <a
          href="https://bittrex.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={STYLES.bittrexWrapper}>
          <div className={STYLES.bittrex} />
        </a>
      </div>
      <div className={STYLES.exchangeWrap}>
        <a
          href="https://www.kraken.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={STYLES.krakenWrapper}>
          <div className={STYLES.kraken} />
        </a>
      </div>
      <div className={STYLES.exchangeWrap}>
        <a
          href="https://cex.io/r/0/up119051561/0/"
          target="_blank"
          rel="noopener noreferrer"
          className={STYLES.cexWrapper}>
          <div className={STYLES.cex} />
        </a>
      </div>
      <div className={STYLES.exchangeWrap}>
        <a
          href="https://poloniex.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={STYLES.poloniexWrapper}>
          <div className={STYLES.poloniex} />
        </a>
      </div>
    </div>
  </div>
);

export default Exchanges;
