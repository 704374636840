import React from 'react';
import { instanceOf, bool, func } from 'prop-types';
import { List } from 'immutable';

import GoogleAd from '../../../../components/GoogleAd';
import Operations from '../../../../components/operations/Operations';
import Transactions from '../../../../components/transactions/Transactions';

import STYLES from './HorizonData.module.scss';

const HorizonData = ({
  operations,
  transactions,
  toggleCodeModal,
  isDevModeOn,
}) => (
  <div className={STYLES.HorizonData}>
    <div className={STYLES.panelWrapper}>
      <div className={STYLES.header}>
        <h2>RECENT OPERATIONS</h2>
      </div>
      <Operations
        operations={operations}
        isDevModeOn={isDevModeOn}
        toggleCodeModal={toggleCodeModal}
      />
      <div className={STYLES.adWrap}>
        <GoogleAd placement="Home" adSlot="7799510885" />
      </div>
    </div>
    <div className={STYLES.panelWrapper}>
      <div className={STYLES.header}>
        <h2>RECENT TRANSACTIONS</h2>
      </div>
      <Transactions
        isDevModeOn={isDevModeOn}
        toggleCodeModal={toggleCodeModal}
        transactions={transactions}
      />
      <div className={STYLES.adWrap}>
        <GoogleAd placement="Home" adSlot="7799510885" />
      </div>
    </div>
  </div>
);

HorizonData.propTypes = {
  transactions: instanceOf(List),
  operations: instanceOf(List),
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

HorizonData.defaultProps = {
  transactions: new List(),
  operations: new List(),
  isDevModeOn: false,
};

export default HorizonData;
