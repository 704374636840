import React from 'react';
import { string, number } from 'prop-types';

import { GoMarkGithub, GoLink, GoTools, GoGear } from 'react-icons/go';
import { FaAnchor } from 'react-icons/fa';
import {
  MdAccountBalanceWallet,
  MdAutorenew,
  MdGames,
  MdForum,
  MdDescription,
} from 'react-icons/md';

import STYLES from './Project.module.scss';

const formatCategory = category => {
  switch (category) {
    case 'devTools':
      return 'dev Tools';
    case 'socialNetworking':
      return 'social Networking';
    default:
      return category;
  }
};

const renderProjectIcon = category => {
  switch (category) {
    case 'anchors':
      return <FaAnchor />;
    case 'exchanges':
      return <MdAutorenew />;
    case 'wallets':
      return <MdAccountBalanceWallet />;
    case 'utilities':
      return <GoGear />;
    case 'devTools':
      return <GoTools />;
    case 'games':
      return <MdGames />;
    case 'socialNetworking':
      return <MdForum />;
    case 'reference':
      return <MdDescription />;
    default:
      return <FaAnchor />;
  }
};

// const renderPlatformTag = platform => {
//   const platformTargets = platform.split('#');
//
//   return platformTargets.map((target, i) => (
//     <div key={i} className={STYLES.platformTag}>{target}</div>
//   ));
// };

const Project = ({ name, url, github, category, license, sbc, platform }) => (
  <div className={STYLES.Project}>
    <div className={STYLES.categoryIcon}>{renderProjectIcon(category)}</div>
    <div className={STYLES.projectItem}>
      <div className={STYLES.titleContainer}>
        <h4>
          <a href={url || github} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </h4>
      </div>
      <div className={STYLES.tags}>
        <div className={STYLES.categoryTag}>{formatCategory(category)}</div>
        {license && <div className={STYLES.openSourceTag}>Open Source</div>}
        {sbc && <div className={STYLES.sbcTag}>SBC {sbc}</div>}
        {/*{platform && (
          renderPlatformTag(platform)
        )}*/}
      </div>
      <div className={STYLES.flexDetails}>
        {github ? (
          <div className={STYLES.projectStat}>
            <GoMarkGithub className={STYLES.statIcon} />{' '}
            <a
              href={github}
              className={STYLES.projectLink}
              target="_blank"
              rel="noopener noreferrer">
              {github}
            </a>
          </div>
        ) : (
          <div className={STYLES.projectStat}>
            <GoLink className={STYLES.statIcon} />{' '}
            <a
              href={url}
              className={STYLES.projectLink}
              target="_blank"
              rel="noopener noreferrer">
              {url}
            </a>
          </div>
        )}
      </div>
    </div>
  </div>
);

Project.propTypes = {
  name: string.isRequired,
  category: string.isRequired,
  url: string,
  sbc: number,
  github: string,
  license: string,
  platform: string,
};

Project.defaultProps = {
  url: null,
  sbc: null,
  github: null,
  license: null,
  platform: null,
};

export default Project;
