import React, { Component } from 'react';
import { instanceOf, object, number, bool, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import ReactGA from 'react-ga4';

import { getProjects } from '../../actions/community';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import Project from './components/Project';

import STYLES from './ProjectsPage.module.scss';
import { withHook } from '../../util/withHook';

const buildQuery = query => {
  const queryParams = {
    ...(query.name && { name: query.name }),
    ...(query.openSource && { openSource: query.openSource }),
    ...(query.sbc && query.sbc.length && { sbc: query.sbc }),
    ...(query.category &&
      query.category.length && { category: query.category }),
  };

  return isEmpty(queryParams) ? null : queryParams;
};

class ProjectsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    projects: instanceOf(List),
    projectsCount: number,
    isFetchingProjects: bool,
    onGetProjects: func.isRequired,
  };

  static defaultProps = {
    projects: new List(),
  };

  state = {
    previousSearch: {},
  };

  componentDidMount = () => {
    const {
      match: { params, url },
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: url,
        title: params.projectCategory,
      });
    }

    const queryParams = buildQuery({
      category: params.projectCategory,
    });

    this.props.onGetProjects(queryParams);
    this.setState({ previousSearch: queryParams });
  };

  renderHelmet = () => {
    const {
      match: { url, params },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = `StellarUp - ${params.projectCategory[0].toUpperCase()}${params.projectCategory.slice(
      1
    )}`;
    const description = `The Community ${params.projectCategory[0].toUpperCase()}${params.projectCategory.slice(
      1
    )} page on StellarUp provides a look into all of the projects built on or for Stellar for this category.`;
    const communityUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={communityUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      match: { params },
      projects,
      isFetchingProjects,
    } = this.props;

    return (
      <div className={STYLES.ProjectsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header} ref={this.projectsTop}>
                <h2 className={STYLES.pageTitle}>{params.projectCategory}</h2>
              </div>
              <div>
                {/*<div className={STYLES.topWrapper}>
                  <div className={STYLES.resultsInfo}>
                    { showingStart && showingEnd && projectsCount ? (
                      <span>
                        <span className={STYLES.resultsMinor}>
                          {showingStart} to {showingEnd} of
                        </span> {projectsCount} results
                      </span>
                    ) : null}
                  </div>
                  <div className={STYLES.sortWrapper}>
                    <div className={STYLES.sortTitle}>Sort By:</div>
                    <DropdownButton
                      className={STYLES.dropdownButton}
                      pullRight
                      bsStyle="link"
                      id="sort-dropdown"
                      title={sortCategory}>
                      <MenuItem
                        onClick={this.updateProjectsSortCategory.bind(null, 'name')}
                        eventKey="1">
                        {sortCategory === 'name' ? (
                          <span>
                            <MdCheck className={STYLES.dropdownIcon} /> Name
                          </span>
                        ) : (
                          <span className={STYLES.dropdownPlaceholder}>Name</span>
                        )}
                      </MenuItem>
                      <MenuItem divider />
                      <MenuItem
                        onClick={this.updateProjectsSortOrder.bind(null, 'asc')}
                        eventKey="4">
                        {sortOrder === 'asc' ? (
                          <span>
                            <MdCheck className={STYLES.dropdownIcon} /> asc
                          </span>
                        ) : (
                          <span className={STYLES.dropdownPlaceholder}>asc</span>
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={this.updateProjectsSortOrder.bind(null, 'desc')}
                        eventKey="5">
                        {sortOrder === 'desc' ? (
                          <span>
                            <MdCheck className={STYLES.dropdownIcon} /> desc
                          </span>
                        ) : (
                          <span className={STYLES.dropdownPlaceholder}>desc</span>
                        )}
                      </MenuItem>
                    </DropdownButton>
                  </div>
                </div>*/}

                <div className={STYLES.dividerWrapper}>
                  <div className={STYLES.divider} />
                </div>
                <div className={STYLES.projects}>
                  {isFetchingProjects ? (
                    <div>
                      <div className="loading-spinner-animation" />
                    </div>
                  ) : (
                    <div>
                      {projects &&
                        projects.map(project => (
                          <Project
                            key={project.get('name')}
                            name={project.get('name')}
                            url={project.get('url')}
                            github={project.get('github')}
                            category={project.get('category')}
                            license={project.get('license')}
                            sbc={project.get('sbc')}
                          />
                        ))}
                      <div className={STYLES.adWrap}>
                        <GoogleAd placement="Bottom" adSlot="7799510885" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: SELECTORS.getProjects(state),
  projectsCount: SELECTORS.getProjectsCount(state),
  isFetchingProjects: SELECTORS.getIsFetchingProjects(state),
});

const mapDispatchToProps = {
  onGetProjects: getProjects,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProjectsPage))
);
