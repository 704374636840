import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { MdCheckCircle, MdError } from 'react-icons/md';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsComponent.module.scss';

const AllowTrustDetails = ({ operationDetails }) => (
  <div className={STYLES.DetailsComponent}>
    <h3>
      Operation Type{' '}
      <InfoToolTip
        title="Allow Trust"
        info="Updates the “authorized” flag of an existing trust line this is called by the issuer of the asset."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.mainDetails}>Allow Trust</div>
    <h3>
      Transaction Hash{' '}
      <InfoToolTip
        title="Transaction Hash"
        info="A hex-encoded SHA-256 hash of the transaction’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {operationDetails.get('transaction_hash')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Status</span>
        <InfoToolTip
          title="Status"
          info="Indicates if transaction was successful or not."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {operationDetails.get('transaction_successful') ? (
            <div className={STYLES.successLabel}>
              <MdCheckCircle className={STYLES.statusIcon} /> Successful
            </div>
          ) : (
            <div className={STYLES.failedLabel}>
              <MdError className={STYLES.statusIcon} /> Failed
            </div>
          )}
        </span>
      </div>
      {operationDetails && operationDetails.get('created_at') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Created at</span>
          <InfoToolTip
            title="Created at"
            info="The timestamp of when the operation was created."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {moment(operationDetails.get('created_at')).format('LLL')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('trustee') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Trustee</span>
          <InfoToolTip
            title="Trustee"
            info="Trustee account."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('trustee')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('trustor') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Trustor</span>
          <InfoToolTip
            title="Trustor"
            info="Trustor account."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('trustor')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('asset_type') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Asset</span>
          <InfoToolTip
            title="Asset"
            info="The 4 or 12 character-maximum asset code of the trustline the source account is authorizing"
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('asset_code')}
          </span>
        </div>
      )}
    </div>
  </div>
);

AllowTrustDetails.propTypes = {
  operationDetails: instanceOf(Map),
};

AllowTrustDetails.defaultProps = {
  operationDetails: new Map(),
};

export default AllowTrustDetails;
