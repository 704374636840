import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './OperationComponent.module.scss';

const renderBuyOffer = (operation, matchId) => (
  <div className={STYLES.flexDetails}>
    Create passive offer from{' '}
    <HashPopover
      path="accounts"
      hash={operation.get('buying_asset_issuer')}
      matchId={matchId}
    />
    buying{' '}
    {operation.get('buying_asset_type') === 'native'
      ? 'XLM'
      : operation.get('buying_asset_code')}{' '}
    for{' '}
    {operation.get('selling_asset_type') === 'native'
      ? 'XLM'
      : operation.get('selling_asset_code')}{' '}
    at {operation.get('price')}
  </div>
);

const renderSellOffer = (operation, matchId) => (
  <div className={STYLES.flexDetails}>
    Create passive offer from{' '}
    <HashPopover
      path="accounts"
      hash={operation.get('selling_asset_issuer')}
      matchId={matchId}
    />
    selling{' '}
    {operation.get('selling_asset_type') === 'native'
      ? 'XLM'
      : operation.get('selling_asset_code')}{' '}
    for{' '}
    {operation.get('buying_asset_type') === 'native'
      ? 'XLM'
      : operation.get('buying_asset_code')}{' '}
    at {operation.get('price')}
  </div>
);

const CreatePassiveOffer = ({
  operation,
  matchId,
  isDevModeOn,
  toggleCodeModal,
}) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>CREATE PASSIVE OFFER</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    {operation.get('buying_asset_issuer')
      ? renderBuyOffer(operation, matchId)
      : renderSellOffer(operation, matchId)}
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

CreatePassiveOffer.propTypes = {
  matchId: string,
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

CreatePassiveOffer.defaultProps = {
  matchId: '',
  isDevModeOn: false,
};

export default CreatePassiveOffer;
