import React, { Component } from 'react';
import { instanceOf, string, bool } from 'prop-types';
import { Map } from 'immutable';
import ReactEcharts from 'echarts-for-react';

import STYLES from './PriceGraph.module.scss';

class PriceGraph extends Component {
  static propTypes = {
    weekHistory: instanceOf(Map),
    monthHistory: instanceOf(Map),
    yearHistory: instanceOf(Map),
    graphTimePeriod: string,
    isFetchingCoinHistory: bool,
  };

  static defaultProps = {
    weekHistory: new Map(),
    monthHistory: new Map(),
    yearHistory: new Map(),
    graphTimePeriod: 'YEAR',
    isFetchingCoinHistory: false,
  };

  determineActiveGraphPeriod() {
    const {
      weekHistory,
      monthHistory,
      yearHistory,
      graphTimePeriod,
    } = this.props;

    switch (graphTimePeriod) {
      case 'WEEK':
        return {
          data: weekHistory,
          interval: 4,
        };
      case 'MONTH':
        return {
          data: monthHistory,
          interval: 8,
        };
      case 'YEAR':
        return {
          data: yearHistory,
          interval: 77,
        };
      default:
        return {
          data: weekHistory,
          interval: 3,
        };
    }
  }

  renderSmallOptions() {
    const currentGraph = this.determineActiveGraphPeriod();

    return {
      title: {
        show: true,
        text: 'Historical Price Data',
        textStyle: {
          color: '#0EBA81',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
        left: 50,
        top: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data:
          currentGraph.data.get('date') && currentGraph.data.get('date').toJS(),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: currentGraph.interval,
          fontFamily: 'Poppins',
          color: '#9698AA',
          textStyle: {
            align: 'left',
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          // fontFamily: 'Poppins',
          // color: '#9698AA',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#EDEDED'],
          },
        },
      },
      series: [
        {
          name: 'XLM/USD',
          data:
            currentGraph.data.get('data') &&
            currentGraph.data.get('data').toJS(),
          type: 'line',
          itemStyle: {
            normal: {
              color: 'rgb(43, 50, 178)',
            },
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgb(20, 136, 204)', // 0%
                },
                {
                  offset: 1,
                  color: 'rgb(43, 50, 178)', // 100%
                },
              ],
            },
          },
        },
      ],
    };
  }

  renderLargeOptions() {
    const currentGraph = this.determineActiveGraphPeriod();

    return {
      title: {
        show: true,
        text: 'Historical Price Data',
        textStyle: {
          color: '#0EBA81',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
        left: 50,
        top: 10,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data:
          currentGraph.data.get('date') && currentGraph.data.get('date').toJS(),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontFamily: 'Poppins',
          color: '#9698AA',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontFamily: 'Poppins',
          color: '#9698AA',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#EDEDED'],
          },
        },
      },
      series: [
        {
          name: 'XLM/USD',
          data:
            currentGraph.data.get('data') &&
            currentGraph.data.get('data').toJS(),
          type: 'line',
          itemStyle: {
            normal: {
              color: 'rgb(43, 50, 178)',
            },
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgb(20, 136, 204)', // 0%
                },
                {
                  offset: 1,
                  color: 'rgb(43, 50, 178)', // 100%
                },
              ],
            },
          },
        },
      ],
    };
  }

  render() {
    const { isFetchingCoinHistory } = this.props;

    return (
      <div className={STYLES.PriceGraph}>
        <div className={STYLES.graph}>
          {isFetchingCoinHistory ? (
            <div className={STYLES.loading}>
              <div className="loading-spinner-animation" />
            </div>
          ) : (
            <ReactEcharts
              onChartReady={this.onChartReady}
              showLoading={isFetchingCoinHistory}
              option={this.renderLargeOptions()}
              style={{ height: '350px', width: '100%' }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PriceGraph;
