import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { getAllTransactions } from '../../actions/transaction';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import Transactions from '../../components/transactions/Transactions';

import STYLES from './TransactionPage.module.scss';
import { withHook } from '../../util/withHook';

class TransactionPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    transactions: instanceOf(List),
    transactionsOrder: string,
    isFetchingTransactions: bool,
    isDevModeOn: bool,
    getAllTransactions: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    transactions: new List(),
    transactionsOrder: 'desc',
    isFetchingTransactions: false,
    isDevModeOn: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.transactionsTop = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/transactions',
        title: 'Transactions',
      });
    }

    this.props.getAllTransactions();
  };

  getNextPage = () => {
    const { transactions } = this.props;
    const { currentPage } = this.state;
    const pagingToken = transactions.get(-1).get('paging_token');
    const transactionsTop = ReactDOM.findDOMNode(this.transactionsTop.current)
      .offsetTop;

    this.props.getAllTransactions(pagingToken, 'desc');
    window.scrollTo(0, transactionsTop - 20);
    this.setState({ currentPage: currentPage + 1 });
  };

  getPreviousPage = () => {
    const { transactions } = this.props;
    const { currentPage } = this.state;
    const pagingToken = transactions.get(0).get('paging_token');
    const transactionsTop = ReactDOM.findDOMNode(this.transactionsTop.current)
      .offsetTop;

    this.props.getAllTransactions(pagingToken, 'asc');
    window.scrollTo(0, transactionsTop - 20);
    this.setState({ currentPage: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Transactions';
    const description =
      'A list containing all of the recent Transactions on the Stellar cryptocurrency network';
    const transactionsUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={transactionsUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      transactions,
      transactionsOrder,
      isFetchingTransactions,
      onToggleCodeModal,
      isDevModeOn,
    } = this.props;
    const { currentPage } = this.state;

    return (
      <div className={STYLES.TransactionPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header} ref={this.transactionsTop}>
                <h2>TRANSACTIONS</h2>
              </div>
              <div>
                <Transactions
                  transactions={transactions}
                  isDevModeOn={isDevModeOn}
                  transactionsOrder={transactionsOrder}
                  currentPage={currentPage}
                  isMoreTransactions={true}
                  isFetchingTransactions={isFetchingTransactions}
                  toggleCodeModal={onToggleCodeModal}
                  onGetNextPage={this.getNextPage}
                  onGetPreviousPage={this.getPreviousPage}
                />
                <div className={STYLES.adWrapBottom}>
                  <GoogleAd placement="Bottom" adSlot="7799510885" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactions: SELECTORS.getAllTransactions(state),
  transactionsOrder: SELECTORS.getTransactionsOrder(state),
  isFetchingTransactions: SELECTORS.getIsFetchingTransactions(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getAllTransactions,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(TransactionPage))
);
