import { Map } from 'immutable';
import * as types from '../actions/ledger/types';

const ACTIONS = {
  // All Ledgers
  [types.REQUEST_ALL_LEDGERS]: (state, action) => {
    const { isFetchingLedgers } = action.payload;
    return state.merge({ isFetchingLedgers });
  },
  [types.GET_ALL_LEDGERS_SUCCESS]: (state, action) => {
    const { ledgers, ledgersOrder, isFetchingLedgers } = action.payload;
    return state.merge({
      ledgers,
      ledgersOrder,
      isFetchingLedgers,
    });
  },
  [types.GET_ALL_LEDGERS_ERROR]: (state, action) => {
    const { error, isFetchingLedgers } = action.payload;
    return state.merge({ error, isFetchingLedgers });
  },
  // Ledger Details
  [types.REQUEST_LEDGER_DETAILS]: (state, action) => {
    const { isFetchingLedgerDetails } = action.payload;
    return state.merge({ isFetchingLedgerDetails });
  },
  [types.GET_LEDGER_DETAILS_SUCCESS]: (state, action) => {
    const { ledgerDetails, isFetchingLedgerDetails } = action.payload;
    return state.merge({ ledgerDetails, isFetchingLedgerDetails });
  },
  [types.GET_LEDGER_DETAILS_ERROR]: (state, action) => {
    const { error, isFetchingLedgerDetails } = action.payload;
    return state.merge({ error, isFetchingLedgerDetails });
  },
  // Ledger Effects
  [types.REQUEST_LEDGER_EFFECTS]: (state, action) => {
    const { isFetchingLedgerEffects } = action.payload;
    return state.merge({ isFetchingLedgerEffects });
  },
  [types.GET_LEDGER_EFFECTS_SUCCESS]: (state, action) => {
    const {
      ledgerEffects,
      ledgerEffectsOrder,
      isMoreLedgerEffects,
      isFetchingLedgerEffects,
    } = action.payload;
    return state.merge({
      ledgerEffects,
      ledgerEffectsOrder,
      isMoreLedgerEffects,
      isFetchingLedgerEffects,
    });
  },
  [types.GET_LEDGER_EFFECTS_ERROR]: (state, action) => {
    const { error, isFetchingLedgerEffects } = action.payload;
    return state.merge({ error, isFetchingLedgerEffects });
  },
  // Ledger Operations
  [types.REQUEST_LEDGER_OPERATIONS]: (state, action) => {
    const { isFetchingLedgerOperations } = action.payload;
    return state.merge({ isFetchingLedgerOperations });
  },
  [types.GET_LEDGER_OPERATIONS_SUCCESS]: (state, action) => {
    const {
      ledgerOperations,
      ledgerOperationsOrder,
      isMoreLedgerOperations,
      isFetchingLedgerOperations,
    } = action.payload;
    return state.merge({
      ledgerOperations,
      ledgerOperationsOrder,
      isMoreLedgerOperations,
      isFetchingLedgerOperations,
    });
  },
  [types.GET_LEDGER_OPERATIONS_ERROR]: (state, action) => {
    const { error, isFetchingLedgerOperations } = action.payload;
    return state.merge({ error, isFetchingLedgerOperations });
  },
  // Ledger Transactions
  [types.REQUEST_LEDGER_TRANSACTIONS]: (state, action) => {
    const { isFetchingLedgerTransactions } = action.payload;
    return state.merge({ isFetchingLedgerTransactions });
  },
  [types.GET_LEDGER_TRANSACTIONS_SUCCESS]: (state, action) => {
    const {
      ledgerTransactions,
      ledgerTransactionsOrder,
      isMoreLedgerTransactions,
      isFetchingLedgerTransactions,
    } = action.payload;
    return state.merge({
      ledgerTransactions,
      ledgerTransactionsOrder,
      isMoreLedgerTransactions,
      isFetchingLedgerTransactions,
    });
  },
  [types.GET_LEDGER_TRANSACTIONS_ERROR]: (state, action) => {
    const { error, isFetchingLedgerTransactions } = action.payload;
    return state.merge({ error, isFetchingLedgerTransactions });
  },
  // Ledger Payments
  [types.REQUEST_LEDGER_PAYMENTS]: (state, action) => {
    const { isFetchingLedgerPayments } = action.payload;
    return state.merge({ isFetchingLedgerPayments });
  },
  [types.GET_LEDGER_PAYMENTS_SUCCESS]: (state, action) => {
    const {
      ledgerPayments,
      ledgerPaymentsOrder,
      isMoreLedgerPayments,
      isFetchingLedgerPayments,
    } = action.payload;
    return state.merge({
      ledgerPayments,
      ledgerPaymentsOrder,
      isMoreLedgerPayments,
      isFetchingLedgerPayments,
    });
  },
  [types.GET_LEDGER_PAYMENTS_ERROR]: (state, action) => {
    const { error, isFetchingLedgerPayments } = action.payload;
    return state.merge({ error, isFetchingLedgerPayments });
  },
};

export default function ledgerReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
