import { Map } from 'immutable';
import * as types from '../actions/asset/types';

const ACTIONS = {
  [types.REQUEST_ALL_ASSETS]: (state, action) => {
    const { isFetchingAssets } = action.payload;
    return state.merge({ isFetchingAssets });
  },
  [types.GET_ALL_ASSETS_SUCCESS]: (state, action) => {
    const { assets, assetsOrder, isFetchingAssets } = action.payload;
    return state.merge({
      assets,
      assetsOrder,
      isFetchingAssets,
    });
  },
  [types.GET_ALL_ASSETS_ERROR]: (state, action) => {
    const { error, isFetchingAssets } = action.payload;
    return state.merge({
      error,
      isFetchingAssets,
    });
  },
};

export default function assetReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
