import { Map } from 'immutable';
import * as types from '../actions/transaction/types';

const ACTIONS = {
  // All Transactions
  [types.REQUEST_ALL_TRANSACTIONS]: (state, action) => {
    const { isFetchingTransactions } = action.payload;
    return state.merge({ isFetchingTransactions });
  },
  [types.GET_ALL_TRANSACTIONS_SUCCESS]: (state, action) => {
    const {
      transactions,
      transactionsOrder,
      isFetchingTransactions,
    } = action.payload;
    return state.merge({
      transactions,
      transactionsOrder,
      isFetchingTransactions,
    });
  },
  [types.GET_ALL_TRANSACTIONS_ERROR]: (state, action) => {
    const { error, isFetchingTransactions } = action.payload;
    return state.merge({ error, isFetchingTransactions });
  },
  // Transaction Details
  [types.REQUEST_TRANSACTION_DETAILS]: (state, action) => {
    const { isFetchingTransactionDetails } = action.payload;
    return state.merge({ isFetchingTransactionDetails });
  },
  [types.GET_TRANSACTION_DETAILS_SUCCESS]: (state, action) => {
    const { transactionDetails, isFetchingTransactionDetails } = action.payload;
    return state.merge({ transactionDetails, isFetchingTransactionDetails });
  },
  [types.GET_TRANSACTION_DETAILS_ERROR]: (state, action) => {
    const { error, isFetchingTransactionDetails } = action.payload;
    return state.merge({ error, isFetchingTransactionDetails });
  },
  // Transaction Effects
  [types.REQUEST_TRANSACTION_EFFECTS]: (state, action) => {
    const { isFetchingTransactionEffects } = action.payload;
    return state.merge({ isFetchingTransactionEffects });
  },
  [types.GET_TRANSACTION_EFFECTS_SUCCESS]: (state, action) => {
    const {
      transactionEffects,
      transactionEffectsOrder,
      isMoreTransactionEffects,
      isFetchingTransactionEffects,
    } = action.payload;
    return state.merge({
      transactionEffects,
      transactionEffectsOrder,
      isMoreTransactionEffects,
      isFetchingTransactionEffects,
    });
  },
  [types.GET_TRANSACTION_EFFECTS_ERROR]: (state, action) => {
    const { error, isFetchingTransactionEffects } = action.payload;
    return state.merge({ error, isFetchingTransactionEffects });
  },
  // Transaction Operations
  [types.REQUEST_TRANSACTION_OPERATIONS]: (state, action) => {
    const { isFetchingTransactionOperations } = action.payload;
    return state.merge({ isFetchingTransactionOperations });
  },
  [types.GET_TRANSACTION_OPERATIONS_SUCCESS]: (state, action) => {
    const {
      transactionOperations,
      transactionOperationsOrder,
      isMoreTransactionOperations,
      isFetchingTransactionOperations,
    } = action.payload;
    return state.merge({
      transactionOperations,
      transactionOperationsOrder,
      isMoreTransactionOperations,
      isFetchingTransactionOperations,
    });
  },
  [types.GET_TRANSACTION_OPERATIONS_ERROR]: (state, action) => {
    const { error, isFetchingTransactionOperations } = action.payload;
    return state.merge({ error, isFetchingTransactionOperations });
  },
  // Transaction Payments
  [types.REQUEST_TRANSACTION_PAYMENTS]: (state, action) => {
    const { isFetchingTransactionPayments } = action.payload;
    return state.merge({ isFetchingTransactionPayments });
  },
  [types.GET_TRANSACTION_PAYMENTS_SUCCESS]: (state, action) => {
    const {
      transactionPayments,
      transactionPaymentsOrder,
      isMoreTransactionPayments,
      isFetchingTransactionPayments,
    } = action.payload;
    return state.merge({
      transactionPayments,
      transactionPaymentsOrder,
      isMoreTransactionPayments,
      isFetchingTransactionPayments,
    });
  },
  [types.GET_TRANSACTION_PAYMENTS_ERROR]: (state, action) => {
    const { error, isFetchingTransactionPayments } = action.payload;
    return state.merge({ error, isFetchingTransactionPayments });
  },
};

export default function transactionReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
