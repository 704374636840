import { Map } from 'immutable';
import * as types from '../actions/community/types';

const ACTIONS = {
  // UI
  [types.TOGGLE_PROJECT_CREATE_FORM]: (state, action) => {
    const { isProjectCreateFormOpen } = action.payload;
    return state.merge({ isProjectCreateFormOpen });
  },
  [types.TOGGLE_PROJECT_EDIT_FORM]: (state, action) => {
    const { activeProjectEditForm } = action.payload;
    return state.merge({ activeProjectEditForm });
  },
  [types.TOGGLE_PROJECT_DELETE_MODAL]: (state, action) => {
    const { activeProjectDeleteModal } = action.payload;
    return state.merge({ activeProjectDeleteModal });
  },
  // CREATE Project
  [types.REQUEST_CREATE_PROJECT]: (state, action) => {
    const { isFetchingCreateProject } = action.payload;
    return state.merge({ isFetchingCreateProject });
  },
  [types.CREATE_PROJECT_SUCCESS]: (state, action) => {
    const { isFetchingCreateProject, isProjectCreateFormOpen } = action.payload;
    return state.merge({ isFetchingCreateProject, isProjectCreateFormOpen });
  },
  [types.CREATE_PROJECT_ERROR]: (state, action) => {
    const { isFetchingCreateProject, error } = action.payload;
    return state.merge({ isFetchingCreateProject, error });
  },
  // EDIT Project
  [types.EDIT_PROJECT_SUCCESS]: (state, action) => {
    const { activeProjectEditForm } = action.payload;
    return state.merge({ activeProjectEditForm });
  },
  // DELETE Project
  [types.REQUEST_DELETE_PROJECT]: (state, action) => {
    const { isFetchingDeleteProject } = action.payload;
    return state.merge({ isFetchingDeleteProject });
  },
  [types.DELETE_PROJECT_SUCCESS]: (state, action) => {
    const {
      isFetchingDeleteProject,
      activeProjectDeleteModal,
    } = action.payload;
    return state.merge({ isFetchingDeleteProject, activeProjectDeleteModal });
  },
  [types.DELETE_PROJECT_ERROR]: (state, action) => {
    const { isFetchingDeleteProject, error } = action.payload;
    return state.merge({ isFetchingDeleteProject, error });
  },
  // GET Projects
  [types.REQUEST_COMMUNITY_PROJECTS]: (state, action) => {
    const { isFetchingProjects } = action.payload;
    return state.merge({ isFetchingProjects });
  },
  [types.GET_COMMUNITY_PROJECTS_ERROR]: (state, action) => {
    const { error, isFetchingProjects } = action.payload;
    return state.merge({ error, isFetchingProjects });
  },
  [types.GET_COMMUNITY_PROJECTS_SUCCESS]: (state, action) => {
    const { projects, projectsCount, isFetchingProjects } = action.payload;
    return state.merge({
      projects,
      projectsCount,
      isFetchingProjects,
    });
  },
  // Get Projects Search Results
  [types.REQUEST_PROJECTS_SEARCH_RESULTS]: (state, action) => {
    const { isFetchingProjectsSR } = action.payload;
    return state.merge({ isFetchingProjectsSR });
  },
  [types.GET_PROJECTS_SEARCH_RESULTS_ERROR]: (state, action) => {
    const { error, isFetchingProjectsSR } = action.payload;
    return state.merge({ error, isFetchingProjectsSR });
  },
  [types.GET_PROJECTS_SEARCH_RESULTS_SUCCESS]: (state, action) => {
    const {
      projectsSR,
      projectsSRCount,
      isFetchingProjectsSR,
    } = action.payload;
    return state.merge({
      projectsSR,
      projectsSRCount,
      isFetchingProjectsSR,
    });
  },
  // Get Featured Projects
  [types.REQUEST_FEATURED_PROJECTS]: (state, action) => {
    const { isFetchingFeaturedProjects } = action.payload;
    return state.merge({ isFetchingFeaturedProjects });
  },
  [types.GET_FEATURED_PROJECTS_SUCCESS]: (state, action) => {
    const { isFetchingFeaturedProjects, featuredProjects } = action.payload;
    return state.merge({ isFetchingFeaturedProjects, featuredProjects });
  },
  [types.GET_FEATURED_PROJECTS_ERROR]: (state, action) => {
    const { isFetchingFeaturedProjects, error } = action.payload;
    return state.merge({ isFetchingFeaturedProjects, error });
  },
};

export default function communityReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
