export const REQUEST_REPO_STATS = 'REQUEST_REPO_STATS';
export const GET_REPO_STATS_SUCCESS = 'GET_REPO_STATS_SUCCESS';
export const GET_REPO_STATS_ERROR = 'GET_REPO_STATS_ERROR';

export const REQUEST_REPOS_LIST = 'REQUEST_REPOS_LIST';
export const GET_REPOS_LIST_SUCCESS = 'GET_REPOS_LIST_SUCCESS';
export const GET_REPOS_LIST_ERROR = 'GET_REPOS_LIST_ERROR';

export const TOGGLE_DEV_MODE = 'TOGGLE_DEV_MODE';
export const TOGGLE_HORIZON_NET = 'TOGGLE_HORIZON_NET';

export const UPDATE_REPOS_SORT_CATEGORY = 'UPDATE_REPOS_SORT_CATEGORY';
export const UPDATE_REPOS_SORT_DIRECTION = 'UPDATE_REPOS_SORT_DIRECTION';
