import React from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import PaginationButtons from '../PaginationButtons';

import STYLES from './Ledgers.module.scss';

const determineOrder = (ledgers, ledgersOrder) =>
  ledgersOrder === 'asc' ? ledgers.reverse() : ledgers;

const Ledgers = ({
  ledgers,
  ledgersOrder,
  currentPage,
  isFetchingLedgers,
  isMoreLedgers,
  isDevModeOn,
  toggleCodeModal,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Ledgers}>
    {isFetchingLedgers ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {ledgers && ledgers.size ? (
          <div>
            {determineOrder(ledgers, ledgersOrder).map(ledger => (
              <div className={STYLES.ledgerRow} key={ledger.get('id')}>
                <div className={STYLES.ledgerItem}>
                  <div className={STYLES.titleContainer}>
                    <h4>
                      <Link to={`/ledgers/${ledger.get('sequence')}`}>
                        LEDGER - {ledger.get('sequence')}
                      </Link>
                      {isDevModeOn && (
                        <CodeModalToggle
                          id={ledger.get('id')}
                          toggleCodeModal={toggleCodeModal}
                          buttonSize="small"
                        />
                      )}
                    </h4>
                    <div className={STYLES.label}>
                      FEE POOL {ledger.get('fee_pool')}
                    </div>
                  </div>
                  <p className={STYLES.date}>
                    Closed on:{' '}
                    {moment(ledger.get('closed_at')).format(
                      'MMM DD YYYY, h:mm a'
                    )}
                  </p>
                  <div className={STYLES.flexDetails}>
                    Closed with {ledger.get('transaction_count')} transaction
                    {ledger.get('transaction_count') === 1 ? '' : 's'}{' '}
                    {ledger.get('operation_count')} operation
                    {ledger.get('operation_count') === 1 ? '' : 's'} and{' '}
                    {ledger.get('total_coins')} coins.
                  </div>
                </div>
                {isDevModeOn && <CodeModal name="ledger" code={ledger} />}
              </div>
            ))}
            <PaginationButtons
              section="Ledgers"
              currentPage={currentPage}
              isMoreItems={isMoreLedgers}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noLedgers}>
            <div className={STYLES.largeLabel}>There are no Ledgers yet.</div>
          </div>
        )}
      </div>
    )}
  </div>
);

Ledgers.propTypes = {
  ledgers: instanceOf(List),
  ledgersOrder: string,
  currentPage: number,
  isDevModeOn: bool,
  isMoreLedgers: bool,
  isFetchingLedgers: bool,
  toggleCodeModal: func.isRequired,
};

Ledgers.defaultProps = {
  ledgersOrder: 'desc',
  currentPage: 1,
  isDevModeOn: false,
  isMoreLedgers: false,
  isFetchingLedgers: false,
  onGetNextPage: () => {},
  onGetPreviousPage: () => {},
};

export default Ledgers;
