import React, { Component } from 'react';
import { instanceOf, bool } from 'prop-types';
import { Map } from 'immutable';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import AdvancedSearch from './AdvancedSearch';
import Featured from './Featured';
import Categories from './Categories';
import TabTitle from './TabTitle';

import STYLES from './TabbedList.module.scss';

class TabbedList extends Component {
  static propTypes = {
    featuredProjects: instanceOf(Map),
    isFetchingFeaturedProjects: bool,
  };

  static defaultProps = {
    featuredProjects: new Map(),
    isFetchingFeaturedProjects: false,
  };

  state = { activeTabKey: 1 };

  handleTabSelect = activeTabKey => {
    this.setState({ activeTabKey });
  };

  render() {
    const { activeTabKey } = this.state;
    const { featuredProjects, isFetchingFeaturedProjects } = this.props;

    return (
      <Tabs
        id="community-tabs"
        activeKey={activeTabKey}
        onSelect={this.handleTabSelect}
        className={STYLES.TabbedList}>
        <Tab
          eventKey={1}
          title={
            <TabTitle title="Featured" tabKey={1} activeTabKey={activeTabKey} />
          }>
          <Featured
            featuredProjects={featuredProjects}
            isFetchingFeaturedProjects={isFetchingFeaturedProjects}
          />
        </Tab>
        <Tab
          eventKey={2}
          title={
            <TabTitle
              title="Categories"
              tabKey={2}
              activeTabKey={activeTabKey}
            />
          }>
          <Categories />
        </Tab>
        <Tab
          eventKey={3}
          title={
            <TabTitle
              title="Advanced Search"
              tabKey={3}
              activeTabKey={activeTabKey}
            />
          }>
          <AdvancedSearch />
        </Tab>
      </Tabs>
    );
  }
}

export default TabbedList;
