import React, { Component } from 'react';
import { connect } from 'react-redux';
import { instanceOf, string, func } from 'prop-types';
import ReactJson from 'react-json-view';
import { Map } from 'immutable';

import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import { toggleCodeModal } from '../actions/ui';
import * as SELECTORS from '../selectors';

import STYLES from './CodeModal.module.scss';

const determineActive = (activeCodeModal, name, code) => {
  if (name === 'asset') return activeCodeModal === code.get('paging_token');
  return activeCodeModal === code.get('id');
};

class CodeModal extends Component {
  static propTypes = {
    code: instanceOf(Map).isRequired,
    name: string.isRequired,
    activeCodeModal: string,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    activeCodeModal: null,
  };

  handleHideModal = () => {
    const { onToggleCodeModal } = this.props;

    onToggleCodeModal(null);
  };

  render() {
    const { code, name, activeCodeModal } = this.props;

    return (
      <Modal
        bsSize="lg"
        show={determineActive(activeCodeModal, name, code)}
        onHide={this.handleHideModal}
        className={STYLES.CodeModal}>
        <Modal.Header closeButton>
          <Modal.Title className={STYLES.modalTitle}>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={STYLES.modalBody}>
          <ReactJson
            src={code.toJS()}
            name={name}
            collapsed={1}
            onAdd={false}
            onEdit={false}
            enableClipboard={false}
            onDelete={false}
            displayDataTypes={false}
            collapseStringsAfterLength={70}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleHideModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  activeCodeModal: SELECTORS.getActiveCodeModal(state),
});

const mapDispatchToProps = {
  onToggleCodeModal: toggleCodeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeModal);
