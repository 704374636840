import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_TRANSACTION_EFFECTS,
  GET_TRANSACTION_EFFECTS_ERROR,
  GET_TRANSACTION_EFFECTS_SUCCESS,
} from './types';

const getTransactionEffectsSuccess = ({
  transactionEffects,
  transactionEffectsOrder,
  isMoreTransactionEffects,
}) => ({
  type: GET_TRANSACTION_EFFECTS_SUCCESS,
  payload: {
    transactionEffects,
    transactionEffectsOrder,
    isMoreTransactionEffects,
    isFetchingTransactionEffects: false,
  },
});

const getTransactionEffectsError = error => ({
  type: GET_TRANSACTION_EFFECTS_ERROR,
  payload: { error, isFetchingTransactionEffects: false },
});

const requestTransactionEffects = () => ({
  type: REQUEST_TRANSACTION_EFFECTS,
  payload: { isFetchingTransactionEffects: true },
});

export default (transactionId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestTransactionEffects());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const effectsOrder = order ? order : 'desc';

    const res = await server
      .effects()
      .cursor(cursor)
      .order(effectsOrder)
      .limit(10)
      .forTransaction(transactionId)
      .call();
    const nextPage =
      effectsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getTransactionEffectsSuccess({
        transactionEffects: res.records,
        transactionEffectsOrder: effectsOrder,
        isMoreTransactionEffects: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getTransactionEffectsError(err));
  }
};
