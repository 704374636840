import React, { Component } from 'react';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import {
  getOperationDetails,
  getOperationEffects,
} from '../../actions/operation';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import InfoToolTip from '../../components/InfoToolTip';
import GoogleAd from '../../components/GoogleAd';
import CodeModal from '../../components/CodeModal';
import CodeModalToggle from '../../components/CodeModalToggle';
import TabbedList from './components/TabbedList';

import CreateAccountDetails from './components/CreateAccountDetails';
import PaymentDetails from './components/PaymentDetails';
import PaymentPathDetails from './components/PaymentPathDetails';
import ManageSellOfferDetails from './components/ManageSellOfferDetails';
import ManageBuyOfferDetails from './components/ManageBuyOfferDetails';
import CreatePassiveOfferDetails from './components/CreatePassiveOfferDetails';
import SetOptionsDetails from './components/SetOptionsDetails';
import ChangeTrustDetails from './components/ChangeTrustDetails';
import AllowTrustDetails from './components/AllowTrustDetails';
import AccountMergeDetails from './components/AccountMergeDetails';
import InflationDetails from './components/InflationDetails';
import ManageDataDetails from './components/ManageDataDetails';

import STYLES from './OperationDetailsPage.module.scss';
import { withHook } from '../../util/withHook';

const renderComponentDetails = operationDetails => {
  switch (operationDetails.get('type_i')) {
    case 0:
      return <CreateAccountDetails operationDetails={operationDetails} />;
    case 1:
      return <PaymentDetails operationDetails={operationDetails} />;
    case 2:
      return <PaymentPathDetails operationDetails={operationDetails} />;
    case 3:
      return <ManageSellOfferDetails operationDetails={operationDetails} />;
    case 4:
      return <CreatePassiveOfferDetails operationDetails={operationDetails} />;
    case 5:
      return <SetOptionsDetails operationDetails={operationDetails} />;
    case 6:
      return <ChangeTrustDetails operationDetails={operationDetails} />;
    case 7:
      return <AllowTrustDetails operationDetails={operationDetails} />;
    case 8:
      return <AccountMergeDetails operationDetails={operationDetails} />;
    case 9:
      return <InflationDetails operationDetails={operationDetails} />;
    case 10:
      return <ManageDataDetails operationDetails={operationDetails} />;
    case 12:
      return <ManageBuyOfferDetails operationDetails={operationDetails} />;
    default:
      return null;
  }
};

class OperationDetailsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    operationDetails: instanceOf(Map),
    operationEffects: instanceOf(List),
    operationEffectsOrder: string,
    isMoreOperationEffects: bool,
    isDevModeOn: bool,
    isFetchingOperationDetails: bool,
    isFetchingOperationEffects: bool,
    getOperationDetails: func.isRequired,
    getOperationEffects: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    operationDetails: new Map(),
    operationEffects: new List(),
    operationEffectsOrder: 'desc',
    isMoreOperationEffects: false,
    isDevModeOn: false,
    isFetchingOperationDetails: true,
    isFetchingOperationEffects: true,
  };

  state = {
    operationEffectsPage: 1,
  };

  componentDidMount = () => {
    const {
      match: { params, url },
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: url,
        title: 'Operation Details',
      });
    }

    this.props.getOperationDetails(params.operationId);
    this.props.getOperationEffects(params.operationId);
  };

  getNextPage = prop => {
    const {
      match: { params },
    } = this.props;

    const category = this.props[`operation${prop}`];
    const currentPage = this.state[`operation${prop}Page`];
    const pagingToken = category.get(-1).get('paging_token');

    this.props[`getOperation${prop}`](params.operationId, pagingToken, 'desc');
    this.setState({ [`operation${prop}Page`]: currentPage + 1 });
  };

  getPreviousPage = prop => {
    const {
      match: { params },
    } = this.props;
    const category = this.props[`operation${prop}`];
    const currentPage = this.state[`operation${prop}Page`];
    const pagingToken = category.get(0).get('paging_token');

    this.props[`getOperation${prop}`](params.operationId, pagingToken, 'asc');
    this.setState({ [`operation${prop}Page`]: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      operationDetails,
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = `StellarUp - Operation ${operationDetails &&
      operationDetails.get('id')}`;
    const description = `A detailed look into Stellar Lumens (XLM) cryptocurrency operation ${operationDetails &&
      operationDetails.get(
        'id'
      )}. Includes information on operation effects, date, operations count, and more.`;
    const operationUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={operationUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const { operationEffectsPage } = this.state;
    const {
      operationDetails,
      operationEffects,
      operationEffectsOrder,
      isMoreOperationEffects,
      isDevModeOn,
      isFetchingOperationDetails,
      onToggleCodeModal,
    } = this.props;

    return (
      <div className={STYLES.OperationDetailsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>Operation Details</h2>
                <InfoToolTip
                  title="Operation"
                  info="Transactions are made up of a list of operations. Each operation is an individual command that mutates the ledger. Operations are executed on behalf of the source account specified in the transaction, unless there is an override defined for the operation."
                  link="https://www.stellar.org/developers/guides/concepts/operations.html"
                  width="300px"
                  placement="bottom"
                  size="20px"
                />
                {isDevModeOn && (
                  <CodeModalToggle
                    id={operationDetails && operationDetails.get('id')}
                    toggleCodeModal={onToggleCodeModal}
                    buttonSize="large"
                  />
                )}
              </div>
              {isFetchingOperationDetails ? (
                <div className={STYLES.detailsLoading}>
                  <div className="loading-spinner-animation" />
                </div>
              ) : (
                <div className={STYLES.detailWrap}>
                  <div className={STYLES.summary}>
                    {!isFetchingOperationDetails &&
                      renderComponentDetails(operationDetails)}
                  </div>
                  <TabbedList
                    operationEffects={operationEffects}
                    operationEffectsPage={operationEffectsPage}
                    operationEffectsOrder={operationEffectsOrder}
                    isMoreOperationEffects={isMoreOperationEffects}
                    isDevModeOn={isDevModeOn}
                    toggleCodeModal={onToggleCodeModal}
                    onGetNextPage={this.getNextPage}
                    onGetPreviousPage={this.getPreviousPage}
                  />
                  {isDevModeOn && (
                    <CodeModal
                      name="operationDetails"
                      code={operationDetails}
                    />
                  )}
                  <div className={STYLES.adWrapBottom}>
                    <GoogleAd placement="Bottom" adSlot="7799510885" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  operationDetails: SELECTORS.getOperationDetails(state),
  operationEffects: SELECTORS.getOperationEffects(state),
  operationEffectsOrder: SELECTORS.getOperationEffectsOrder(state),
  isMoreOperationEffects: SELECTORS.getIsMoreOperationEffects(state),
  isFetchingOperationDetails: SELECTORS.getIsFetchingOperationDetails(state),
  isFetchingOperationEffects: SELECTORS.getIsFetchingOperationEffects(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getOperationDetails,
  getOperationEffects,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperationDetailsPage))
);
