import React from 'react';
import { number } from 'prop-types';

import { MdTrendingDown, MdTrendingFlat, MdTrendingUp } from 'react-icons/md';

import STYLES from './PriceStat.module.scss';

const positiveChange = percentChange24 => (
  <div className={STYLES.percentWrapper}>
    <div className={STYLES.positive}>{percentChange24.toFixed(2)}%</div>
    <div>
      <MdTrendingUp className={STYLES.upIcon} />
    </div>
  </div>
);

const negativeChange = percentChange24 => (
  <div className={STYLES.percentWrapper}>
    <div className={STYLES.negative}>{percentChange24.toFixed(2)}%</div>
    <div>
      <MdTrendingDown className={STYLES.downIcon} />
    </div>
  </div>
);

const noChange = percentChange24 => (
  <div className={STYLES.percentWrapper}>
    <div className={STYLES.negative}>{percentChange24.toFixed(2)}%</div>
    <div>
      <MdTrendingFlat className={STYLES.neutralIcon} />
    </div>
  </div>
);

const renderPercentChange = percent => {
  if (Number(percent) > 0) {
    return positiveChange(percent);
  }

  if (Number(percent) < 0) {
    return negativeChange(percent);
  }

  if (Number(percent) === 0) {
    return noChange(percent);
  }
};

const PriceStat = ({ priceUsd, percentChange24 }) => (
  <div className={STYLES.PriceStat}>
    <div>
      <div className={STYLES.stats}>
        <div className={STYLES.currencySymbol}>$</div>
        <div className={STYLES.currencyPrice}>{priceUsd}</div>
        <div className={STYLES.currencyAbbrev}>USD</div>
        {percentChange24 && renderPercentChange(percentChange24)}
      </div>
      <div className={STYLES.description}>PRICE XLM/USD</div>
    </div>
  </div>
);

PriceStat.propTypes = {
  priceUsd: number,
  percentChange24: number,
};

PriceStat.defaultProps = {
  priceUsd: 0,
  percentChange24: 0,
};

export default PriceStat;
