import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';

import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import STYLES from './HashPopover.module.scss';

const hashPopOver = hash => <Popover id="hash">{hash}</Popover>;

const HashPopover = ({ path, hash, matchId }) => (
  <span>
    {matchId === hash ? (
      <span className={STYLES.MatchedHash}>
        <OverlayTrigger placement="top" overlay={hashPopOver(hash)}>
          <div className={STYLES.truncateString}>{hash}</div>
        </OverlayTrigger>
      </span>
    ) : (
      <Link className={STYLES.HashPopover} to={`/${path}/${hash}`}>
        <OverlayTrigger placement="top" overlay={hashPopOver(hash)}>
          <div className={STYLES.truncateString}>{hash}</div>
        </OverlayTrigger>
      </Link>
    )}
  </span>
);

HashPopover.propTypes = {
  matchId: string,
  path: string.isRequired,
  hash: string.isRequired,
};

HashPopover.defaultProps = {
  matchId: '',
};

export default HashPopover;
