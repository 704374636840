import React from 'react';
import { Link } from 'react-router-dom';
import STYLES from './Footer.module.scss';

const Footer = () => (
  <div className={STYLES.Footer}>
    <div className={STYLES.bottomBar}>
      <div className={STYLES.copyright}>&#9400; 2024 Stellarup</div>
      <div className={STYLES.navLinks}>
        <Link to="/about">About</Link>
        <Link to="/disclaimer">Disclaimer</Link>
        <Link to="/privacy-policy">Privacy</Link>
      </div>
    </div>
  </div>
);

export default Footer;
