import React, { Component } from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import TabTitle from './TabTitle';
import Operations from '../../../components/operations/Operations';
import Payments from '../../../components/payments/Payments';
import Effects from '../../../components/effects/Effects';

import STYLES from './TabbedList.module.scss';

class TabbedList extends Component {
  static propTypes = {
    transactionEffects: instanceOf(List),
    transactionOperations: instanceOf(List),
    transactionPayments: instanceOf(List),
    transactionEffectsPage: number,
    transactionOperationsPage: number,
    transactionPaymentsPage: number,
    transactionEffectsOrder: string,
    transactionOperationsOrder: string,
    transactionPaymentsOrder: string,
    isMoreTransactionEffects: bool,
    isMoreTransactionOperations: bool,
    isMoreTransactionPayments: bool,
    isDevModeOn: bool,
    isFetchingTransactionEffects: bool,
    isFetchingTransactionOperations: bool,
    isFetchingTransactionPayments: bool,
    toggleCodeModal: func.isRequired,
    onGetNextPage: func,
    onGetPreviousPage: func,
  };

  static defaultProps = {
    transactionEffects: new List(),
    transactionOperations: new List(),
    transactionPayments: new List(),
    transactionEffectsPage: 1,
    transactionOperationsPage: 1,
    transactionPaymentsPage: 1,
    transactionEffectsOrder: 'desc',
    transactionOperationsOrder: 'desc',
    transactionPaymentsOrder: 'desc',
    isMoreTransactionEffects: false,
    isMoreTransactionOperations: false,
    isMoreTransactionPayments: false,
    isDevModeOn: false,
    isFetchingTransactionEffects: false,
    isFetchingTransactionOperations: false,
    isFetchingTransactionPayments: false,
    onGetNextPage: () => {},
    onGetPreviousPage: () => {},
  };

  state = { activeTabKey: 1 };

  handleTabSelect = activeTabKey => {
    this.setState({ activeTabKey });
  };

  render() {
    const {
      transactionEffects,
      transactionOperations,
      transactionPayments,
      transactionEffectsPage,
      transactionOperationsPage,
      transactionPaymentsPage,
      transactionEffectsOrder,
      transactionOperationsOrder,
      transactionPaymentsOrder,
      isMoreTransactionEffects,
      isMoreTransactionOperations,
      isMoreTransactionPayments,
      isDevModeOn,
      isFetchingTransactionEffects,
      isFetchingTransactionOperations,
      isFetchingTransactionPayments,
      toggleCodeModal,
      onGetNextPage,
      onGetPreviousPage,
    } = this.props;
    const { activeTabKey } = this.state;

    return (
      <Tabs
        id="ledger-tabs"
        activeKey={activeTabKey}
        onSelect={this.handleTabSelect}
        className={STYLES.TabbedList}>
        <Tab
          eventKey={1}
          title={
            <TabTitle
              title="Operations"
              tabKey={1}
              activeTabKey={activeTabKey}
            />
          }>
          <Operations
            operations={transactionOperations}
            operationsOrder={transactionOperationsOrder}
            currentPage={transactionOperationsPage}
            isMoreOperations={isMoreTransactionOperations}
            isDevModeOn={isDevModeOn}
            isFetchingOperations={isFetchingTransactionOperations}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={2}
          title={
            <TabTitle title="Payments" tabKey={2} activeTabKey={activeTabKey} />
          }>
          <Payments
            payments={transactionPayments}
            paymentsOrder={transactionPaymentsOrder}
            currentPage={transactionPaymentsPage}
            isMorePayments={isMoreTransactionPayments}
            isDevModeOn={isDevModeOn}
            isFetchingPayments={isFetchingTransactionPayments}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={3}
          title={
            <TabTitle title="Effects" tabKey={3} activeTabKey={activeTabKey} />
          }>
          <Effects
            effects={transactionEffects}
            effectsOrder={transactionEffectsOrder}
            currentPage={transactionEffectsPage}
            isMoreEffects={isMoreTransactionEffects}
            isDevModeOn={isDevModeOn}
            isFetchingEffects={isFetchingTransactionEffects}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default TabbedList;
