import React from 'react';
import { number, string } from 'prop-types';
import cx from 'classnames';

import STYLES from './TabTitle.module.scss';

const setActiveTabClass = (activeTabKey, tabKey) =>
  cx({
    [STYLES.TabTitle]: true,
    [STYLES.ActiveTabTitle]: activeTabKey === tabKey,
  });

const TabTitle = ({ activeTabKey, tabKey, title }) => (
  <div className={setActiveTabClass(activeTabKey, tabKey)}>
    <h3>{title}</h3>
  </div>
);

TabTitle.propTypes = {
  activeTabKey: number.isRequired,
  tabKey: number.isRequired,
  title: string.isRequired,
};

export default TabTitle;
