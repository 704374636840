import React, { Component } from 'react';
import { string, func } from 'prop-types';

import { Button } from 'react-bootstrap';
import { GoCode } from 'react-icons/go';

import STYLES from './CodeModalToggle.module.scss';

class CodeModalToggle extends Component {
  static propTypes = {
    id: string,
    buttonSize: string.isRequired,
    toggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    id: '',
  };

  handleCodeModalToggle = () => {
    const { toggleCodeModal, id } = this.props;

    toggleCodeModal(id);
  };

  render() {
    const { buttonSize } = this.props;

    return (
      <div className={STYLES.CodeModalToggle}>
        <Button
          className={
            buttonSize === 'large'
              ? STYLES.largeCodeButton
              : STYLES.smallCodeButton
          }
          onClick={this.handleCodeModalToggle}>
          <GoCode className={STYLES.codeIcon} />
        </Button>
      </div>
    );
  }
}

export default CodeModalToggle;
