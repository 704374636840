import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_TRANSACTION_OPERATIONS,
  GET_TRANSACTION_OPERATIONS_ERROR,
  GET_TRANSACTION_OPERATIONS_SUCCESS,
} from './types';

const getTransactionOperationsSuccess = ({
  transactionOperations,
  transactionOperationsOrder,
  isMoreTransactionOperations,
}) => ({
  type: GET_TRANSACTION_OPERATIONS_SUCCESS,
  payload: {
    transactionOperations,
    transactionOperationsOrder,
    isMoreTransactionOperations,
    isFetchingTransactionOperations: false,
  },
});

const getTransactionOperationsError = error => ({
  type: GET_TRANSACTION_OPERATIONS_ERROR,
  payload: { error, isFetchingTransactionOperations: false },
});

const requestTransactionOperations = () => ({
  type: REQUEST_TRANSACTION_OPERATIONS,
  payload: { isFetchingTransactionOperations: true },
});

export default (transactionId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestTransactionOperations());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const operationsOrder = order ? order : 'desc';

    const res = await server
      .operations()
      .cursor(cursor)
      .order(operationsOrder)
      .limit(10)
      .forTransaction(transactionId)
      .call();
    const nextPage =
      operationsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getTransactionOperationsSuccess({
        transactionOperations: res.records,
        transactionOperationsOrder: operationsOrder,
        isMoreTransactionOperations: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getTransactionOperationsError(err));
  }
};
