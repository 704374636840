import React from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';
import moment from 'moment';

import { OverlayTrigger, Popover } from 'react-bootstrap';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import PaginationButtons from '../PaginationButtons';

import STYLES from './Effects.module.scss';

const truncateString = hash => `${hash.substring(0, 14)}...`;
const effectPopOver = effectId => <Popover id="effect-id">{effectId}</Popover>;
const formatType = type => type.split('_').join(' ');

const determineOrder = (effects, effectsOrder) =>
  effectsOrder === 'asc' ? effects.reverse() : effects;

const Effects = ({
  effects,
  effectsOrder,
  currentPage,
  isMoreEffects,
  isFetchingEffects,
  isDevModeOn,
  toggleCodeModal,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Effects}>
    {isFetchingEffects ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {effects && effects.size ? (
          <div>
            {determineOrder(effects, effectsOrder).map(effect => (
              <div className={STYLES.effectRow} key={effect.get('id')}>
                <div className={STYLES.effectItem}>
                  <div className={STYLES.titleContainer}>
                    <h4>
                      <OverlayTrigger
                        placement="top"
                        overlay={effectPopOver(effect.get('id'))}>
                        <span>EFFECT - {truncateString(effect.get('id'))}</span>
                      </OverlayTrigger>
                      {isDevModeOn && (
                        <CodeModalToggle
                          id={effect.get('id')}
                          toggleCodeModal={toggleCodeModal}
                          buttonSize="small"
                        />
                      )}
                    </h4>
                    <div className={STYLES.label}>
                      {formatType(effect.get('type'))}
                    </div>
                  </div>
                  <div className={STYLES.date}>
                    Created:{' '}
                    {moment(effect.get('created_at')).format(
                      'MMM DD YYYY, h:mm a'
                    )}
                  </div>
                  <div className={STYLES.flexDetails}>
                    {formatType(effect.get('type'))}.
                  </div>
                </div>
                {isDevModeOn && <CodeModal name="effect" code={effect} />}
              </div>
            ))}
            <PaginationButtons
              section="Effects"
              currentPage={currentPage}
              isMoreItems={isMoreEffects}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noEffects}>
            <div className={STYLES.largeLabel}>
              There are no Effects for this item yet.
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

Effects.propTypes = {
  effects: instanceOf(List),
  effectsOrder: string,
  currentPage: number,
  isDevModeOn: bool,
  isFetchingEffects: bool,
  isMoreEffects: bool,
  toggleCodeModal: func.isRequired,
  onGetNextPage: func,
  onGetPreviousPage: func,
};

Effects.defaultProps = {
  effectsOrder: 'desc',
  currentPage: 1,
  isDevModeOn: false,
  isMoreEffects: false,
  isFetchingEffects: false,
  onGetNextPage: () => {},
  onGetPreviousPage: () => {},
};

export default Effects;
