import React from 'react';
import { number } from 'prop-types';

import STYLES from './VolumeStat.module.scss';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 0,
});

const formatCurrency = ammount => formatter.format(ammount);

const VolumeStat = ({ volume24 }) => (
  <div className={STYLES.VolumeStat}>
    <div>
      <div className={STYLES.stats}>
        <div className={STYLES.currencySymbol}>$</div>
        <div className={STYLES.currencyPrice}>{formatCurrency(volume24)}</div>
        <div className={STYLES.currencyAbbrev}>USD</div>
      </div>
      <div className={STYLES.description}>24h VOLUME </div>
    </div>
  </div>
);

VolumeStat.propTypes = {
  volume24: number,
};

VolumeStat.defaultProps = {
  volume24: 0,
};

export default VolumeStat;
