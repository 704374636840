import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ALL_OPERATIONS,
  GET_ALL_OPERATIONS_ERROR,
  GET_ALL_OPERATIONS_SUCCESS,
} from './types';

const getAllOperationsSuccess = ({ operations, operationsOrder }) => ({
  type: GET_ALL_OPERATIONS_SUCCESS,
  payload: {
    operations: operations,
    operationsOrder,
    isFetchingOperations: false,
  },
});

const getAllOperationsError = error => ({
  type: GET_ALL_OPERATIONS_ERROR,
  payload: {
    error,
    isFetchingOperations: false,
  },
});

const requestAllOperations = () => ({
  type: REQUEST_ALL_OPERATIONS,
  payload: {
    isFetchingOperations: true,
  },
});

export default (pagingToken, order) => async (dispatch, getState, api) => {
  dispatch(requestAllOperations());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const operationsOrder = order ? order : 'desc';

    const res = await server
      .operations()
      .cursor(cursor)
      .order(operationsOrder)
      .limit(10)
      .call();

    dispatch(
      getAllOperationsSuccess({
        operations: res.records,
        operationsOrder,
      })
    );
  } catch (err) {
    dispatch(getAllOperationsError(err));
  }
};
