import React from 'react';
import PropTypes from 'prop-types';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import PaginationButtons from '../PaginationButtons';
import HashPopover from '../HashPopover';

import STYLES from './Assets.module.scss';

const determineOrder = (assets, assetsOrder) =>
  assetsOrder === 'asc' ? assets.reverse() : assets;

const Assets = ({
  assets,
  assetsOrder,
  currentPage,
  isDevModeOn,
  toggleCodeModal,
  isFetchingAssets,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Assets}>
    {isFetchingAssets ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {assets && assets.size ? (
          <div>
            {determineOrder(assets, assetsOrder).map((asset, i) => (
              <div className={STYLES.assetRow} key={i}>
                <div className={STYLES.assetItem}>
                  <div className={STYLES.titleContainer}>
                    <h4>
                      {asset.get('asset_code')}
                      {isDevModeOn && (
                        <CodeModalToggle
                          id={asset.get('paging_token')}
                          toggleCodeModal={toggleCodeModal}
                          buttonSize="small"
                        />
                      )}
                    </h4>
                    <div className={STYLES.label}>
                      AMOUNT {asset.get('amount')}
                    </div>
                  </div>
                  <p className={STYLES.date}>
                    Account: {asset.get('num_accounts')}
                  </p>
                  <div className={STYLES.flexDetails}>
                    Issued by{' '}
                    <HashPopover
                      path="accounts"
                      hash={asset.get('asset_issuer')}
                    />
                    .
                  </div>
                </div>
                {isDevModeOn && <CodeModal name="asset" code={asset} />}
              </div>
            ))}
            <PaginationButtons
              section="Assets"
              currentPage={currentPage}
              isMoreItems={true}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noAssets}>
            <div className={STYLES.largeLabel}>There are no Asset.</div>
          </div>
        )}
      </div>
    )}
  </div>
);

export default Assets;
