export const REQUEST_ACCOUNT_DETAILS = 'REQUEST_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_ERROR = 'GET_ACCOUNT_DETAILS_ERROR';

export const REQUEST_ACCOUNT_EFFECTS = 'REQUEST_ACCOUNT_EFFECTS';
export const GET_ACCOUNT_EFFECTS_SUCCESS = 'GET_ACCOUNT_EFFECTS_SUCCESS';
export const GET_ACCOUNT_EFFECTS_ERROR = 'GET_ACCOUNT_EFFECTS_ERROR';

export const REQUEST_ACCOUNT_OPERATIONS = 'REQUEST_ACCOUNT_OPERATIONS';
export const GET_ACCOUNT_OPERATIONS_SUCCESS = 'GET_ACCOUNT_OPERATIONS_SUCCESS';
export const GET_ACCOUNT_OPERATIONS_ERROR = 'GET_ACCOUNT_OPERATIONS_ERROR';

export const REQUEST_ACCOUNT_TRANSACTIONS = 'REQUEST_ACCOUNT_TRANSACTIONS';
export const GET_ACCOUNT_TRANSACTIONS_SUCCESS =
  'GET_ACCOUNT_TRANSACTIONS_SUCCESS';
export const GET_ACCOUNT_TRANSACTIONS_ERROR = 'GET_ACCOUNT_TRANSACTIONS_ERROR';

export const REQUEST_ACCOUNT_PAYMENTS = 'REQUEST_ACCOUNT_PAYMENTS';
export const GET_ACCOUNT_PAYMENTS_SUCCESS = 'GET_ACCOUNT_PAYMENTS_SUCCESS';
export const GET_ACCOUNT_PAYMENTS_ERROR = 'GET_ACCOUNT_PAYMENTS_ERROR';

export const REQUEST_ACCOUNT_OFFERS = 'REQUEST_ACCOUNT_OFFERS';
export const GET_ACCOUNT_OFFERS_SUCCESS = 'GET_ACCOUNT_OFFERS_SUCCESS';
export const GET_ACCOUNT_OFFERS_ERROR = 'GET_ACCOUNT_OFFERS_ERROR';
