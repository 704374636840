import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { IntlProvider } from 'react-intl-redux';
import createStore from './store/configureStore';
// import * as serviceWorker from './serviceWorker';
import App from './App';
import HomePage from './pages/home/HomePage';
import AccountPage from './pages/account/AccountPage';
import OperationsPage from './pages/operation/OperationsPage';
import OperationDetailsPage from './pages/operation/OperationDetailsPage';
import TransactionPage from './pages/transaction/TransactionPage';
import TransactionDetailsPage from './pages/transaction/TransactionDetailsPage';
import AssetsPage from './pages/assets/AssetsPage';
import LedgersPage from './pages/ledger/LedgersPage';
import LedgerDetailsPage from './pages/ledger/LedgerDetailsPage';
import NotFoundPage from './pages/NotFoundPage';
import DisclaimerPage from './pages/disclaimer/DisclaimerPage';
import AboutPage from './pages/about/AboutPage';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import DevelopersPage from './pages/developers/DevelopersPage';
import CommunityPage from './pages/community/CommunityPage';
import ProjectsPage from './pages/community/ProjectsPage';

export const history = createBrowserHistory();
const store = createStore(history);

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-ES5XGCNPSE');
}

const Root = () => {
  return (
    <Provider store={store}>
      <IntlProvider>
        <BrowserRouter history={history}>
          <Routes>
            <Route element={<App />}>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/accounts/:accountId" element={<AccountPage />} />
              <Route exact path="/operations" element={<OperationsPage />} />
              <Route
                path="/operations/:operationId"
                element={<OperationDetailsPage />}
              />
              <Route exact path="/transactions" element={<TransactionPage />} />
              <Route
                path="/transactions/:transactionId"
                element={<TransactionDetailsPage />}
              />
              <Route exact path="/assets" element={<AssetsPage />} />
              <Route exact path="/ledgers" element={<LedgersPage />} />
              <Route
                path="/ledgers/:ledgerId"
                element={<LedgerDetailsPage />}
              />
              <Route path="/developers" element={<DevelopersPage />} />
              <Route path="/community" element={<CommunityPage />} />
              <Route
                path="/community/:projectCategory"
                element={<ProjectsPage />}
              />
              <Route path="/disclaimer" element={<DisclaimerPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
