import React, { Component } from 'react';
import { instanceOf, bool } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import { FaAnchor } from 'react-icons/fa';
import {
  MdAccountBalanceWallet,
  MdAutorenew,
  MdArrowDropDownCircle,
} from 'react-icons/md';
import { GoTools, GoGear } from 'react-icons/go';

import Project from './Project';

import STYLES from './Featured.module.scss';

const backgroundImg = bgUrl => ({
  height: '100%',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${bgUrl})`,
});

const FEATURED_CATEGORIES = [
  { name: 'anchors', title: 'ANCHORS', icon: <FaAnchor /> },
  { name: 'exchanges', title: 'EXCHANGES', icon: <MdAutorenew /> },
  { name: 'wallets', title: 'WALLETS', icons: <MdAccountBalanceWallet /> },
  { name: 'utilities', title: 'UTILITIES', icon: <GoGear /> },
  { name: 'devTools', title: 'DEV TOOLS', icon: <GoTools /> },
];

class Featured extends Component {
  static propTypes = {
    featuredProjects: instanceOf(Map),
    isFetchingFeaturedProjects: bool,
  };

  static defaultProps = {
    isFetchingFeaturedProjects: false,
  };

  state = {
    currentSlide: 0,
  };

  handleNext = () => {
    this.refs.slider1.slickNext();
    this.refs.slider2.slickNext();
  };

  handleNav = () => {
    const { currentSlide } = this.state;
    const links = [
      'https://www.stellarx.com/',
      'https://stronghold.co',
      'https://lobstr.co/',
    ];

    window.open(links[currentSlide], '_blank');
  };

  handleBefore = (oldIndex, newIndex) => {
    this.setState({ currentSlide: newIndex });
  };

  render() {
    const { featuredProjects, isFetchingFeaturedProjects } = this.props;

    const featuredSettings = {
      dots: false,
      arrows: false,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 7000,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.handleBefore,
    };

    const verticalSettings = {
      dots: false,
      arrows: false,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 7000,
      infinite: true,
      vertical: true,
      slidesToShow: 3,
      slidesToScroll: 1,
    };

    return (
      <div className={STYLES.Featured}>
        {isFetchingFeaturedProjects ? (
          <div>
            <div className="loading-spinner-animation" />
          </div>
        ) : (
          <div className={STYLES.sliderWrap}>
            <Slider
              ref="slider1"
              className={STYLES.featuredSlider}
              {...featuredSettings}>
              <div onClick={this.handleNav} className={STYLES.slide}>
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/stellarx.png'
                  )}
                />
              </div>
              <div onClick={this.handleNav} className={STYLES.slide}>
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/stronghold.jpg'
                  )}
                />
              </div>
              <div onClick={this.handleNav} className={STYLES.slide}>
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/lobstr.png'
                  )}
                />
              </div>
            </Slider>
            <Slider
              ref="slider2"
              className={STYLES.verticalSlider}
              {...verticalSettings}>
              <a
                className={STYLES.vslide}
                href="https://www.binance.com/?ref=13859220"
                target="_blank"
                rel="noopener noreferrer">
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/binanceSlide.png'
                  )}
                />
              </a>
              <a
                className={STYLES.vslide}
                href="https://bittrex.com/"
                target="_blank"
                rel="noopener noreferrer">
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/bittrexSlide.jpg'
                  )}
                />
              </a>
              <a
                className={STYLES.vslide}
                href="https://changelly.com/?ref_id=657f82a46faa"
                target="_blank"
                rel="noopener noreferrer">
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/changelly.png'
                  )}
                />
              </a>
              <a
                className={STYLES.vslide}
                href="https://www.ledgerwallet.com/r/82eb"
                target="_blank"
                rel="noopener noreferrer">
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/ledger.png'
                  )}
                />
              </a>
              <a
                className={STYLES.vslide}
                href="https://cex.io/"
                target="_blank"
                rel="noopener noreferrer">
                <div
                  style={backgroundImg(
                    'https://s3.amazonaws.com/stellarup-assets/images/cexSlide.png'
                  )}
                />
              </a>
            </Slider>
            <MdArrowDropDownCircle
              className={STYLES.nextButton}
              fill="#FFF"
              onClick={this.handleNext}
            />
          </div>
        )}
        {FEATURED_CATEGORIES.map(category => (
          <div key={category.name}>
            <div className={STYLES.header}>
              <div className={STYLES.headerInside}>
                <h2 className={STYLES.sectionTitle}>
                  {category.icon} {category.title}
                </h2>
                <Link
                  className={STYLES.seeAll}
                  to={`/community/${category.name}`}>
                  See All
                </Link>
              </div>
            </div>
            <div className={STYLES.projects}>
              {isFetchingFeaturedProjects ? (
                <div>
                  <div className="loading-spinner-animation" />
                </div>
              ) : (
                <div>
                  {featuredProjects &&
                    featuredProjects.get(category.name) &&
                    featuredProjects
                      .get(category.name)
                      .map(project => (
                        <Project
                          key={project.get('name')}
                          name={project.get('name')}
                          url={project.get('url')}
                          github={project.get('github')}
                          category={project.get('category')}
                          license={project.get('license')}
                          sbc={project.get('sbc')}
                          platform={project.get('platform')}
                        />
                      ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Featured;
