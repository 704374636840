import { Map } from 'immutable';
import * as types from '../actions/account/types';

const ACTIONS = {
  // Account Details
  [types.REQUEST_ACCOUNT_DETAILS]: (state, action) => {
    const { isFetchingAccountDetails } = action.payload;
    return state.merge({ isFetchingAccountDetails });
  },
  [types.GET_ACCOUNT_DETAILS_SUCCESS]: (state, action) => {
    const { accountDetails, isFetchingAccountDetails } = action.payload;
    return state.merge({ accountDetails, isFetchingAccountDetails });
  },
  [types.GET_ACCOUNT_DETAILS_ERROR]: (state, action) => {
    const { error, isFetchingAccountDetails } = action.payload;
    return state.merge({ error, isFetchingAccountDetails });
  },
  // Account Effects
  [types.REQUEST_ACCOUNT_EFFECTS]: (state, action) => {
    const { isFetchingAccountEffects } = action.payload;
    return state.merge({ isFetchingAccountEffects });
  },
  [types.GET_ACCOUNT_EFFECTS_SUCCESS]: (state, action) => {
    const {
      accountEffects,
      accountEffectsOrder,
      isMoreAccountEffects,
      isFetchingAccountEffects,
    } = action.payload;
    return state.merge({
      accountEffects,
      accountEffectsOrder,
      isMoreAccountEffects,
      isFetchingAccountEffects,
    });
  },
  [types.GET_ACCOUNT_EFFECTS_ERROR]: (state, action) => {
    const { error, isFetchingAccountEffects } = action.payload;
    return state.merge({ error, isFetchingAccountEffects });
  },
  // Account Operations
  [types.REQUEST_ACCOUNT_OPERATIONS]: (state, action) => {
    const { isFetchingAccountOperations } = action.payload;
    return state.merge({ isFetchingAccountOperations });
  },
  [types.GET_ACCOUNT_OPERATIONS_SUCCESS]: (state, action) => {
    const {
      accountOperations,
      accountOperationsOrder,
      isMoreAccountOperations,
      isFetchingAccountOperations,
    } = action.payload;
    return state.merge({
      accountOperations,
      accountOperationsOrder,
      isMoreAccountOperations,
      isFetchingAccountOperations,
    });
  },
  [types.GET_ACCOUNT_OPERATIONS_ERROR]: (state, action) => {
    const { error, isFetchingAccountOperations } = action.payload;
    return state.merge({ error, isFetchingAccountOperations });
  },
  // Account Transactions
  [types.REQUEST_ACCOUNT_TRANSACTIONS]: (state, action) => {
    const { isFetchingAccountTransactions } = action.payload;
    return state.merge({ isFetchingAccountTransactions });
  },
  [types.GET_ACCOUNT_TRANSACTIONS_SUCCESS]: (state, action) => {
    const {
      accountTransactions,
      accountTransactionsOrder,
      isMoreAccountTransactions,
      isFetchingAccountTransactions,
    } = action.payload;
    return state.merge({
      accountTransactions,
      accountTransactionsOrder,
      isMoreAccountTransactions,
      isFetchingAccountTransactions,
    });
  },
  [types.GET_ACCOUNT_TRANSACTIONS_ERROR]: (state, action) => {
    const { error, isFetchingAccountTransactions } = action.payload;
    return state.merge({ error, isFetchingAccountTransactions });
  },
  // Account Payments
  [types.REQUEST_ACCOUNT_PAYMENTS]: (state, action) => {
    const { isFetchingAccountPayments } = action.payload;
    return state.merge({ isFetchingAccountPayments });
  },
  [types.GET_ACCOUNT_PAYMENTS_SUCCESS]: (state, action) => {
    const {
      accountPayments,
      accountPaymentsOrder,
      isMoreAccountPayments,
      isFetchingAccountPayments,
    } = action.payload;
    return state.merge({
      accountPayments,
      accountPaymentsOrder,
      isMoreAccountPayments,
      isFetchingAccountPayments,
    });
  },
  [types.GET_ACCOUNT_PAYMENTS_ERROR]: (state, action) => {
    const { error, isFetchingAccountPayments } = action.payload;
    return state.merge({ error, isFetchingAccountPayments });
  },
  // Account Offers
  [types.REQUEST_ACCOUNT_OFFERS]: (state, action) => {
    const { isFetchingAccountOffers } = action.payload;
    return state.merge({ isFetchingAccountOffers });
  },
  [types.GET_ACCOUNT_OFFERS_SUCCESS]: (state, action) => {
    const {
      accountOffers,
      accountOffersOrder,
      isMoreAccountOffers,
      isFetchingAccountOffers,
    } = action.payload;
    return state.merge({
      accountOffers,
      accountOffersOrder,
      isMoreAccountOffers,
      isFetchingAccountOffers,
    });
  },
  [types.GET_ACCOUNT_OFFERS_ERROR]: (state, action) => {
    const { error, isFetchingAccountOffers } = action.payload;
    return state.merge({ error, isFetchingAccountOffers });
  },
};

export default function accountReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
