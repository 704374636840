import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './OperationComponent.module.scss';

const AllowTrust = ({ operation, matchId, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>ALLOW TRUST</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    {operation.get('authorize') ? (
      <div className={STYLES.flexDetails}>
        <HashPopover
          path="accounts"
          hash={operation.get('asset_issuer')}
          matchId={matchId}
        />
        allowing trustline to{' '}
        {operation.get('asset_type') === 'native'
          ? 'XLM'
          : operation.get('asset_code')}{' '}
        for
        <HashPopover
          path="accounts"
          hash={operation.get('trustor')}
          matchId={matchId}
        />
        .
      </div>
    ) : (
      <div className={STYLES.flexDetails}>
        <HashPopover
          path="accounts"
          hash={operation.get('asset_issuer')}
          matchId={matchId}
        />
        revoking trustline to{' '}
        {operation.get('asset_type') === 'native'
          ? 'XLM'
          : operation.get('asset_code')}{' '}
        for
        <HashPopover
          path="accounts"
          hash={operation.get('trustor')}
          matchId={matchId}
        />
        .
      </div>
    )}
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

AllowTrust.propTypes = {
  matchId: string,
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

AllowTrust.defaultProps = {
  matchId: '',
  isDevModeOn: false,
};

export default AllowTrust;
