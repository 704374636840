import React from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import PaginationButtons from '../PaginationButtons';
import PaymentPath from './PaymentPath';
import Payment from './Payment';
import CreateAccount from './CreateAccount';

import STYLES from './Payments.module.scss';

const determineOrder = (payments, paymentsOrder) =>
  paymentsOrder === 'asc' ? payments.reverse() : payments;

const Payments = ({
  payments,
  paymentsOrder,
  isFetchingPayments,
  currentPage,
  matchId,
  isDevModeOn,
  isMorePayments,
  toggleCodeModal,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Payments}>
    {isFetchingPayments ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {payments && payments.size ? (
          <div>
            {determineOrder(payments, paymentsOrder).map(payment => (
              <div className={STYLES.paymentRow} key={payment.get('id')}>
                {payment.get('type_i') === 0 && (
                  <CreateAccount
                    payment={payment}
                    matchId={matchId}
                    isDevModeOn={isDevModeOn}
                    toggleCodeModal={toggleCodeModal}
                  />
                )}
                {payment.get('type_i') === 1 && (
                  <Payment
                    payment={payment}
                    matchId={matchId}
                    isDevModeOn={isDevModeOn}
                    toggleCodeModal={toggleCodeModal}
                  />
                )}
                {payment.get('type_i') === 2 && (
                  <PaymentPath
                    payment={payment}
                    matchId={matchId}
                    isDevModeOn={isDevModeOn}
                    toggleCodeModal={toggleCodeModal}
                  />
                )}
              </div>
            ))}
            <PaginationButtons
              section="Payments"
              currentPage={currentPage}
              isMoreItems={isMorePayments}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noPayments}>
            <div className={STYLES.largeLabel}>
              There are no Payments for this item yet.
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

Payments.propTypes = {
  matchId: string,
  payments: instanceOf(List),
  paymentsOrder: string,
  currentPage: number,
  isDevModeOn: bool,
  isMorePayments: bool,
  isFetchingPayments: bool,
  toggleCodeModal: func.isRequired,
  onGetNextPage: func,
  onGetPreviousPage: func,
};

Payments.defaultProps = {
  matchId: '',
  paymentsOrder: 'desc',
  currentPage: 1,
  isDevModeOn: false,
  isMorePayments: false,
  isFetchingPayments: false,
  onGetNextPage: () => {},
  onGetPreviousPage: () => {},
};

export default Payments;
