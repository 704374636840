import React, { Component } from 'react';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import {
  getAccountOffers,
  getAccountPayments,
  getAccountDetails,
  getAccountEffects,
  getAccountOperations,
  getAccountTransactions,
} from '../../actions/account';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import InfoToolTip from '../../components/InfoToolTip';
import GoogleAd from '../../components/GoogleAd';
import CodeModal from '../../components/CodeModal';
import CodeModalToggle from '../../components/CodeModalToggle';
import TabbedList from './components/TabbedList';
import DetailsSummary from './components/DetailsSummary';

import STYLES from './AccountPage.module.scss';
import { withHook } from '../../util/withHook';

class AccountPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    accountDetails: instanceOf(Map),
    accountPayments: instanceOf(List),
    accountEffects: instanceOf(List),
    accountOffers: instanceOf(List),
    accountOperations: instanceOf(List),
    accountTransactions: instanceOf(List),
    accountTransactionsOrder: string,
    accountOperationsOrder: string,
    accountEffectsOrder: string,
    accountPaymentsOrder: string,
    accountOffersOrder: string,
    isDevModeOn: bool,
    isMoreAccountTransactions: bool,
    isMoreAccountOperations: bool,
    isMoreAccountEffects: bool,
    isMoreAccountPayments: bool,
    isMoreAccountOffers: bool,
    isFetchingAccountDetails: bool,
    isFetchingAccountEffects: bool,
    isFetchingAccountOperations: bool,
    isFetchingAccountTransactions: bool,
    isFetchingAccountPayments: bool,
    isFetchingAccountOffers: bool,
    getAccountDetails: func.isRequired,
    getAccountOffers: func.isRequired,
    getAccountPayments: func.isRequired,
    getAccountEffects: func.isRequired,
    getAccountOperations: func.isRequired,
    getAccountTransactions: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    isMoreAccountTransactions: false,
    isMoreAccountOperations: false,
    isMoreAccountEffects: false,
    isMoreAccountPayments: false,
    isMoreAccountOffers: false,
    accountTransactionsOrder: 'desc',
    accountOperationsOrder: 'desc',
    accountEffectsOrder: 'desc',
    accountPaymentsOrder: 'desc',
    accountOffersOrder: 'desc',
    isDevModeOn: false,
    isFetchingAccountDetails: false,
    isFetchingAccountEffects: false,
    isFetchingAccountOperations: false,
    isFetchingAccountTransactions: false,
    isFetchingAccountPayments: false,
    isFetchingAccountOffers: false,
  };

  state = {
    accountOffersPage: 1,
    accountPaymentsPage: 1,
    accountEffectsPage: 1,
    accountOperationsPage: 1,
    accountTransactionsPage: 1,
  };

  componentDidMount = () => {
    const {
      match: { params, url },
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: url,
        title: 'Account',
      });
    }

    this.props.getAccountDetails(params.accountId);
    this.props.getAccountOffers(params.accountId);
    this.props.getAccountPayments(params.accountId);
    this.props.getAccountEffects(params.accountId);
    this.props.getAccountOperations(params.accountId);
    this.props.getAccountTransactions(params.accountId);
  };

  getNextPage = prop => {
    const {
      match: { params },
    } = this.props;

    const category = this.props[`account${prop}`];
    const currentPage = this.state[`account${prop}Page`];
    const pagingToken = category.get(-1).get('paging_token');

    this.props[`getAccount${prop}`](params.accountId, pagingToken, 'desc');
    this.setState({ [`account${prop}Page`]: currentPage + 1 });
  };

  getPreviousPage = prop => {
    const {
      match: { params },
    } = this.props;
    const category = this.props[`account${prop}`];
    const currentPage = this.state[`account${prop}Page`];
    const pagingToken = category.get(0).get('paging_token');

    this.props[`getAccount${prop}`](params.accountId, pagingToken, 'asc');
    this.setState({ [`account${prop}Page`]: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      accountDetails,
      match: { url },
    } = this.props;
    const accountId = accountDetails && accountDetails.get('id');
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = `StellarUp - Account ${accountId}`;
    const description = `A detailed look into Stellar Lumens (XLM) cryptocurrency account ${accountId}. Includes information on account operations, transactions, effects, payments, and more.`;
    const accountUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={accountUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      accountTransactionsPage,
      accountOperationsPage,
      accountEffectsPage,
      accountPaymentsPage,
      accountOffersPage,
    } = this.state;
    const {
      accountDetails,
      accountEffects,
      accountOperations,
      accountTransactions,
      accountPayments,
      accountOffers,
      accountTransactionsOrder,
      accountOperationsOrder,
      accountEffectsOrder,
      accountPaymentsOrder,
      accountOffersOrder,
      isDevModeOn,
      isMoreAccountTransactions,
      isMoreAccountOperations,
      isMoreAccountEffects,
      isMoreAccountPayments,
      isMoreAccountOffers,
      isFetchingAccountDetails,
      isFetchingAccountEffects,
      isFetchingAccountOperations,
      isFetchingAccountTransactions,
      isFetchingAccountPayments,
      isFetchingAccountOffers,
      onToggleCodeModal,
    } = this.props;

    return (
      <div className={STYLES.AccountPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>Account</h2>
                <InfoToolTip
                  title="Account"
                  info="Accounts are the central data structure in Stellar. Accounts are identified by a public key and saved in the ledger. Everything else in the ledger, such as offers or trustlines, are owned by a particular account."
                  link="https://www.stellar.org/developers/guides/concepts/accounts.html"
                  width="300px"
                  placement="bottom"
                  size="20px"
                />
                {isDevModeOn && (
                  <CodeModalToggle
                    id={accountDetails && accountDetails.get('id')}
                    toggleCodeModal={onToggleCodeModal}
                    buttonSize="large"
                  />
                )}
              </div>
              {isFetchingAccountDetails || isFetchingAccountOperations ? (
                <div className={STYLES.detailsLoading}>
                  <div className="loading-spinner-animation" />
                </div>
              ) : (
                <div className={STYLES.detailWrap}>
                  <DetailsSummary accountDetails={accountDetails} />
                  <TabbedList
                    accountId={accountDetails && accountDetails.get('id')}
                    accountBalances={
                      accountDetails && accountDetails.get('balances')
                    }
                    accountSigners={
                      accountDetails && accountDetails.get('signers')
                    }
                    accountEffects={accountEffects}
                    accountEffectsPage={accountEffectsPage}
                    accountOperations={accountOperations}
                    accountOperationsPage={accountOperationsPage}
                    accountTransactions={accountTransactions}
                    accountTransactionsPage={accountTransactionsPage}
                    accountPayments={accountPayments}
                    accountPaymentsPage={accountPaymentsPage}
                    accountOffers={accountOffers}
                    accountOffersPage={accountOffersPage}
                    accountTransactionsOrder={accountTransactionsOrder}
                    accountOperationsOrder={accountOperationsOrder}
                    accountEffectsOrder={accountEffectsOrder}
                    accountPaymentsOrder={accountPaymentsOrder}
                    accountOffersOrder={accountOffersOrder}
                    isDevModeOn={isDevModeOn}
                    isMoreAccountTransactions={isMoreAccountTransactions}
                    isMoreAccountOperations={isMoreAccountOperations}
                    isMoreAccountEffects={isMoreAccountEffects}
                    isMoreAccountPayments={isMoreAccountPayments}
                    isMoreAccountOffers={isMoreAccountOffers}
                    isFetchingAccountEffects={isFetchingAccountEffects}
                    isFetchingAccountOperations={isFetchingAccountOperations}
                    isFetchingAccountTransactions={
                      isFetchingAccountTransactions
                    }
                    isFetchingAccountPayments={isFetchingAccountPayments}
                    isFetchingAccountOffers={isFetchingAccountOffers}
                    toggleCodeModal={onToggleCodeModal}
                    onGetNextPage={this.getNextPage}
                    onGetPreviousPage={this.getPreviousPage}
                  />
                  {isDevModeOn && accountDetails && (
                    <CodeModal name="accountDetails" code={accountDetails} />
                  )}
                  <div className={STYLES.adWrapBottom}>
                    <GoogleAd placement="Bottom" adSlot="7799510885" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accountDetails: SELECTORS.getAccountDetails(state),
  accountEffects: SELECTORS.getAccountEffects(state),
  accountOperations: SELECTORS.getAccountOperations(state),
  accountTransactions: SELECTORS.getAccountTransactions(state),
  accountOffers: SELECTORS.getAccountOffers(state),
  accountPayments: SELECTORS.getAccountPayments(state),
  accountPaymentsOrder: SELECTORS.getAccountPaymentsOrder(state),
  accountTransactionsOrder: SELECTORS.getAccountTransactionsOrder(state),
  accountOperationsOrder: SELECTORS.getAccountOperationsOrder(state),
  accountEffectsOrder: SELECTORS.getAccountEffectsOrder(state),
  accountOffersOrder: SELECTORS.getAccountOffersOrder(state),
  isMoreAccountTransactions: SELECTORS.getIsMoreAccountTransactions(state),
  isMoreAccountOperations: SELECTORS.getIsMoreAccountOperations(state),
  isMoreAccountEffects: SELECTORS.getIsMoreAccountEffects(state),
  isMoreAccountPayments: SELECTORS.getIsMoreAccountPayments(state),
  isMoreAccountOffers: SELECTORS.getIsMoreAccountOffers(state),
  isFetchingAccountDetails: SELECTORS.getIsFetchingAccountDetails(state),
  isFetchingAccountEffects: SELECTORS.getIsFetchingAccountEffects(state),
  isFetchingAccountOperations: SELECTORS.getIsFetchingAccountOperations(state),
  isFetchingAccountTransactions: SELECTORS.getIsFetchingAccountTransactions(
    state
  ),
  isFetchingAccountPayments: SELECTORS.getIsFetchingAccountPayments(state),
  isFetchingAccountOffers: SELECTORS.getIsFetchingAccountOffers(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getAccountDetails,
  getAccountEffects,
  getAccountOperations,
  getAccountTransactions,
  getAccountOffers,
  getAccountPayments,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountPage))
);
