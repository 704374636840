import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { MdCheckCircle, MdError } from 'react-icons/md';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsComponent.module.scss';

const PaymentDetails = ({ operationDetails }) => (
  <div className={STYLES.DetailsComponent}>
    <h3>
      Operation Type{' '}
      <InfoToolTip
        title="Payment"
        info="A payment operation represents a payment from one account to another. This payment can be either a simple native asset payment or a fiat asset payment."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.mainDetails}>Payment</div>
    <h3>
      Transaction Hash{' '}
      <InfoToolTip
        title="Transaction Hash"
        info="A hex-encoded SHA-256 hash of the transaction’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {operationDetails.get('transaction_hash')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Status</span>
        <InfoToolTip
          title="Status"
          info="Indicates if transaction was successful or not."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {operationDetails.get('transaction_successful') ? (
            <div className={STYLES.successLabel}>
              <MdCheckCircle className={STYLES.statusIcon} /> Successful
            </div>
          ) : (
            <div className={STYLES.failedLabel}>
              <MdError className={STYLES.statusIcon} /> Failed
            </div>
          )}
        </span>
      </div>
      {operationDetails && operationDetails.get('created_at') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Created at</span>
          <InfoToolTip
            title="Created at"
            info="The timestamp of when the operation was created."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {moment(operationDetails.get('created_at')).format('LLL')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('to') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Payment to</span>
          <InfoToolTip
            title="Payment to"
            info="Destination of the payment."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('to')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('from') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Payment from</span>
          <InfoToolTip
            title="Payment from"
            info="Sender of the payment."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('from')}
          </span>
        </div>
      )}
      {operationDetails &&
        operationDetails.get('amount') &&
        operationDetails.get('asset_type') && (
          <div className={STYLES.keyValue}>
            <span className={STYLES.key}>Amount</span>
            <InfoToolTip
              title="Amount"
              info="Amount of the asset to send."
              placement="top"
              size="15px"
              link="https://www.stellar.org/developers/guides/concepts/operations.html"
            />
            <span className={STYLES.value}>
              {operationDetails.get('amount')}{' '}
              {operationDetails.get('asset_type') === 'native'
                ? 'XLM'
                : operationDetails.get('asset_code')}
            </span>
          </div>
        )}
    </div>
  </div>
);

PaymentDetails.propTypes = {
  operationDetails: instanceOf(Map),
};

PaymentDetails.defaultProps = {
  operationDetails: new Map(),
};

export default PaymentDetails;
