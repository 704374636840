import React from 'react';
import { instanceOf, string, bool } from 'prop-types';
import { Map } from 'immutable';

import PriceGraph from './PriceGraph';

import STYLES from './VisualData.module.scss';

const VisualData = ({
  weekHistory,
  monthHistory,
  yearHistory,
  graphTimePeriod,
  isFetchingCoinHistory,
}) => (
  <div className={STYLES.VisualData}>
    <div className={STYLES.graphWrapper}>
      <PriceGraph
        weekHistory={weekHistory}
        monthHistory={monthHistory}
        yearHistory={yearHistory}
        graphTimePeriod={graphTimePeriod}
        isFetchingCoinHistory={isFetchingCoinHistory}
      />
    </div>
  </div>
);

VisualData.propTypes = {
  weekHistory: instanceOf(Map),
  monthHistory: instanceOf(Map),
  yearHistory: instanceOf(Map),
  graphTimePeriod: string,
  isFetchingCoinHistory: bool,
};

VisualData.defaultProps = {
  weekHistory: new Map(),
  monthHistory: new Map(),
  yearHistory: new Map(),
  graphTimePeriod: 'YEAR',
  isFetchingCoinHistory: false,
};

export default VisualData;
