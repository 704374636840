import { Map } from 'immutable';
import * as types from '../actions/ui/types';

const ACTIONS = {
  [types.TOGGLE_SIDEBAR]: (state, action) => {
    const { isSidebarOpen } = action.payload;
    return state.merge({ isSidebarOpen });
  },
  [types.SET_GRAPH_TIME_PERIOD]: (state, action) => {
    const { graphTimePeriod } = action.payload;
    return state.merge({ graphTimePeriod });
  },
  [types.TOGGLE_CODE_MODAL]: (state, action) => {
    const { activeCodeModal } = action.payload;
    return state.merge({ activeCodeModal });
  },
};

export default function uiReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
