import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './OperationComponent.module.scss';

const renderInflationSet = (operation, matchId) => (
  <span className={STYLES.flexDetails}>
    Inflation destination set to
    <HashPopover
      path="accounts"
      hash={operation.get('inflation_dest')}
      matchId={matchId}
    />
    .
  </span>
);

const renderOtherOptions = (operation, matchId) => (
  <span className={STYLES.flexDetails}>
    Set Options.{' '}
    {operation.get('source_account') && (
      <span className={STYLES.optionsWrap}>
        <span>Source account </span>
        <HashPopover
          path="accounts"
          hash={operation.get('source_account')}
          matchId={matchId}
        />{' '}
      </span>
    )}
    {operation.get('high_threshold') && (
      <span>, high threshold {operation.get('high_threshold')} </span>
    )}
    {operation.get('med_threshold') && (
      <span>, medium threshold {operation.get('med_threshold')} </span>
    )}
    {operation.get('low_threshold') && (
      <span>, low threshold {operation.get('low_threshold')} </span>
    )}
    {operation.get('master_key_weight') && (
      <span>, and key weight {operation.get('master_key_weight')}.</span>
    )}
  </span>
);

const SetOptions = ({ operation, matchId, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>SET OPTIONS</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    <div>
      {operation.get('inflation_dest') &&
        renderInflationSet(operation, matchId)}
      {!operation.get('inflation_dest') &&
        renderOtherOptions(operation, matchId)}
    </div>
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

SetOptions.propTypes = {
  matchId: string,
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

SetOptions.defaultProps = {
  matchId: '',
  isDevModeOn: false,
};

export default SetOptions;
