import React from 'react';
import { instanceOf, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';

import STYLES from './OperationComponent.module.scss';

const canceledOffer = offerId => (
  <div className={STYLES.flexDetails}>Offer {offerId} canceled.</div>
);

const renderBody = (type, operation) => (
  <div className={STYLES.flexDetails}>
    {type}. Selling {operation.get('amount')}{' '}
    {operation.get('selling_asset_type') === 'native'
      ? 'XLM'
      : operation.get('selling_asset_code')}{' '}
    for{' '}
    {operation.get('buying_asset_type') === 'native'
      ? 'XLM'
      : operation.get('buying_asset_code')}{' '}
    at {operation.get('price')}{' '}
    {operation.get('buying_asset_type') === 'native'
      ? 'XLM'
      : operation.get('buying_asset_code')}
    /
    {operation.get('selling_asset_type') === 'native'
      ? 'XLM'
      : operation.get('selling_asset_code')}
  </div>
);

const determineType = operation => {
  if (operation.get('offer_id') === 0)
    return renderBody('Created new offer', operation);
  if (operation.get('amount') < 0)
    return canceledOffer(operation.get('offer_id'));
  return renderBody('Updated offer', operation);
};

const ManageSellOffer = ({ operation, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>MANAGE SELL OFFER</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm:ss a')}
    </p>
    {determineType(operation)}
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

ManageSellOffer.propTypes = {
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

ManageSellOffer.defaultProps = {
  isDevModeOn: false,
};

export default ManageSellOffer;
