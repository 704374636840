import React from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import PaginationButtons from '../PaginationButtons';
import HashPopover from '../HashPopover';

import STYLES from './Offers.module.scss';

const determineOrder = (offers, offersOrder) =>
  offersOrder === 'asc' ? offers.reverse() : offers;

const Offers = ({
  offers,
  offersOrder,
  isFetchingOffers,
  matchId,
  currentPage,
  isDevModeOn,
  isMoreOffers,
  toggleCodeModal,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Offers}>
    {isFetchingOffers ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {offers && offers.size ? (
          <div>
            {determineOrder(offers, offersOrder).map(offer => (
              <div className={STYLES.offerRow} key={offer.get('id')}>
                <div className={STYLES.offerItem}>
                  <div className={STYLES.titleContainer}>
                    <h4>
                      OFFER - {offer.get('id')}
                      {isDevModeOn && (
                        <CodeModalToggle
                          id={offer.get('id')}
                          toggleCodeModal={toggleCodeModal}
                          buttonSize="small"
                        />
                      )}
                    </h4>
                    <div className={STYLES.label}>
                      AMOUNT {offer.get('amount')}
                    </div>
                  </div>
                  <p className={STYLES.date}>
                    Price: {offer.get('price')}
                    {''}
                    {offer.getIn(['buying', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['buying', 'asset_code'])}
                    /
                    {offer.getIn(['selling', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['selling', 'asset_code'])}
                  </p>
                  <div className={STYLES.flexDetails}>
                    Offer from{' '}
                    <HashPopover
                      path="accounts"
                      hash={offer.get('seller')}
                      matchId={matchId}
                    />
                    selling {offer.get('amount')}{' '}
                    {offer.getIn(['selling', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['selling', 'asset_code'])}{' '}
                    to buy{' '}
                    {offer.getIn(['buying', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['buying', 'asset_code'])}{' '}
                    at {offer.get('price')}{' '}
                    {offer.getIn(['buying', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['buying', 'asset_code'])}
                    /
                    {offer.getIn(['selling', 'asset_type']) === 'native'
                      ? 'XLM'
                      : offer.getIn(['selling', 'asset_code'])}{' '}
                    {offer.getIn(['buying', 'asset_issuer']) && (
                      <span className={STYLES.flexSpan}>
                        from{' '}
                        <HashPopover
                          path="accounts"
                          hash={offer.getIn(['buying', 'asset_issuer'])}
                          matchId={matchId}
                        />
                      </span>
                    )}
                    .
                  </div>
                </div>
                {isDevModeOn && <CodeModal name="offer" code={offer} />}
              </div>
            ))}
            <PaginationButtons
              section="Offers"
              currentPage={currentPage}
              isMoreItems={isMoreOffers}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noOffers}>
            <div className={STYLES.largeLabel}>
              There are no Offers for this item yet.
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

Offers.propTypes = {
  matchId: string,
  offers: instanceOf(List),
  offersOrder: string,
  currentPage: number,
  isDevModeOn: bool,
  isMoreOffers: bool,
  isFetchingOffers: bool,
  toggleCodeModal: func.isRequired,
  onGetNextPage: func,
  onGetPreviousPage: func,
};

Offers.defaultProps = {
  matchId: '',
  offersOrder: 'desc',
  currentPage: 1,
  isDevModeOn: false,
  isMoreOffers: false,
  isFetchingOffers: false,
  onGetNextPage: () => {},
  onGetPreviousPage: () => {},
};

export default Offers;
