import axios from 'axios';
import * as types from './types';

export const toggleDevMode = isDevModeOn => {
  localStorage.setItem('isDevModeOn', JSON.stringify(!isDevModeOn));

  return {
    type: types.TOGGLE_DEV_MODE,
    payload: {
      isDevModeOn: !isDevModeOn,
    },
  };
};

export const toggleHorizonNet = isTestNetEnabled => {
  localStorage.setItem('isTestNetEnabled', JSON.stringify(!isTestNetEnabled));

  return {
    type: types.TOGGLE_HORIZON_NET,
    payload: {
      isTestNetEnabled: !isTestNetEnabled,
    },
  };
};

const getRepoStatsSuccess = ({ repoStats }) => ({
  type: types.GET_REPO_STATS_SUCCESS,
  payload: {
    repoStats,
    isFetchingRepoStats: false,
  },
});

const getRepoStatsError = error => ({
  type: types.GET_REPO_STATS_ERROR,
  payload: {
    error,
    isFetchingRepoStats: false,
  },
});

const requestRepoStats = () => ({
  type: types.REQUEST_REPO_STATS,
  payload: {
    isFetchingRepoStats: true,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const getRepoStats = repo => async dispatch => {
  dispatch(requestRepoStats());

  try {
    const res = await axios.get(
      `http://localhost:4000/developers/github/stats/${repo}`
    );
    dispatch(
      getRepoStatsSuccess({
        repoStats: res.data,
      })
    );
  } catch (err) {
    dispatch(getRepoStatsError(err));
  }
};

const getReposListSuccess = ({
  reposList,
  reposLinks,
  activeReposPage,
  reposPerPage,
  reposSortCategory,
  reposSortDirection,
}) => ({
  type: types.GET_REPOS_LIST_SUCCESS,
  payload: {
    reposList,
    reposLinks,
    activeReposPage,
    reposPerPage,
    isFetchingReposList: false,
    reposSortCategory,
    reposSortDirection,
  },
});

const getReposListError = error => ({
  type: types.GET_REPOS_LIST_ERROR,
  payload: {
    error,
    isFetchingReposList: false,
  },
});

const requestReposList = () => ({
  type: types.REQUEST_REPOS_LIST,
  payload: {
    isFetchingReposList: true,
  },
});

const formatLinks = links => {
  const linksArr = links.split(',');
  let linkObj = {};

  linksArr.forEach(link => {
    const parts = link.split(';');
    const pageRe = /page=([^&]*)/;
    const perPageRe = /per_page=([^&]*)/;
    const relRe = /rel=([^&]*)/;

    const page = parts[0].match(pageRe)[1];
    const perPage = parts[0].match(perPageRe)[1].slice(0, -1);
    const action = parts[1].match(relRe)[1].slice(1, -1);

    linkObj[action] = {
      page,
      perPage,
    };
  });

  return linkObj;
};

// Github Repos for user
export const getReposList = (
  activeReposPage,
  reposPerPage,
  reposSortCategory,
  reposSortDirection
) => async dispatch => {
  dispatch(requestReposList());

  try {
    const URL = `https://api.github.com/users/stellar/repos?sort=${reposSortCategory}&direction=${reposSortDirection}&page=${activeReposPage}&per_page=${reposPerPage}`;
    const res = await axios.get(URL, {
      auth: {
        username: process.env.REACT_APP_GH_USERNAME,
        password: process.env.REACT_APP_GH_PASSWORD,
      },
      headers: {
        Accept: 'application/vnd.github.v3+json',
      },
    });
    const links = formatLinks(res.headers.link);

    dispatch(
      getReposListSuccess({
        reposList: res.data,
        reposLinks: links,
        activeReposPage,
        reposPerPage,
        reposSortCategory,
        reposSortDirection,
      })
    );
  } catch (err) {
    dispatch(getReposListError(err));
  }
};

export const updateReposSortCategory = reposSortCategory => (
  dispatch,
  getState
) => {
  const {
    activeReposPage,
    reposPerPage,
    reposSortDirection,
  } = getState().developer;
  dispatch({
    type: types.UPDATE_REPOS_SORT_CATEGORY,
    payload: {
      reposSortCategory,
    },
  });
  dispatch(
    getReposList(
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection
    )
  );
};

export const updateReposSortDirection = reposSortDirection => (
  dispatch,
  getState
) => {
  const {
    activeReposPage,
    reposPerPage,
    reposSortCategory,
  } = getState().developer;
  dispatch({
    type: types.UPDATE_REPOS_SORT_DIRECTION,
    payload: {
      reposSortDirection,
    },
  });
  dispatch(
    getReposList(
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection
    )
  );
};
