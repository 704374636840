import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './Payments.module.scss';

const CreateAccount = ({ payment, isDevModeOn, toggleCodeModal, matchId }) => (
  <div className={STYLES.paymentItem}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/transactions/${payment.get('transaction_hash')}`}>
          Payment - {payment.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={payment.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>CREATE ACCOUNT</div>
    </div>
    <p className={STYLES.date}>
      Created: {moment(payment.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    <div className={STYLES.flexDetails}>
      Created Account{' '}
      <HashPopover
        path="accounts"
        hash={payment.get('account')}
        matchId={matchId}
      />
      with a starting balance of {payment.get('starting_balance')} XLM funded by{' '}
      <HashPopover
        path="accounts"
        hash={payment.get('funder')}
        matchId={matchId}
      />
      .
    </div>
    {isDevModeOn && <CodeModal name="payment" code={payment} />}
  </div>
);

CreateAccount.propTypes = {
  payment: instanceOf(Map),
  isDevModeOn: bool,
  matchId: string,
  toggleCodeModal: func.isRequired,
};

CreateAccount.defaultProps = {
  isDevModeOn: false,
  matchId: '',
};

export default CreateAccount;
