import React, { Component } from 'react';
import { instanceOf, object, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { getFeaturedProjects } from '../../actions/community';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import TabbedList from './components/TabbedList';

import STYLES from './CommunityPage.module.scss';
import { withHook } from '../../util/withHook';

class CommunityPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    featuredProjects: instanceOf(Map),
    isFetchingFeaturedProjects: bool,
    onGetFeaturedProjects: func.isRequired,
  };

  static defaultProps = {
    isFetchingFeaturedProjects: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/community',
        title: 'Community',
      });
    }

    this.props.onGetFeaturedProjects();
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Community';
    const description =
      'The Community page on StellarUp provides a look into the projects, news, and social media trends encompassing the Stellar ecosystem.';
    const communityUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={communityUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const { featuredProjects, isFetchingFeaturedProjects } = this.props;

    return (
      <div className={STYLES.CommunityPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2 className={STYLES.pageTitle}>COMMUNITY</h2>
              </div>
              <div>
                <div className={STYLES.dividerWrapper}>
                  <div className={STYLES.divider} />
                </div>
                <div className={STYLES.tabWrap}>
                  <TabbedList
                    featuredProjects={featuredProjects}
                    isFetchingFeaturedProjects={isFetchingFeaturedProjects}
                  />
                </div>
                <div className={STYLES.adWrapBottom}>
                  <GoogleAd placement="Bottom" adSlot="7799510885" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  featuredProjects: SELECTORS.getFeaturedProjects(state),
  isFetchingFeaturedProjects: SELECTORS.getIsFetchingFeaturedProjects(state),
});

const mapDispatchToProps = {
  onGetFeaturedProjects: getFeaturedProjects,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(CommunityPage))
);
