import React, { Component } from 'react';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

import { getAllOperations } from '../../actions/operation';
import { getAllTransactions } from '../../actions/transaction';
import { getCoinHistory, getCoinStats } from '../../actions/coin';
import { setGraphTimePeriod, toggleCodeModal } from '../../actions/ui';

import * as SELECTORS from '../../selectors';

import VisualData from './components/visualData/VisualData';
import Exchanges from './components/exchanges/Exchanges';
import HorizonData from './components/horizonData/HorizonData';
import Stats from './components/stats/Stats';
import GraphTimeframeButton from './components/GraphTimeframeButton';

import STYLES from './HomePage.module.scss';
import ReactGA from 'react-ga4';

// <FormattedMessage id="homepage.title" />

class HomePage extends Component {
  static propTypes = {
    operations: instanceOf(List),
    transactions: instanceOf(List),
    coinStats: instanceOf(Map),
    weekHistory: instanceOf(Map),
    yearHistory: instanceOf(Map),
    monthHistory: instanceOf(Map),
    graphTimePeriod: string,
    isDevModeOn: bool,
    isFetchingCoinStats: bool,
    isFetchingCoinHistory: bool,
    location: object.isRequired,
    getCoinStats: func.isRequired,
    getCoinHistory: func.isRequired,
    getAllOperations: func.isRequired,
    getAllTransactions: func.isRequired,
    setGraphTimePeriod: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    operations: new List(),
    transactions: new List(),
    coinStats: new Map(),
    weekHistory: new Map(),
    yearHistory: new Map(),
    monthHistory: new Map(),
    graphTimePeriod: 'YEAR',
    isFetchingCoinStats: false,
    isFetchingCoinHistory: false,
    isDevModeOn: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'Dashboard',
      });
    }

    this.props.getAllOperations();
    this.props.getCoinHistory();
    this.props.getAllTransactions();
    this.props.getCoinStats();
  };

  updateGraphPeriod = timeframe => {
    this.props.setGraphTimePeriod(timeframe);
  };

  renderHelmet = () => {
    const title = 'StellarUp';
    const description =
      'StellarUp is mobile friendly block explorer for existing and aspiring users of the Stellar Network. Providing useful data visualization tools, an ever growing database of Stellar applications, and other useful features to help users stay current and effective.';
    const baseUrl = 'http://www.stellarup.com';

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta name="og:title" property="og:title" content={title} />
        <meta name="og:type" property="og:type" content="website" />
        <meta name="og:url" property="og:url" content={baseUrl} />
        <meta
          name="og:description"
          property="og:description"
          content={description}
        />
        <meta name="og:determiner" property="og:determiner" content="" />
        <meta name="og:locale" property="og:locale" content="en_US" />
        <meta name="og:ttl" property="og:ttl" content="345600" />
        <link rel="canonical" href="http://www.stellarup.com" />
      </Helmet>
    );
  };

  render() {
    const {
      operations,
      transactions,
      weekHistory,
      monthHistory,
      yearHistory,
      graphTimePeriod,
      coinStats,
      isFetchingCoinStats,
      isFetchingCoinHistory,
      isDevModeOn,
      onToggleCodeModal,
    } = this.props;

    return (
      <div className={STYLES.HomePage}>
        {this.renderHelmet()}
        <Stats
          isFetchingCoinStats={isFetchingCoinStats}
          coinStats={coinStats}
        />
        <div className={STYLES.homeWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2 className={STYLES.headerTitle}>OVERVIEW</h2>
                <div className={STYLES.graphFilters}>
                  <GraphTimeframeButton
                    title="week"
                    timeframe="WEEK"
                    graphTimePeriod={graphTimePeriod}
                    updateGraphPeriod={this.updateGraphPeriod}
                  />
                  <GraphTimeframeButton
                    title="month"
                    timeframe="MONTH"
                    graphTimePeriod={graphTimePeriod}
                    updateGraphPeriod={this.updateGraphPeriod}
                  />
                  <GraphTimeframeButton
                    title="year"
                    timeframe="YEAR"
                    graphTimePeriod={graphTimePeriod}
                    updateGraphPeriod={this.updateGraphPeriod}
                  />
                </div>
              </div>
              <VisualData
                weekHistory={weekHistory}
                monthHistory={monthHistory}
                yearHistory={yearHistory}
                graphTimePeriod={graphTimePeriod}
                isFetchingCoinHistory={isFetchingCoinHistory}
              />
              <HorizonData
                isDevModeOn={isDevModeOn}
                toggleCodeModal={onToggleCodeModal}
                transactions={transactions}
                operations={operations}
              />
              <Exchanges />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  assets: SELECTORS.getAllAssets(state),
  operations: SELECTORS.getAllOperations(state),
  transactions: SELECTORS.getAllTransactions(state),
  weekHistory: SELECTORS.getWeekHistory(state),
  monthHistory: SELECTORS.getMonthHistory(state),
  yearHistory: SELECTORS.getYearHistory(state),
  coinStats: SELECTORS.getCoinStats(state),
  graphTimePeriod: SELECTORS.getGraphTimePeriod(state),
  isFetchingCoinStats: SELECTORS.getIsFetchingCoinStats(state),
  isFetchingCoinHistory: SELECTORS.getIsFetchingCoinHistory(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getAllOperations,
  getAllTransactions,
  getCoinHistory,
  setGraphTimePeriod,
  getCoinStats,
  onToggleCodeModal: toggleCodeModal,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
