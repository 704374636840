import React from 'react';
import { instanceOf, bool } from 'prop-types';
import { Map } from 'immutable';

import PriceStat from './PriceStat';
import MarketCapStat from './MarketCapStat';
import VolumeStat from './VolumeStat';

import STYLES from './Stats.module.scss';

const Stats = ({ coinStats, isFetchingCoinStats }) => (
  <div className={STYLES.Stats}>
    {isFetchingCoinStats ? (
      <div />
    ) : (
      <div className={STYLES.statWrap}>
        <div className={STYLES.panelWrapper}>
          <MarketCapStat marketCap={coinStats && coinStats.get('market_cap')} />
        </div>
        <div className={STYLES.panelWrapper}>
          <PriceStat
            priceUsd={coinStats && coinStats.get('price')}
            percentChange24={coinStats && coinStats.get('percent_change_24h')}
          />
        </div>
        <div className={STYLES.panelWrapper}>
          <VolumeStat volume24={coinStats && coinStats.get('volume_24h')} />
        </div>
      </div>
    )}
  </div>
);

Stats.propTypes = {
  coinStats: instanceOf(Map),
  isFetchingCoinStats: bool,
};

Stats.defaultProps = {
  coinStats: new Map(),
  isFetchingCoinStats: false,
};

export default Stats;
