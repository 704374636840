import React, { Component } from 'react';
import { string, number, bool, func } from 'prop-types';

import { Button } from 'react-bootstrap';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import STYLES from './PaginationButtons.module.scss';

class PaginationButtons extends Component {
  static propTypes = {
    section: string.isRequired,
    currentPage: number,
    isMoreItems: bool,
    onGetNextPage: func,
    onGetPreviousPage: func,
  };

  static defaultProps = {
    currentPage: 1,
    isMoreItems: false,
    onGetNextPage: () => {},
    onGetPreviousPage: () => {},
  };

  handlePaginateBack = () => {
    const { onGetPreviousPage, section } = this.props;

    onGetPreviousPage(section);
  };

  handlePaginateForward = () => {
    const { onGetNextPage, section } = this.props;

    onGetNextPage(section);
  };

  render() {
    const { currentPage, isMoreItems } = this.props;

    return (
      <div className={STYLES.PaginationButtons}>
        <div className={STYLES.paginationWrapperDesktop}>
          {currentPage > 1 && (
            <Button
              className={STYLES.paginationButton}
              onClick={this.handlePaginateBack}>
              Previous Page
            </Button>
          )}
          {isMoreItems && (
            <Button
              className={STYLES.paginationButton}
              onClick={this.handlePaginateForward}>
              Next Page
            </Button>
          )}
        </div>
        <div className={STYLES.paginationWrapperMobile}>
          {currentPage > 1 && (
            <Button
              className={STYLES.mobileButtonLeft}
              onClick={this.handlePaginateBack}>
              <MdKeyboardArrowLeft className={STYLES.arrowIcon} />
            </Button>
          )}
          {isMoreItems && (
            <Button
              className={STYLES.mobileButtonRight}
              onClick={this.handlePaginateForward}>
              <MdKeyboardArrowRight className={STYLES.arrowIcon} />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default PaginationButtons;
