import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ACCOUNT_PAYMENTS,
  GET_ACCOUNT_PAYMENTS_ERROR,
  GET_ACCOUNT_PAYMENTS_SUCCESS,
} from './types';

const getAccountPaymentsSuccess = ({
  accountPayments,
  accountPaymentsOrder,
  isMoreAccountPayments,
}) => ({
  type: GET_ACCOUNT_PAYMENTS_SUCCESS,
  payload: {
    accountPayments,
    accountPaymentsOrder,
    isMoreAccountPayments,
    isFetchingAccountPayments: false,
  },
});

const getAccountPaymentsError = error => ({
  type: GET_ACCOUNT_PAYMENTS_ERROR,
  payload: { error, isFetchingAccountPayments: false },
});

const requestAccountPayments = () => ({
  type: REQUEST_ACCOUNT_PAYMENTS,
  payload: { isFetchingAccountPayments: true },
});

export default (accountId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestAccountPayments());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const paymentOrder = order ? order : 'desc';

    const res = await server
      .payments()
      .cursor(cursor)
      .order(paymentOrder)
      .limit(10)
      .forAccount(accountId)
      .call();
    const nextPage =
      paymentOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getAccountPaymentsSuccess({
        accountPayments: res.records,
        accountPaymentsOrder: paymentOrder,
        isMoreAccountPayments: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getAccountPaymentsError(err));
  }
};
