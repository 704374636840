import React, { Component } from 'react';
import { string, func } from 'prop-types';
import cx from 'classnames';

import STYLES from './GraphTimeframeButton.module.scss';

const determineActiveButton = (graphTimePeriod, buttonKey) =>
  cx({
    [STYLES.filter]: true,
    [STYLES.activeFilter]: graphTimePeriod === buttonKey,
  });

class GraphTimeframeButton extends Component {
  static propTypes = {
    timeframe: string.isRequired,
    title: string.isRequired,
    graphTimePeriod: string.isRequired,
    updateGraphPeriod: func.isRequired,
  };

  handleGraphUpdate = () => {
    const { updateGraphPeriod, timeframe } = this.props;

    updateGraphPeriod(timeframe);
  };

  render() {
    const { graphTimePeriod, timeframe, title } = this.props;

    return (
      <div className={STYLES.GraphTimeframeButton}>
        <div
          onClick={this.handleGraphUpdate}
          className={determineActiveButton(graphTimePeriod, timeframe)}>
          <span className={STYLES.largeFilter}>This {title}</span>
          <span className={STYLES.smallFilter}>{title}</span>
        </div>
      </div>
    );
  }
}

export default GraphTimeframeButton;
