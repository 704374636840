import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { MdCheckCircle, MdError } from 'react-icons/md';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsComponent.module.scss';

const CreateAccountDetails = ({ operationDetails }) => (
  <div className={STYLES.DetailsComponent}>
    <h3>
      Operation Type{' '}
      <InfoToolTip
        title="Create Account"
        info="Creates and funds a new account with the specified starting balance."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.mainDetails}>Create Account</div>
    <h3>
      Transaction Hash{' '}
      <InfoToolTip
        title="Transaction Hash"
        info="A hex-encoded SHA-256 hash of the transaction’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {operationDetails && operationDetails.get('transaction_hash')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Status</span>
        <InfoToolTip
          title="Status"
          info="Indicates if transaction was successful or not."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {operationDetails.get('transaction_successful') ? (
            <div className={STYLES.successLabel}>
              <MdCheckCircle className={STYLES.statusIcon} /> Successful
            </div>
          ) : (
            <div className={STYLES.failedLabel}>
              <MdError className={STYLES.statusIcon} /> Failed
            </div>
          )}
        </span>
      </div>
      {operationDetails && operationDetails.get('created_at') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Created at</span>
          <InfoToolTip
            title="Created at"
            info="The timestamp of when the operation was created."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {moment(operationDetails.get('created_at')).format('LLL')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('account') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Account</span>
          <InfoToolTip
            title="Account"
            info="Account address that is created and funded."
            placement="top"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('account')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('funder') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Funder</span>
          <InfoToolTip
            title="Funder"
            info="Account that funded a new account."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('funder')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('starting_balance') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Starting balance</span>
          <InfoToolTip
            title="Starting balance"
            info="Amount of XLM to send to the newly created account. This XLM comes from the source account."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('starting_balance')} XLM
          </span>
        </div>
      )}
    </div>
  </div>
);

CreateAccountDetails.propTypes = {
  operationDetails: instanceOf(Map),
};

CreateAccountDetails.defaultProps = {
  operationDetails: new Map(),
};

export default CreateAccountDetails;
