import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_LEDGER_DETAILS,
  GET_LEDGER_DETAILS_ERROR,
  GET_LEDGER_DETAILS_SUCCESS,
} from './types';

const getLedgerDetailsSuccess = ledgerDetails => ({
  type: GET_LEDGER_DETAILS_SUCCESS,
  payload: { ledgerDetails, isFetchingLedgerDetails: false },
});

const getLedgerDetailsError = error => ({
  type: GET_LEDGER_DETAILS_ERROR,
  payload: { error, isFetchingLedgerDetails: false },
});

const requestLedgerDetails = () => ({
  type: REQUEST_LEDGER_DETAILS,
  payload: { isFetchingLedgerDetails: true },
});

export default ledgerId => async (dispatch, getState) => {
  dispatch(requestLedgerDetails());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;

    const res = await server
      .ledgers()
      .ledger(ledgerId)
      .call();

    dispatch(getLedgerDetailsSuccess(res));
  } catch (err) {
    dispatch(getLedgerDetailsError(err));
  }
};
