import React, { Component } from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import TabTitle from './TabTitle';
import Operations from '../../../components/operations/Operations';
import Transactions from '../../../components/transactions/Transactions';
import Payments from '../../../components/payments/Payments';
import Offers from '../../../components/offers/Offers';
import Effects from '../../../components/effects/Effects';
import Balances from '../../../components/balances/Balances';
import Signers from '../../../components/signers/Signers';

import STYLES from './TabbedList.module.scss';

class TabbedList extends Component {
  static propTypes = {
    accountId: string,
    accountEffects: instanceOf(List),
    accountOperations: instanceOf(List),
    accountTransactions: instanceOf(List),
    accountPayments: instanceOf(List),
    accountOffers: instanceOf(List),
    accountBalances: instanceOf(List),
    accountSigners: instanceOf(List),
    accountTransactionsPage: number,
    accountOperationsPage: number,
    accountEffectsPage: number,
    accountPaymentsPage: number,
    accountOffersPage: number,
    accountTransactionsOrder: string,
    accountOperationsOrder: string,
    accountEffectsOrder: string,
    accountPaymentsOrder: string,
    accountOffersOrder: string,
    isDevModeOn: bool,
    isMoreAccountTransactions: bool,
    isMoreAccountOperations: bool,
    isMoreAccountEffects: bool,
    isMoreAccountPayments: bool,
    isMoreAccountOffers: bool,
    isFetchingAccountEffects: bool,
    isFetchingAccountOperations: bool,
    isFetchingAccountTransactions: bool,
    isFetchingAccountPayments: bool,
    isFetchingAccountOffers: bool,
    toggleCodeModal: func.isRequired,
    onGetNextPage: func.isRequired,
    onGetPreviousPage: func.isRequired,
  };

  static defaultProps = {
    accountId: '',
    accountTransactionsPage: 1,
    accountOperationsPage: 1,
    accountEffectsPage: 1,
    accountPaymentsPage: 1,
    accountOffersPage: 1,
    isMoreAccountTransactions: false,
    isMoreAccountOperations: false,
    isMoreAccountEffects: false,
    isMoreAccountPayments: false,
    isMoreAccountOffers: false,
    accountTransactionsOrder: 'desc',
    accountOperationsOrder: 'desc',
    accountEffectsOrder: 'desc',
    accountPaymentsOrder: 'desc',
    accountOffersOrder: 'desc',
    isDevModeOn: false,
    isFetchingAccountEffects: false,
    isFetchingAccountOperations: false,
    isFetchingAccountTransactions: false,
    isFetchingAccountPayments: false,
    isFetchingAccountOffers: false,
  };

  state = { activeTabKey: 1 };

  handleTabSelect = activeTabKey => {
    this.setState({ activeTabKey });
  };

  render() {
    const {
      accountId,
      accountEffects,
      accountEffectsPage,
      accountOperations,
      accountOperationsPage,
      accountTransactions,
      accountTransactionsPage,
      accountPayments,
      accountPaymentsPage,
      accountOffers,
      accountOffersPage,
      accountBalances,
      accountSigners,
      accountTransactionsOrder,
      accountOperationsOrder,
      accountEffectsOrder,
      accountPaymentsOrder,
      accountOffersOrder,
      isDevModeOn,
      isMoreAccountTransactions,
      isMoreAccountOperations,
      isMoreAccountEffects,
      isMoreAccountPayments,
      isMoreAccountOffers,
      isFetchingAccountEffects,
      isFetchingAccountOperations,
      isFetchingAccountTransactions,
      isFetchingAccountPayments,
      isFetchingAccountOffers,
      toggleCodeModal,
      onGetNextPage,
      onGetPreviousPage,
    } = this.props;
    const { activeTabKey } = this.state;

    return (
      <Tabs
        id="account-tabs"
        activeKey={activeTabKey}
        onSelect={this.handleTabSelect}
        className={STYLES.TabbedList}>
        <Tab
          eventKey={1}
          title={
            <TabTitle
              title="Operations"
              tabKey={1}
              activeTabKey={activeTabKey}
            />
          }>
          <Operations
            matchId={accountId}
            operations={accountOperations}
            operationsOrder={accountOperationsOrder}
            currentPage={accountOperationsPage}
            isDevModeOn={isDevModeOn}
            isMoreOperations={isMoreAccountOperations}
            isFetchingOperations={isFetchingAccountOperations}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={2}
          title={
            <TabTitle
              title="Transactions"
              tabKey={2}
              activeTabKey={activeTabKey}
            />
          }>
          <Transactions
            transactions={accountTransactions}
            transactionsOrder={accountTransactionsOrder}
            currentPage={accountTransactionsPage}
            isDevModeOn={isDevModeOn}
            isMoreTransactions={isMoreAccountTransactions}
            isFetchingTransactions={isFetchingAccountTransactions}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={3}
          title={
            <TabTitle title="Effects" tabKey={3} activeTabKey={activeTabKey} />
          }>
          <Effects
            matchId={accountId}
            effects={accountEffects}
            effectsOrder={accountEffectsOrder}
            currentPage={accountEffectsPage}
            isDevModeOn={isDevModeOn}
            isMoreEffects={isMoreAccountEffects}
            isFetchingEffects={isFetchingAccountEffects}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={4}
          title={
            <TabTitle title="Payments" tabKey={4} activeTabKey={activeTabKey} />
          }>
          <Payments
            matchId={accountId}
            payments={accountPayments}
            paymentsOrder={accountPaymentsOrder}
            currentPage={accountPaymentsPage}
            isDevModeOn={isDevModeOn}
            isMorePayments={isMoreAccountPayments}
            isFetchingPayments={isFetchingAccountPayments}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={5}
          title={
            <TabTitle title="Offers" tabKey={5} activeTabKey={activeTabKey} />
          }>
          <Offers
            matchId={accountId}
            offers={accountOffers}
            offersOrder={accountOffersOrder}
            currentPage={accountOffersPage}
            isDevModeOn={isDevModeOn}
            isMoreOffers={isMoreAccountOffers}
            isFetchingOffers={isFetchingAccountOffers}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
        <Tab
          eventKey={6}
          title={
            <TabTitle title="Balances" tabKey={6} activeTabKey={activeTabKey} />
          }>
          <Balances balances={accountBalances} />
        </Tab>
        <Tab
          eventKey={7}
          title={
            <TabTitle title="Signers" tabKey={7} activeTabKey={activeTabKey} />
          }>
          <Signers matchId={accountId} signers={accountSigners} />
        </Tab>
      </Tabs>
    );
  }
}

export default TabbedList;
