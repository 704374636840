import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ACCOUNT_OPERATIONS,
  GET_ACCOUNT_OPERATIONS_ERROR,
  GET_ACCOUNT_OPERATIONS_SUCCESS,
} from './types';

const getAccountOperationsSuccess = ({
  accountOperations,
  accountOperationsOrder,
  isMoreAccountOperations,
}) => ({
  type: GET_ACCOUNT_OPERATIONS_SUCCESS,
  payload: {
    accountOperations,
    accountOperationsOrder,
    isMoreAccountOperations,
    isFetchingAccountOperations: false,
  },
});

const getAccountOperationsError = error => ({
  type: GET_ACCOUNT_OPERATIONS_ERROR,
  payload: { error, isFetchingAccountOperations: false },
});

const requestAccountOperations = () => ({
  type: REQUEST_ACCOUNT_OPERATIONS,
  payload: { isFetchingAccountOperations: true },
});

export default (accountId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestAccountOperations());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const operationOrder = order ? order : 'desc';

    const res = await server
      .operations()
      .cursor(cursor)
      .order(operationOrder)
      .limit(10)
      .forAccount(accountId)
      .call();
    const nextPage =
      operationOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getAccountOperationsSuccess({
        accountOperations: res.records,
        accountOperationsOrder: operationOrder,
        isMoreAccountOperations: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getAccountOperationsError(err));
  }
};
