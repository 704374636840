import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { MdCheckCircle, MdError } from 'react-icons/md';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsSummary.module.scss';

const DetailsSummary = ({ transactionDetails }) => (
  <div className={STYLES.DetailsSummary}>
    <h3>
      Transaction Hash{' '}
      <InfoToolTip
        title="Transaction Hash"
        info="A hex-encoded SHA-256 hash of the transaction’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/transactions.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {transactionDetails && transactionDetails.get('hash')}
    </div>
    <h3>
      Source Account
      <InfoToolTip
        title="Source Account"
        info="The source account is the account that originates the transaction."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/transactions.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {transactionDetails && transactionDetails.get('source_account')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Status</span>
        <InfoToolTip
          title="Status"
          info="Indicates if transaction was successful or not."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails.get('successful') ? (
            <div className={STYLES.successLabel}>
              <MdCheckCircle className={STYLES.statusIcon} /> Successful
            </div>
          ) : (
            <div className={STYLES.failedLabel}>
              <MdError className={STYLES.statusIcon} /> Failed
            </div>
          )}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Created at</span>
        <InfoToolTip
          title="Created at"
          info="The timestamp of when the transaction was created."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails &&
            moment(transactionDetails.get('created_at')).format('LLL')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Sequence Number</span>
        <InfoToolTip
          title="Sequence Number"
          info="Each transaction has a sequence number associated with the source account. Transactions follow a strict ordering rule when it comes to processing transactions per account in order to prevent double-spending."
          placement="top"
          size="15px"
          width="300px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails &&
            transactionDetails.get('source_account_sequence')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Ledger</span>
        <InfoToolTip
          title="Ledger"
          info="Sequence number of the ledger in which this transaction was applied."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails && transactionDetails.get('ledger_attr')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Max fee</span>
        <InfoToolTip
          title="Max fee"
          info="The the maximum fee the source account was willing to pay."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails && transactionDetails.get('fee_paid')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Fee charged</span>
        <InfoToolTip
          title="Fee charged"
          info="The fee paid by the source account of this transaction when the transaction was applied to the ledger."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails && transactionDetails.get('fee_paid')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Operation count</span>
        <InfoToolTip
          title="Operation Count"
          info="The number of operations that are contained within this transaction."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {transactionDetails && transactionDetails.get('operation_count')}
        </span>
      </div>
    </div>
  </div>
);

DetailsSummary.propTypes = {
  transactionDetails: instanceOf(Map),
};

DetailsSummary.defaultProps = {
  transactionDetails: new Map(),
};

export default DetailsSummary;
