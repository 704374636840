import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';
import HashPopover from '../HashPopover';

import STYLES from './Payments.module.scss';

const Payment = ({ payment, isDevModeOn, toggleCodeModal, matchId }) => (
  <div className={STYLES.paymentItem}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/transactions/${payment.get('transaction_hash')}`}>
          Payment - {payment.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={payment.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>PAYMENT</div>
    </div>
    <p className={STYLES.date}>
      Created: {moment(payment.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    <div className={STYLES.flexDetails}>
      Payment from{' '}
      <HashPopover
        path="accounts"
        hash={payment.get('from')}
        matchId={matchId}
      />
      to{' '}
      <HashPopover path="accounts" hash={payment.get('to')} matchId={matchId} />
      of {payment.get('amount')}{' '}
      {payment.get('asset_type') === 'native'
        ? 'XLM'
        : payment.get('asset_code')}
      .
    </div>
    {isDevModeOn && <CodeModal name="payment" code={payment} />}
  </div>
);

Payment.propTypes = {
  payment: instanceOf(Map),
  isDevModeOn: bool,
  matchId: string,
  toggleCodeModal: func.isRequired,
};

Payment.defaultProps = {
  isDevModeOn: false,
  matchId: '',
};

export default Payment;
