import { Map } from 'immutable';
import * as types from '../actions/developer/types';

const ACTIONS = {
  [types.UPDATE_REPOS_SORT_CATEGORY]: (state, action) => {
    const { reposSortCategory } = action.payload;
    return state.merge({ reposSortCategory });
  },
  [types.UPDATE_REPOS_SORT_DIRECTION]: (state, action) => {
    const { reposSortDirection } = action.payload;
    return state.merge({ reposSortDirection });
  },
  [types.TOGGLE_DEV_MODE]: (state, action) => {
    const { isDevModeOn } = action.payload;
    return state.merge({ isDevModeOn });
  },
  [types.TOGGLE_HORIZON_NET]: (state, action) => {
    const { isTestNetEnabled } = action.payload;
    return state.merge({ isTestNetEnabled });
  },
  // Repo Stats
  [types.REQUEST_REPO_STATS]: (state, action) => {
    const { isFetchingRepoStats } = action.payload;
    return state.merge({ isFetchingRepoStats });
  },
  [types.GET_REPO_STATS_ERROR]: (state, action) => {
    const { error, isFetchingRepoStats } = action.payload;
    return state.merge({ error, isFetchingRepoStats });
  },
  [types.GET_REPO_STATS_SUCCESS]: (state, action) => {
    const { repoStats, isFetchingRepoStats } = action.payload;
    return state.merge({ repoStats, isFetchingRepoStats });
  },
  // Repos List
  [types.REQUEST_REPOS_LIST]: (state, action) => {
    const { isFetchingReposList } = action.payload;
    return state.merge({ isFetchingReposList });
  },
  [types.GET_REPOS_LIST_ERROR]: (state, action) => {
    const { error, isFetchingReposList } = action.payload;
    return state.merge({ error, isFetchingReposList });
  },
  [types.GET_REPOS_LIST_SUCCESS]: (state, action) => {
    const {
      reposList,
      reposLinks,
      isFetchingReposList,
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection,
    } = action.payload;
    return state.merge({
      reposList,
      reposLinks,
      isFetchingReposList,
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection,
    });
  },
};

const isDevModeOn = localStorage.getItem('isDevModeOn');
const isTestNetEnabled = localStorage.getItem('isTestNetEnabled');

const initialState = {
  ...(isDevModeOn && { isDevModeOn: JSON.parse(isDevModeOn) }),
  ...(isTestNetEnabled && { isTestNetEnabled: JSON.parse(isTestNetEnabled) }),
};

export default function developerReducer(
  state = new Map(initialState),
  action
) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
