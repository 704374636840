import React, { Component } from 'react';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import STYLES from './DisclaimerPage.module.scss';
import { withHook } from '../../util/withHook';

class Disclaimer extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/disclaimer',
        title: 'Disclaimer',
      });
    }
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;

    const title = 'StellarUp - Disclaimer';
    const description =
      'The Disclaimer page includes the personal affiliate disclosure for StellarUp.';
    const disclaimerUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={disclaimerUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    return (
      <div className={STYLES.Disclaimer}>
        {this.renderHelmet()}
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>StellarUp Disclaimer</h2>
              </div>
              <div className={STYLES.disclaimerWrap}>
                <div className={STYLES.disclaimerContent}>
                  <h4>Personal Affiliate Disclosure</h4>
                  <p>
                    We would like to be fully transparent with the users of this
                    website and disclose how we are able to generate revenue to
                    keep the development of new and exciting features in
                    operation. What keeps our wheels turning is the use of
                    affiliate links. When a user follows a link to an exchange,{' '}
                    for example, it gives us the opportunity to create an
                    additional revenue stream if the user decides to use the
                    exchange, which is at no additional cost to the user.
                  </p>
                  <p>
                    Some affiliate links offer benefits for both us and the user
                    and Coinbase is a great example of this. With every $100
                    purchase or sell the user makes, through our referral links,
                    they will also be credited with $10 worth of free bitcoin.
                  </p>
                  <p>
                    These affliate links can help offset the cost of development
                    so that we can continue to provide new and exciting
                    features. Improve the functionality and usability of old
                    features. And overall create a better experience for the{' '}
                    user.
                  </p>
                  <p>
                    One last thing to take note of is that by no means are we
                    financial or cryptocurrency expects. The tutorials and
                    resources provided are intended to get you started if you
                    choose to do so. Please do not take this as financial advice
                    and always knows the risks involved before deciding to enter
                    the world of cryptocurrency. The decisions you make on
                    whether or not to invest in cryptocurrency are your own
                    responsibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHook(injectIntl(Disclaimer));
