import React from 'react';
import { instanceOf, bool, func } from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';

import CodeModal from '../CodeModal';
import CodeModalToggle from '../CodeModalToggle';

import STYLES from './OperationComponent.module.scss';

const Inflation = ({ operation, isDevModeOn, toggleCodeModal }) => (
  <div className={STYLES.OperationComponent}>
    <div className={STYLES.titleContainer}>
      <h4>
        <Link to={`/operations/${operation.get('id')}`}>
          OPERATION - {operation.get('id')}
        </Link>
        {isDevModeOn && (
          <CodeModalToggle
            id={operation.get('id')}
            toggleCodeModal={toggleCodeModal}
            buttonSize="small"
          />
        )}
      </h4>
      <div className={STYLES.label}>INFLATION</div>
    </div>
    <p className={STYLES.date}>
      Created:{' '}
      {moment(operation.get('created_at')).format('MMM DD YYYY, h:mm a')}
    </p>
    <div className={STYLES.flexDetails}>Inflation run.</div>
    {isDevModeOn && <CodeModal name="operation" code={operation} />}
  </div>
);

Inflation.propTypes = {
  operation: instanceOf(Map).isRequired,
  isDevModeOn: bool,
  toggleCodeModal: func.isRequired,
};

Inflation.defaultProps = {
  isDevModeOn: false,
};

export default Inflation;
