import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { instanceOf, object, string, number, bool, func } from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import ReactGA from 'react-ga4';

import { GoCode } from 'react-icons/go';
import { FaConnectdevelop } from 'react-icons/fa';

import {
  getReposList,
  toggleDevMode,
  toggleHorizonNet,
  updateReposSortCategory,
  updateReposSortDirection,
} from '../../actions/developer';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import ReposList from './components/ReposList';

import STYLES from './DevelopersPage.module.scss';
import { withHook } from '../../util/withHook';

const findTogglePosition = isToggled =>
  cx({
    [STYLES.toggleButton]: true,
    [STYLES.toggledOn]: isToggled,
    [STYLES.toggledOff]: !isToggled,
  });

class DevelopersPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    reposList: instanceOf(List),
    reposLinks: instanceOf(Map),
    isDevModeOn: bool,
    isTestNetEnabled: bool,
    isFetchingReposList: bool,
    activeReposPage: number,
    reposPerPage: number,
    reposSortCategory: string,
    reposSortDirection: string,
    onToggleDevMode: func.isRequired,
    onToggleHorizonNet: func.isRequired,
    onGetReposList: func.isRequired,
    onUpdateReposSortCategory: func.isRequired,
    onUpdateReposSortDirection: func.isRequired,
  };

  static defaultProps = {
    reposList: new List(),
    reposLinks: new Map(),
    reposSortCategory: 'updated',
    reposSortDirection: 'desc',
    isDevModeOn: false,
    isTestNetEnabled: false,
    isFetchingReposList: false,
    activeReposPage: 1,
    reposPerPage: 10,
  };

  constructor(props) {
    super(props);

    this.reposTop = React.createRef();
  }

  componentDidMount = () => {
    const {
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection,
      onGetReposList,
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/developers',
        title: 'Developers',
      });
    }

    onGetReposList(
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection
    );
  };

  paginatePage = direction => {
    const {
      onGetReposList,
      activeReposPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection,
    } = this.props;
    const newPage =
      direction === 'NEXT' ? activeReposPage + 1 : activeReposPage - 1;
    const reposTop = ReactDOM.findDOMNode(this.reposTop.current).offsetTop;

    onGetReposList(
      newPage,
      reposPerPage,
      reposSortCategory,
      reposSortDirection
    );
    window.scrollTo(0, reposTop);
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Developers';
    const description =
      'The Developers page on StellarUp provides useful tools and insights to help aide the Stellar development community.';
    const developerUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={developerUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      reposList,
      reposLinks,
      reposSortCategory,
      reposSortDirection,
      isDevModeOn,
      isTestNetEnabled,
      isFetchingReposList,
      onToggleDevMode,
      onToggleHorizonNet,
      onUpdateReposSortCategory,
      onUpdateReposSortDirection,
    } = this.props;

    return (
      <div className={STYLES.DevelopersPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>DEVELOPERS</h2>
              </div>
              <div className={STYLES.dividerWrapper}>
                <div className={STYLES.divider} />
              </div>
              <div>
                <div className={STYLES.settings}>
                  <div className={STYLES.settingsRow}>
                    <div className={STYLES.explanation}>
                      <h3>
                        <GoCode className={STYLES.settingsIcon} /> DEVELOPER
                        MODE{' '}
                        {isDevModeOn ? (
                          <span className={STYLES.enabledText}>ENABLED</span>
                        ) : (
                          <span className={STYLES.disabledText}>DISABLED</span>
                        )}
                      </h3>
                      <p>
                        By default developer mode is toggled off. Toggle this
                        option on to see developer specific tools through out
                        the website such as helpful json responses from the
                        Horizon network.
                      </p>
                    </div>
                    <div className={STYLES.toggleWrapper}>
                      <div className={STYLES.toggle}>
                        <div
                          onClick={onToggleDevMode.bind(null, isDevModeOn)}
                          className={STYLES.toggledOffBg}
                        />
                        <div
                          onClick={onToggleDevMode.bind(null, isDevModeOn)}
                          className={STYLES.toggledOnBg}
                        />
                        <div
                          onClick={onToggleDevMode.bind(null, isDevModeOn)}
                          className={findTogglePosition(isDevModeOn)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={STYLES.settingsRow}>
                    <div className={STYLES.explanation}>
                      <h3>
                        <FaConnectdevelop className={STYLES.settingsIcon} />{' '}
                        TEST NET{' '}
                        {isTestNetEnabled ? (
                          <span className={STYLES.enabledText}>ENABLED</span>
                        ) : (
                          <span className={STYLES.disabledText}>DISABLED</span>
                        )}
                      </h3>
                      <p>
                        By default the main net is enabled. If you want to
                        switch to the test net toggle this option on.
                      </p>
                    </div>
                    <div className={STYLES.toggleWrapper}>
                      <div className={STYLES.toggle}>
                        <div
                          onClick={onToggleHorizonNet.bind(
                            null,
                            isTestNetEnabled
                          )}
                          className={STYLES.toggledOffBg}
                        />
                        <div
                          onClick={onToggleHorizonNet.bind(
                            null,
                            isTestNetEnabled
                          )}
                          className={STYLES.toggledOnBg}
                        />
                        <div
                          onClick={onToggleHorizonNet.bind(
                            null,
                            isTestNetEnabled
                          )}
                          className={findTogglePosition(isTestNetEnabled)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div ref={this.reposTop}>
                  <ReposList
                    reposList={reposList}
                    reposLinks={reposLinks}
                    reposSortCategory={reposSortCategory}
                    reposSortDirection={reposSortDirection}
                    isFetchingReposList={isFetchingReposList}
                    onPaginate={this.paginatePage}
                    onUpdateReposSortCategory={onUpdateReposSortCategory}
                    onUpdateReposSortDirection={onUpdateReposSortDirection}
                  />
                  <div className={STYLES.adWrapBottom}>
                    <GoogleAd placement="Bottom" adSlot="7799510885" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
  isTestNetEnabled: SELECTORS.getIsTestNetEnabled(state),
  isFetchingReposList: SELECTORS.getIsFetchingReposList(state),
  reposList: SELECTORS.getReposList(state),
  reposLinks: SELECTORS.getReposLinks(state),
  activeReposPage: SELECTORS.getActiveReposPage(state),
  reposPerPage: SELECTORS.getReposPerPage(state),
  reposSortCategory: SELECTORS.getReposSortCategory(state),
  reposSortDirection: SELECTORS.getReposSortDirection(state),
});

const mapDispatchToProps = {
  onGetReposList: getReposList,
  onToggleDevMode: toggleDevMode,
  onToggleHorizonNet: toggleHorizonNet,
  onUpdateReposSortCategory: updateReposSortCategory,
  onUpdateReposSortDirection: updateReposSortDirection,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(DevelopersPage))
);
