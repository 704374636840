import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_LEDGER_OPERATIONS,
  GET_LEDGER_OPERATIONS_ERROR,
  GET_LEDGER_OPERATIONS_SUCCESS,
} from './types';

const getLedgerOperationsSuccess = ({
  ledgerOperations,
  ledgerOperationsOrder,
  isMoreLedgerOperations,
}) => ({
  type: GET_LEDGER_OPERATIONS_SUCCESS,
  payload: {
    ledgerOperations,
    ledgerOperationsOrder,
    isMoreLedgerOperations,
    isFetchingLedgerOperations: false,
  },
});

const getLedgerOperationsError = error => ({
  type: GET_LEDGER_OPERATIONS_ERROR,
  payload: { error, isFetchingLedgerOperations: false },
});

const requestLedgerOperations = () => ({
  type: REQUEST_LEDGER_OPERATIONS,
  payload: { isFetchingLedgerOperations: true },
});

export default (ledgerId, pagingToken, order) => async (dispatch, getState) => {
  dispatch(requestLedgerOperations());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const operationOrder = order ? order : 'desc';

    const res = await server
      .operations()
      .cursor(cursor)
      .order(operationOrder)
      .limit(10)
      .forLedger(ledgerId)
      .call();
    const nextPage =
      operationOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getLedgerOperationsSuccess({
        ledgerOperations: res.records,
        ledgerOperationsOrder: operationOrder,
        isMoreLedgerOperations: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getLedgerOperationsError(err));
  }
};
