import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ACCOUNT_EFFECTS,
  GET_ACCOUNT_EFFECTS_ERROR,
  GET_ACCOUNT_EFFECTS_SUCCESS,
} from './types';

const getAccountEffectsSuccess = ({
  accountEffects,
  accountEffectsOrder,
  isMoreAccountEffects,
}) => ({
  type: GET_ACCOUNT_EFFECTS_SUCCESS,
  payload: {
    accountEffects,
    accountEffectsOrder,
    isMoreAccountEffects,
    isFetchingAccountEffects: false,
  },
});

const getAccountEffectsError = error => ({
  type: GET_ACCOUNT_EFFECTS_ERROR,
  payload: { error, isFetchingAccountEffects: false },
});

const requestAccountEffects = () => ({
  type: REQUEST_ACCOUNT_EFFECTS,
  payload: { isFetchingAccountEffects: true },
});

export default (accountId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestAccountEffects());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const effectOrder = order ? order : 'desc';

    const res = await server
      .effects()
      .cursor(cursor)
      .order(effectOrder)
      .limit(10)
      .forAccount(accountId)
      .call();
    const nextPage =
      effectOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getAccountEffectsSuccess({
        accountEffects: res.records,
        accountEffectsOrder: effectOrder,
        isMoreAccountEffects: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getAccountEffectsError(err));
  }
};
