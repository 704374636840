import React from 'react';
import { string } from 'prop-types';

import { OverlayTrigger, Popover } from 'react-bootstrap/lib';
import { MdInfo } from 'react-icons/md';

import STYLES from './InfoToolTip.module.scss';

const customPopOver = ({ info, width, link, title }) => (
  <Popover id={title} style={{ padding: 0 }}>
    <div
      className={STYLES.InfoToolTipPopOver}
      style={{
        width: width ? width : '300px',
      }}>
      <h5 className={STYLES.title}>{title}</h5>
      <p className={STYLES.info}>{info}</p>
      {link && (
        <div className={STYLES.linkWrap}>
          <a href={link} rel="noopener noreferrer" target="_blank">
            Read More
          </a>
        </div>
      )}
    </div>
  </Popover>
);

const InfoToolTip = ({ info, placement, width, size, link, title }) => (
  <span className={STYLES.InfoToolTip}>
    <OverlayTrigger
      trigger="click"
      rootClose
      placement={placement}
      overlay={customPopOver({ info, width, link, title })}>
      <MdInfo className={STYLES.icon} size={size} />
    </OverlayTrigger>
  </span>
);

InfoToolTip.propTypes = {
  info: string.isRequired,
  placement: string,
  width: string,
  size: string,
  link: string,
  title: string,
};

InfoToolTip.defaultProps = {
  placement: 'right',
  width: '275px',
  size: '17px',
  link: '',
  title: '',
};

export default InfoToolTip;
