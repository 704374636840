import React from 'react';
import { Link } from 'react-router-dom';

import { GoTools, GoGear } from 'react-icons/go';
import { FaAnchor } from 'react-icons/fa';
import {
  MdAccountBalanceWallet,
  MdAutorenew,
  MdGames,
  MdForum,
  MdDescription,
} from 'react-icons/md';

import STYLES from './Categories.module.scss';

const CATEGORIES = [
  {
    id: 1,
    title: 'Anchors',
    icon: <FaAnchor />,
    link: '/community/anchors',
    description:
      'Apps that act as a bridge between different currencies and the Stellar network.',
    examples: 'For example: Ripplefox, NaoBTC, Tempo, Moni.',
  },
  {
    id: 2,
    title: 'Dev Tools',
    icon: <GoTools />,
    link: '/community/devTools',
    description:
      'Apps that provide tools for Stellar app development, management, and distribution.',
    examples:
      'For example: Soneso Stellar iOS SDK, dotnetcore-stellar-sdk, StellarQtSDK.',
  },
  {
    id: 3,
    title: 'Exchanges',
    icon: <MdAutorenew />,
    link: '/community/exchanges',
    description:
      'Apps that provide an online platform acting as an intermediary between buyers and sellers of Stellar Lumens.',
    examples: 'For example: Binance, Stellarport, BlockEQ, GALTEX.',
  },
  {
    id: 4,
    title: 'Games',
    icon: <MdGames />,
    link: '/community/games',
    description:
      'Apps that provide single or multiplayer interactive activities for entertainment purposes on or for the Stellar Network.',
    examples:
      'For example: Colorglyph, CrystalBall, StellarDice, Celestial Place.',
  },
  {
    id: 5,
    title: 'Reference',
    icon: <MdDescription />,
    link: '/community/reference',
    description:
      'Apps that assist the user in accessing or retrieving information pertaining to Stellar.',
    examples:
      'For example: StellarExpert, Stellar Explorer, Stellar Navigator, lumenauts.',
  },
  {
    id: 6,
    title: 'Social Networking',
    icon: <MdForum />,
    link: '/community/socialNetworking',
    description:
      'Apps that connect people through text, voice, photo, or video. Apps that contribute to Stellar community development.',
    examples: 'For example: Galactic Talk, mystellar.org.',
  },
  {
    id: 7,
    title: 'Utilities',
    icon: <GoGear />,
    link: '/community/utilities',
    description:
      'Apps that enable the user to solve a problem or complete a specific task on or for the Stellar Network.',
    examples:
      'For example: Stellar Pricey, Stellar Donate Plugin, Stellar Asset History.',
  },
  {
    id: 8,
    title: 'Wallets',
    icon: <MdAccountBalanceWallet />,
    link: '/community/wallets',
    description:
      'Apps that store public and private keys which can be used to receive or spend Stellar Lumens.',
    examples: 'For example: Lobstr, Saza, Alfred, Stroopy.',
  },
];

const Categories = () => (
  <div className={STYLES.Categories}>
    {CATEGORIES.map(category => (
      <div key={category.id} className={STYLES.category}>
        <div className={STYLES.categoryIcon}>{category.icon}</div>
        <div className={STYLES.categoryItem}>
          <div className={STYLES.titleContainer}>
            <h4>
              <Link to={category.link}>{category.title}</Link>
            </h4>
          </div>
          <div>{category.description}</div>
          <div className={STYLES.flexDetails}>{category.examples}</div>
        </div>
      </div>
    ))}
  </div>
);

export default Categories;
