import React, { Component } from 'react';
import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Button from 'react-bootstrap/lib/Button';
import {
  MdDashboard,
  MdStorage,
  MdTransform,
  MdAccountBalance,
  MdCompareArrows,
  MdClose,
  MdPeople,
} from 'react-icons/md';
import { GoGitBranch } from 'react-icons/go';

import { toggleSidebar } from '../actions/ui';
import * as SELECTORS from '../selectors';

import STYLES from './Sidebar.module.scss';

const determineActiveButton = (currentPath, buttonPath) =>
  cx({
    [STYLES.navButton]: true,
    [STYLES.activeNavButton]: currentPath === buttonPath,
  });

class Sidebar extends Component {
  static propTypes = {
    location: object.isRequired,
    isSidebarOpen: bool,
    toggleSidebar: func.isRequired,
  };

  static defaultProps = {
    isSidebarOpen: false,
  };

  handleSidebar = () => {
    const { isSidebarOpen } = this.props;

    this.props.toggleSidebar(isSidebarOpen);
  };

  render() {
    const { location } = this.props;

    return (
      <div className={STYLES.Sidebar}>
        <div className={STYLES.closeIcon} onClick={this.handleSidebar}>
          <MdClose />
        </div>
        <div className={STYLES.brand}>
          <h1>StellarUp</h1>
        </div>
        <div className={STYLES.navListSmall}>
          <Link onClick={this.handleSidebar} to="/">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/')}>
              <MdDashboard
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.dashboard]: true,
                })}
              />
              DASHBOARD
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/operations">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/operations'
              )}>
              <MdTransform
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.operations]: true,
                })}
              />
              OPERATIONS
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/transactions">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/transactions'
              )}>
              <MdCompareArrows
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.transactions]: true,
                })}
              />
              TRANSACTIONS
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/assets">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/assets')}>
              <MdAccountBalance
                className={cx({ [STYLES.icon]: true, [STYLES.assets]: true })}
              />
              ASSETS
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/ledgers">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/ledgers')}>
              <MdStorage
                className={cx({ [STYLES.icon]: true, [STYLES.ledger]: true })}
              />
              LEDGERS
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/developers">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/developers'
              )}>
              <GoGitBranch
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.developers]: true,
                })}
              />
              DEVELOPERS
            </Button>
          </Link>
          <Link onClick={this.handleSidebar} to="/community">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/community'
              )}>
              <MdPeople
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.community]: true,
                })}
              />
              COMMUNITY
            </Button>
          </Link>
        </div>
        <div className={STYLES.navListLarge}>
          <Link to="/">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/')}>
              <MdDashboard
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.dashboard]: true,
                })}
              />
              DASHBOARD
            </Button>
          </Link>
          <Link to="/operations">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/operations'
              )}>
              <MdTransform
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.operations]: true,
                })}
              />
              OPERATIONS
            </Button>
          </Link>
          <Link to="/transactions">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/transactions'
              )}>
              <MdCompareArrows
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.transactions]: true,
                })}
              />
              TRANSACTIONS
            </Button>
          </Link>
          <Link to="/assets">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/assets')}>
              <MdAccountBalance
                className={cx({ [STYLES.icon]: true, [STYLES.assets]: true })}
              />
              ASSETS
            </Button>
          </Link>
          <Link to="/ledgers">
            <Button
              bsSize="lg"
              className={determineActiveButton(location.pathname, '/ledgers')}>
              <MdStorage
                className={cx({ [STYLES.icon]: true, [STYLES.ledger]: true })}
              />
              LEDGERS
            </Button>
          </Link>
          <Link to="/developers">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/developers'
              )}>
              <GoGitBranch
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.developers]: true,
                })}
              />
              DEVELOPERS
            </Button>
          </Link>
          <Link to="/community">
            <Button
              bsSize="lg"
              className={determineActiveButton(
                location.pathname,
                '/community'
              )}>
              <MdPeople
                className={cx({
                  [STYLES.icon]: true,
                  [STYLES.community]: true,
                })}
              />
              COMMUNITY
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSidebarOpen: SELECTORS.getIsSidebarOpen(state),
});

const mapDispatchToProps = {
  toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
