import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_LEDGER_TRANSACTIONS,
  GET_LEDGER_TRANSACTIONS_ERROR,
  GET_LEDGER_TRANSACTIONS_SUCCESS,
} from './types';

const getLedgerTransactionsSuccess = ({
  ledgerTransactions,
  ledgerTransactionsOrder,
  isMoreLedgerTransactions,
}) => ({
  type: GET_LEDGER_TRANSACTIONS_SUCCESS,
  payload: {
    ledgerTransactions,
    ledgerTransactionsOrder,
    isMoreLedgerTransactions,
    isFetchingLedgerTransactions: false,
  },
});

const getLedgerTransactionsError = error => ({
  type: GET_LEDGER_TRANSACTIONS_ERROR,
  payload: { error, isFetchingLedgerTransactions: false },
});

const requestLedgerTransactions = () => ({
  type: REQUEST_LEDGER_TRANSACTIONS,
  payload: { isFetchingLedgerTransactions: true },
});

export default (ledgerId, pagingToken, order) => async (
  dispatch,
  getState,
  api
) => {
  dispatch(requestLedgerTransactions());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const transactionsOrder = order ? order : 'desc';

    const res = await server
      .transactions()
      .cursor(cursor)
      .order(transactionsOrder)
      .limit(10)
      .forLedger(ledgerId)
      .call();
    const nextPage =
      transactionsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getLedgerTransactionsSuccess({
        ledgerTransactions: res.records,
        ledgerTransactionsOrder: transactionsOrder,
        isMoreLedgerTransactions: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getLedgerTransactionsError(err));
  }
};
