import { Map } from 'immutable';
import * as types from '../actions/operation/types';

const ACTIONS = {
  // All Operations
  [types.REQUEST_ALL_OPERATIONS]: (state, action) => {
    const { isFetchingOperations } = action.payload;
    return state.merge({ isFetchingOperations });
  },
  [types.GET_ALL_OPERATIONS_SUCCESS]: (state, action) => {
    const {
      operations,
      operationsOrder,
      isFetchingOperations,
    } = action.payload;
    return state.merge({
      operations,
      operationsOrder,
      isFetchingOperations,
    });
  },
  [types.GET_ALL_OPERATIONS_ERROR]: (state, action) => {
    const { error, isFetchingOperations } = action.payload;
    return state.merge({ error, isFetchingOperations });
  },
  // Operation Details
  [types.REQUEST_OPERATION_DETAILS]: (state, action) => {
    const { isFetchingOperationDetails } = action.payload;
    return state.merge({ isFetchingOperationDetails });
  },
  [types.GET_OPERATION_DETAILS_SUCCESS]: (state, action) => {
    const { operationDetails, isFetchingOperationDetails } = action.payload;
    return state.merge({ operationDetails, isFetchingOperationDetails });
  },
  [types.GET_OPERATION_DETAILS_ERROR]: (state, action) => {
    const { error, isFetchingOperationDetails } = action.payload;
    return state.merge({ error, isFetchingOperationDetails });
  },
  // Operation Effects
  [types.REQUEST_OPERATION_EFFECTS]: (state, action) => {
    const { isFetchingOperationEffects } = action.payload;
    return state.merge({ isFetchingOperationEffects });
  },
  [types.GET_OPERATION_EFFECTS_SUCCESS]: (state, action) => {
    const {
      operationEffects,
      operationEffectsOrder,
      isMoreOperationEffects,
      isFetchingOperationEffects,
    } = action.payload;
    return state.merge({
      operationEffects,
      operationEffectsOrder,
      isMoreOperationEffects,
      isFetchingOperationEffects,
    });
  },
  [types.GET_OPERATION_EFFECTS_ERROR]: (state, action) => {
    const { error, isFetchingOperationEffects } = action.payload;
    return state.merge({ error, isFetchingOperationEffects });
  },
};

export default function operationReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
