import axios from 'axios';
import * as types from './types';

const requestCoinStats = () => ({
  type: types.REQUEST_COIN_STATS,
  payload: { isFetchingCoinStats: true },
});

const getCoinStatsError = error => ({
  type: types.GET_COIN_STATS_ERROR,
  payload: { error, isFetchingCoinStats: false },
});

const getCoinStatsSuccess = coinStats => ({
  type: types.GET_COIN_STATS_SUCCESS,
  payload: { coinStats, isFetchingCoinStats: false },
});

export const getCoinStats = () => async dispatch => {
  dispatch(requestCoinStats());

  try {
    const res = await axios({
      host: process.env.REACT_APP_API_GATEWAY_HOST,
      method: 'GET',
      url: `${process.env.REACT_APP_API_GATEWAY_URL}/stats/latest`,
      path: '/dev/stats/latest',
    });

    dispatch(getCoinStatsSuccess(res.data));
  } catch (err) {
    dispatch(getCoinStatsError(err));
  }
};

// Get Coin History
const requestCoinHistory = () => ({
  type: types.REQUEST_COIN_HISTORY,
  payload: { isFetchingCoinHistory: true },
});

const getCoinHistoryError = error => ({
  type: types.GET_COIN_HISTORY_ERROR,
  payload: { error, isFetchingCoinHistory: false },
});

const getCoinHistorySuccess = coinHistory => ({
  type: types.GET_COIN_HISTORY_SUCCESS,
  payload: {
    weekHistory: coinHistory.week,
    monthHistory: coinHistory.month,
    yearHistory: coinHistory.year,
    isFetchingCoinHistory: false,
  },
});

export const getCoinHistory = () => async dispatch => {
  dispatch(requestCoinHistory());

  try {
    const res = await axios({
      host: process.env.REACT_APP_API_GATEWAY_HOST,
      method: 'GET',
      url: `${process.env.REACT_APP_API_GATEWAY_URL}/stats/historical`,
      path: '/dev/stats/historical',
    });

    dispatch(getCoinHistorySuccess(res.data));
  } catch (err) {
    dispatch(getCoinHistoryError(err));
  }
};
