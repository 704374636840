export const REQUEST_ALL_OPERATIONS = 'REQUEST_ALL_OPERATIONS';
export const GET_ALL_OPERATIONS_SUCCESS = 'GET_ALL_OPERATIONS_SUCCESS';
export const GET_ALL_OPERATIONS_ERROR = 'GET_ALL_OPERATIONS_ERROR';

export const REQUEST_OPERATION_DETAILS = 'REQUEST_OPERATION_DETAILS';
export const GET_OPERATION_DETAILS_SUCCESS = 'GET_OPERATION_DETAILS_SUCCESS';
export const GET_OPERATION_DETAILS_ERROR = 'GET_OPERATION_DETAILS_ERROR';

export const REQUEST_OPERATION_EFFECTS = 'REQUEST_OPERATION_EFFECTS';
export const GET_OPERATION_EFFECTS_SUCCESS = 'GET_OPERATION_EFFECTS_SUCCESS';
export const GET_OPERATION_EFFECTS_ERROR = 'GET_OPERATION_EFFECTS_ERROR';
