import React, { Component } from 'react';
import { string } from 'prop-types';

import STYLES from './GoogleAd.module.scss';

class GoogleAd extends Component {
  static propTypes = {
    adSlot: string.isRequired,
    adKey: string.isRequired,
    placement: string.isRequired,
  };

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    const { adSlot, placement } = this.props;

    if (process.env.NODE_ENV === 'development') {
      return (
        <div className={STYLES.GoogleAd}>
          <div
            className={`testgoogle ${STYLES['adUnit' + placement]}`}
            style={{
              border: '1px solid blue',
            }}>
            <div>
              <h4>Google Ad</h4>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={STYLES.GoogleAd}>
        <ins
          className={`adsbygoogle ${STYLES['adUnit' + placement]}`}
          style={{ display: 'inline-block' }}
          data-ad-client="ca-pub-5789456306728233"
          data-ad-slot={adSlot}></ins>
      </div>
    );
  }
}

export default GoogleAd;
