import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import { push } from 'react-router-redux';
import {
  REQUEST_ACCOUNT_DETAILS,
  GET_ACCOUNT_DETAILS_ERROR,
  GET_ACCOUNT_DETAILS_SUCCESS,
} from './types';

const getAccountDetailsSuccess = accountDetails => ({
  type: GET_ACCOUNT_DETAILS_SUCCESS,
  payload: { accountDetails, isFetchingAccountDetails: false },
});

const getAccountDetailsError = error => ({
  type: GET_ACCOUNT_DETAILS_ERROR,
  payload: { error, isFetchingAccountDetails: false },
});

const requestAccountDetails = () => ({
  type: REQUEST_ACCOUNT_DETAILS,
  payload: { isFetchingAccountDetails: true },
});

export default accountId => async (dispatch, getState) => {
  dispatch(requestAccountDetails());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;

    const res = await server
      .accounts()
      .accountId(accountId)
      .call();

    dispatch(getAccountDetailsSuccess(res));
  } catch (err) {
    if (err.response.status === 404) {
      dispatch(push('*'));
    }
    dispatch(getAccountDetailsError(err.response.status));
  }
};
