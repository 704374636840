import React, { Component } from 'react';
import { instanceOf, number, bool, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

import { FormGroup, FormControl } from 'react-bootstrap/lib';
import { MdSearch } from 'react-icons/md';

import { getProjectsSR } from '../../../actions/community';
import * as SELECTORS from '../../../selectors';

import AdvancedFilter from './AdvancedFilter';
import ProjectsList from './ProjectsList';

import STYLES from './AdvancedSearch.module.scss';

const buildQuery = query => {
  const queryParams = {
    ...(query.name && { name: query.name }),
    ...(query.openSource && { openSource: query.openSource }),
    ...(query.sbc && { sbc: query.sbc }),
    ...(query.category && { category: query.category }),
  };

  return isEmpty(queryParams) ? null : queryParams;
};

class AdvancedSearch extends Component {
  static propTypes = {
    projectsSR: instanceOf(List),
    projectsSRCount: number,
    isFetchingProjectsSR: bool,
    onGetProjectsSR: func.isRequired,
  };

  static defaultProps = {
    projectsSR: new List(),
    isFetchingProjectsSR: false,
  };

  state = {
    name: '',
    openSource: false,
    sbc: null,
    category: null,
    previousSearch: {},
  };

  handleProjectSearchText = e => {
    this.setState({ name: e.currentTarget.value || '' });
  };

  handleFilterToggle = prop => {
    this.setState({ [prop]: !this.state[prop] });
  };

  handleFilterSelect = (prop, value) => {
    if (this.state[prop] === value) {
      return this.setState({ [prop]: null });
    }

    this.setState({ [prop]: value });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') return this.submitProjectSearch();
  };

  submitProjectSearch = () => {
    const { name, openSource, sbc, category, previousSearch } = this.state;
    const { onGetProjectsSR } = this.props;

    if (!name && !openSource && !sbc && !category) {
      return;
    }

    const queryParams = buildQuery({
      name,
      openSource,
      sbc,
      category,
    });

    if (isEqual(previousSearch, queryParams)) return;

    onGetProjectsSR(queryParams);
    this.setState({ previousSearch: queryParams });
  };

  render() {
    const { sbc, openSource, category } = this.state;
    const { projectsSR, projectsSRCount, isFetchingProjectsSR } = this.props;

    return (
      <div className={STYLES.AdvancedSearch}>
        <div className={STYLES.topWrapper}>
          <div>
            <h2 className={STYLES.projectsTitle}>STELLAR PROJECTS</h2>
          </div>
        </div>
        <div className={STYLES.searchWrapper}>
          <FormGroup className={STYLES.formGroup}>
            <FormControl
              className={STYLES.searchInput}
              type="text"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleProjectSearchText}
              placeholder="Search projects by name"
            />
            <MdSearch
              onClick={this.submitProjectSearch}
              className={STYLES.searchGlass}
              fill="#9698AA"
            />
          </FormGroup>

          {/*{projectsSR && projectsSR.size ? (
            <SortDropdown
              projectsSRSortCategory={projectsSRSortCategory}
              projectsSRSortOrder={projectsSRSortOrder}
              onUpdateProjectsSRSortCategory={this.updateProjectsSRSortCategory}
              onUpdateProjectsSRSortOrder={this.updateProjectsSRSortOrder}
            />
          ) : null}*/}
        </div>
        <AdvancedFilter
          sbc={sbc}
          openSource={openSource}
          category={category}
          onFilterToggle={this.handleFilterToggle}
          onFilterSelect={this.handleFilterSelect}
        />

        {/*<div className={STYLES.resultsInfo}>
          { showingStart && showingEnd && projectsSRCount ? (
            <span>
              <span className={STYLES.resultsMinor}>
                {showingStart} to {showingEnd} of
              </span> {projectsSRCount} results
            </span>
          ) : null}
        </div>*/}

        <div className={STYLES.dividerWrapper}>
          <div className={STYLES.divider} />
        </div>
        <ProjectsList
          projectsSR={projectsSR}
          isFetchingProjectsSR={isFetchingProjectsSR}
          onPaginatePage={this.paginatePage}
          projectsCount={projectsSRCount}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projectsSR: SELECTORS.getProjectsSR(state),
  projectsSRCount: SELECTORS.getProjectsSRCount(state),
  isFetchingProjectsSR: SELECTORS.getIsFetchingProjectsSR(state),
});

const mapDispatchToProps = {
  onGetProjectsSR: getProjectsSR,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
