import React, { Component } from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';

import Tabs from 'react-bootstrap/lib/Tabs';
import Tab from 'react-bootstrap/lib/Tab';

import TabTitle from './TabTitle';
import Effects from '../../../components/effects/Effects';

import STYLES from './TabbedList.module.scss';

class TabbedList extends Component {
  static propTypes = {
    operationEffects: instanceOf(List),
    operationEffectsPage: number,
    operationEffectsOrder: string,
    isMoreOperationEffects: bool,
    isDevModeOn: bool,
    toggleCodeModal: func.isRequired,
    onGetNextPage: func,
    onGetPreviousPage: func,
  };

  static defaultProps = {
    operationEffects: new List(),
    operationEffectsPage: 1,
    isMoreOperationEffects: false,
    operationEffectsOrder: 'desc',
    isDevModeOn: false,
    onGetNextPage: () => {},
    onGetPreviousPage: () => {},
  };

  state = { activeTabKey: 1 };

  handleTabSelect = activeTabKey => {
    this.setState({ activeTabKey });
  };

  render() {
    const {
      operationEffects,
      operationEffectsPage,
      operationEffectsOrder,
      isMoreOperationEffects,
      isDevModeOn,
      toggleCodeModal,
      onGetNextPage,
      onGetPreviousPage,
    } = this.props;
    const { activeTabKey } = this.state;

    return (
      <Tabs
        id="ledger-tabs"
        activeKey={activeTabKey}
        onSelect={this.handleTabSelect}
        className={STYLES.TabbedList}>
        <Tab
          eventKey={1}
          title={
            <TabTitle title="Effects" tabKey={1} activeTabKey={activeTabKey} />
          }>
          <Effects
            effects={operationEffects}
            effectsOrder={operationEffectsOrder}
            currentPage={operationEffectsPage}
            isMoreEffects={isMoreOperationEffects}
            isDevModeOn={isDevModeOn}
            toggleCodeModal={toggleCodeModal}
            onGetNextPage={onGetNextPage}
            onGetPreviousPage={onGetPreviousPage}
          />
        </Tab>
      </Tabs>
    );
  }
}

export default TabbedList;
