import { Map } from 'immutable';
import * as types from '../actions/search/types';

const ACTIONS = {
  [types.REQUEST_SEARCH_VALUE]: (state, action) => {
    const { isFetchingSearchValue } = action.payload;
    return state.merge({ isFetchingSearchValue });
  },
  [types.GET_SEARCH_VALUE_ERROR]: (state, action) => {
    const { error, isFetchingSearchValue } = action.payload;
    return state.merge({ error, isFetchingSearchValue });
  },
  [types.GET_SEARCH_VALUE_SUCCESS]: (state, action) => {
    const { isFetchingSearchValue } = action.payload;
    return state.merge({ isFetchingSearchValue });
  },
};

export default function searchReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
