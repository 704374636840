import React from 'react';
import { instanceOf, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import moment from 'moment';

import Button from 'react-bootstrap/lib/Button';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {
  GoStar,
  GoRepoForked,
  GoMarkGithub,
  GoIssueOpened,
} from 'react-icons/go';
import { MdCheck } from 'react-icons/md';

import STYLES from './ReposList.module.scss';

// <Link to={`/developers/repositories/${repo.name}`}>
//   {repo.full_name}
// </Link>

const ReposList = ({
  reposList,
  reposLinks,
  reposSortCategory,
  reposSortDirection,
  isFetchingReposList,
  onPaginate,
  onUpdateReposSortCategory,
  onUpdateReposSortDirection,
}) => (
  <div className={STYLES.ReposList}>
    <div className={STYLES.topWrapper}>
      <div>
        <h2 className={STYLES.reposTitle}>
          <GoMarkGithub className={STYLES.githubIcon} /> STELLAR REPOSITORIES
        </h2>
      </div>
      <div className={STYLES.sortWrapper}>
        <div className={STYLES.sortTitle}>Sort By:</div>
        <DropdownButton
          className={STYLES.dropdownButton}
          pullRight
          bsStyle="link"
          id="sort-dropdown"
          title={reposSortCategory}>
          <MenuItem
            onClick={onUpdateReposSortCategory.bind(null, 'created')}
            eventKey="1">
            {reposSortCategory === 'created' ? (
              <span>
                <MdCheck className={STYLES.dropdownIcon} /> Created
              </span>
            ) : (
              <span className={STYLES.dropdownPlaceholder}>Created</span>
            )}
          </MenuItem>
          <MenuItem
            onClick={onUpdateReposSortCategory.bind(null, 'updated')}
            eventKey="2">
            {reposSortCategory === 'updated' ? (
              <span>
                <MdCheck className={STYLES.dropdownIcon} /> Updated
              </span>
            ) : (
              <span className={STYLES.dropdownPlaceholder}>Updated</span>
            )}
          </MenuItem>
          <MenuItem
            onClick={onUpdateReposSortCategory.bind(null, 'pushed')}
            eventKey="3">
            {reposSortCategory === 'pushed' ? (
              <span>
                <MdCheck className={STYLES.dropdownIcon} /> Pushed
              </span>
            ) : (
              <span className={STYLES.dropdownPlaceholder}>Pushed</span>
            )}
          </MenuItem>
          <MenuItem divider />
          <MenuItem
            onClick={onUpdateReposSortDirection.bind(null, 'asc')}
            eventKey="4">
            {reposSortDirection === 'asc' ? (
              <span>
                <MdCheck className={STYLES.dropdownIcon} /> asc
              </span>
            ) : (
              <span className={STYLES.dropdownPlaceholder}>asc</span>
            )}
          </MenuItem>
          <MenuItem
            onClick={onUpdateReposSortDirection.bind(null, 'desc')}
            eventKey="5">
            {reposSortDirection === 'desc' ? (
              <span>
                <MdCheck className={STYLES.dropdownIcon} /> desc
              </span>
            ) : (
              <span className={STYLES.dropdownPlaceholder}>desc</span>
            )}
          </MenuItem>
        </DropdownButton>
      </div>
    </div>
    <div className={STYLES.dividerWrapper}>
      <div className={STYLES.divider} />
    </div>
    <div className={STYLES.repos}>
      {isFetchingReposList ? (
        <div>
          <div className="loading-spinner-animation" />
        </div>
      ) : (
        <div>
          {reposList &&
            reposList.map(repo => (
              <div key={repo.get('id')} className={STYLES.repoRow}>
                <div className={STYLES.repoItem}>
                  <div className={STYLES.titleContainer}>
                    <h4>
                      <a
                        href={repo.get('svn_url')}
                        target="_blank"
                        rel="noopener noreferrer">
                        {repo.get('full_name')}
                      </a>
                    </h4>
                    {repo.get('language') && (
                      <div className={STYLES.label}>
                        Language: {repo.get('language')}
                      </div>
                    )}
                  </div>
                  <p className={STYLES.date}>
                    {reposSortCategory === 'updated' && (
                      <span>
                        Updated:{' '}
                        {moment(repo.get('updated_at')).format('MMMM DD YYYY')}
                      </span>
                    )}
                    {reposSortCategory === 'created' && (
                      <span>
                        Created:{' '}
                        {moment(repo.get('created_at')).format('MMMM DD YYYY')}
                      </span>
                    )}
                    {reposSortCategory === 'pushed' && (
                      <span>
                        Pushed:{' '}
                        {moment(repo.get('pushed_at')).format('MMMM DD YYYY')}
                      </span>
                    )}
                  </p>
                  <div className={STYLES.flexDetails}>
                    <div className={STYLES.repoStat}>
                      <div className={STYLES.statIcon}>
                        <GoStar className={STYLES.statIcon} />
                      </div>
                      <div className={STYLES.stat}>Stars</div>
                      <div className={STYLES.stat}>
                        {repo.get('stargazers_count')}
                      </div>
                    </div>
                    {/*<div className={STYLES.repoStat}>
                    {repo.get('watchers')}{' '}
                    <GoEye className={STYLES.statIcon} />{' '}
                    Watchers
                  </div>*/}
                    <div className={STYLES.repoStat}>
                      <div className={STYLES.statIcon}>
                        <GoRepoForked className={STYLES.statIcon} />
                      </div>
                      <div className={STYLES.stat}>Forks</div>
                      <div className={STYLES.stat}>
                        {repo.get('forks_count')}
                      </div>
                    </div>
                    <div className={STYLES.repoStat}>
                      <div className={STYLES.statIcon}>
                        <GoIssueOpened className={STYLES.statIcon} />
                      </div>
                      <div className={STYLES.stat}>Open Issues</div>
                      <div className={STYLES.stat}>
                        {repo.get('open_issues_count')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className={STYLES.paginationButtons}>
            <div className={STYLES.paginationButtonWrap}>
              {reposLinks && reposLinks.get('prev') && (
                <Button
                  className={STYLES.paginationButton}
                  onClick={onPaginate.bind(null, 'PREVIOUS')}>
                  Previous
                </Button>
              )}
              {reposLinks && reposLinks.get('next') && (
                <Button
                  className={STYLES.paginationButton}
                  onClick={onPaginate.bind(null, 'NEXT')}>
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

ReposList.propTypes = {
  reposList: instanceOf(List),
  reposLinks: instanceOf(Map),
  reposSortCategory: string,
  reposSortDirection: string,
  isFetchingReposList: bool,
  onUpdateReposSortCategory: func.isRequired,
  onUpdateReposSortDirection: func.isRequired,
  onPaginate: func.isRequired,
};

ReposList.defaultProps = {
  reposList: new List(),
  reposLinks: new Map(),
  isFetchingReposList: false,
  reposSortCategory: 'updated',
  reposSortDirection: 'desc',
};

export default ReposList;
