import React from 'react';
import { number } from 'prop-types';

import STYLES from './MarketCapStat.module.scss';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 0,
});

const formatCurrency = ammount => formatter.format(ammount);

const MarketCapStat = ({ marketCap }) => (
  <div className={STYLES.MarketCapStat}>
    <div>
      <div className={STYLES.stats}>
        <div className={STYLES.currencySymbol}>$</div>
        <div className={STYLES.currencyPrice}>{formatCurrency(marketCap)}</div>
        <div className={STYLES.currencyAbbrev}>USD</div>
      </div>
      <div className={STYLES.description}>MARKET CAP</div>
    </div>
  </div>
);

MarketCapStat.propTypes = {
  marketCap: number,
};

MarketCapStat.defaultProps = {
  marketCap: 0,
};

export default MarketCapStat;
