import React from 'react';
import { instanceOf, string, number, bool, func } from 'prop-types';
import { List } from 'immutable';
import moment from 'moment';

import PaginationButtons from '../PaginationButtons';
import AccountMerge from './AccountMerge';
import AllowTrust from './AllowTrust';
import ChangeTrust from './ChangeTrust';
import CreateAccount from './CreateAccount';
import CreatePassiveOffer from './CreatePassiveOffer';
import Inflation from './Inflation';
import ManageData from './ManageData';
import ManageSellOffer from './ManageSellOffer';
import ManageBuyOffer from './ManageBuyOffer';
import Payment from './Payment';
import PaymentPath from './PaymentPath';
import SetOptions from './SetOptions';

import STYLES from './Operations.module.scss';

// Mapping to Operation type_i
const componentMap = {
  0: CreateAccount,
  1: Payment,
  2: PaymentPath,
  3: ManageSellOffer,
  4: CreatePassiveOffer,
  5: SetOptions,
  6: ChangeTrust,
  7: AllowTrust,
  8: AccountMerge,
  9: Inflation,
  10: ManageData,
  12: ManageBuyOffer,
};

const determineOrder = operations =>
  operations.sort(
    (a, b) => moment(b.get('created_at')) - moment(a.get('created_at'))
  );

const Operations = ({
  operations,
  currentPage,
  isMoreOperations,
  isFetchingOperations,
  matchId,
  isDevModeOn,
  toggleCodeModal,
  onGetNextPage,
  onGetPreviousPage,
}) => (
  <div className={STYLES.Operations}>
    {isFetchingOperations ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {operations && operations.size ? (
          <div>
            {determineOrder(operations).map(operation => {
              const OperationComponent = componentMap[operation.get('type_i')];

              // if (operation.get('type_i') === 11) {
              //   console.log('operation', operation.toJS());
              // }

              if (!OperationComponent) return;

              return (
                <div className={STYLES.operationRow} key={operation.get('id')}>
                  <OperationComponent
                    operation={operation}
                    matchId={matchId}
                    isDevModeOn={isDevModeOn}
                    toggleCodeModal={toggleCodeModal}
                  />
                </div>
              );
            })}
            <PaginationButtons
              section="Operations"
              currentPage={currentPage}
              isMoreItems={isMoreOperations}
              onGetNextPage={onGetNextPage}
              onGetPreviousPage={onGetPreviousPage}
            />
          </div>
        ) : (
          <div className={STYLES.noOperations}>
            <div className={STYLES.largeLabel}>
              There are no Operations for this item yet.
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);

Operations.propTypes = {
  matchId: string,
  operations: instanceOf(List),
  currentPage: number,
  isDevModeOn: bool,
  isMoreOperations: bool,
  isFetchingOperations: bool,
  toggleCodeModal: func.isRequired,
  onGetNextPage: func,
  onGetPreviousPage: func,
};

Operations.defaultProps = {
  matchId: '',
  currentPage: 1,
  isDevModeOn: false,
  isMoreOperations: false,
  isFetchingOperations: false,
  onGetNextPage: () => {},
  onGetPreviousPage: () => {},
};

export default Operations;
