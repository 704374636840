import axios from 'axios';
import {
  REQUEST_FEATURED_PROJECTS,
  GET_FEATURED_PROJECTS_ERROR,
  GET_FEATURED_PROJECTS_SUCCESS,
} from './types';

const getFeaturedProjectsSuccess = featuredProjects => ({
  type: GET_FEATURED_PROJECTS_SUCCESS,
  payload: {
    featuredProjects,
    isFetchingFeaturedProjects: false,
  },
});

const getFeaturedProjectsError = error => ({
  type: GET_FEATURED_PROJECTS_ERROR,
  payload: {
    error,
    isFetchingFeaturedProjects: false,
  },
});

const requestFeaturedProjects = () => ({
  type: REQUEST_FEATURED_PROJECTS,
  payload: {
    isFetchingFeaturedProjects: true,
  },
});

export default () => async dispatch => {
  dispatch(requestFeaturedProjects());

  try {
    const res = await axios({
      host: process.env.REACT_APP_API_GATEWAY_HOST,
      method: 'GET',
      url: `${process.env.REACT_APP_API_GATEWAY_URL}/projects/featured`,
      path: '/dev/projects/featured',
    });

    dispatch(getFeaturedProjectsSuccess(res.data.featuredProjects));
  } catch (err) {
    dispatch(getFeaturedProjectsError(err));
  }
};
