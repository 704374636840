export const REQUEST_ALL_TRANSACTIONS = 'REQUEST_ALL_TRANSACTIONS';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_ERROR = 'GET_ALL_TRANSACTIONS_ERROR';

export const REQUEST_TRANSACTION_DETAILS = 'REQUEST_TRANSACTION_DETAILS';
export const GET_TRANSACTION_DETAILS_SUCCESS =
  'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_ERROR = 'GET_TRANSACTION_DETAILS_ERROR';

export const REQUEST_TRANSACTION_EFFECTS = 'REQUEST_TRANSACTION_EFFECTS';
export const GET_TRANSACTION_EFFECTS_SUCCESS =
  'GET_TRANSACTION_EFFECTS_SUCCESS';
export const GET_TRANSACTION_EFFECTS_ERROR = 'GET_TRANSACTION_EFFECTS_ERROR';

export const REQUEST_TRANSACTION_OPERATIONS = 'REQUEST_TRANSACTION_OPERATIONS';
export const GET_TRANSACTION_OPERATIONS_SUCCESS =
  'GET_TRANSACTION_OPERATIONS_SUCCESS';
export const GET_TRANSACTION_OPERATIONS_ERROR =
  'GET_TRANSACTION_OPERATIONS_ERROR';

export const REQUEST_TRANSACTION_PAYMENTS = 'REQUEST_TRANSACTION_PAYMENTS';
export const GET_TRANSACTION_PAYMENTS_SUCCESS =
  'GET_TRANSACTION_PAYMENTS_SUCCESS';
export const GET_TRANSACTION_PAYMENTS_ERROR = 'GET_TRANSACTION_PAYMENTS_ERROR';
