import React from 'react';
import { instanceOf, string } from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import HashPopover from '../HashPopover';

import STYLES from './Signers.module.scss';

const truncateString14 = key => `${key.substring(0, 14)}...`;
const signerPopOver = key => <Popover id="signer-key">{key}</Popover>;

const Signers = ({ signers, matchId }) => (
  <div className={STYLES.Signers}>
    {signers && signers.size ? (
      <div>
        {signers.map((signer, i) => (
          <div className={STYLES.signerRow} key={i}>
            <div className={STYLES.signerItem}>
              <div className={STYLES.titleContainer}>
                <h4>
                  <Link to={`/accounts/${signer.get('key')}`}>
                    <OverlayTrigger
                      placement="top"
                      overlay={signerPopOver(signer.get('key'))}>
                      <span>
                        SIGNER - {truncateString14(signer.get('key'))}
                      </span>
                    </OverlayTrigger>
                  </Link>
                </h4>
                <div className={STYLES.label}>
                  WEIGHT {signer.get('weight')}
                </div>
              </div>
              <p className={STYLES.date}>Type: {signer.get('type')}</p>
              <div className={STYLES.flexDetails}>
                This account is signed by{' '}
                <HashPopover
                  path="accounts"
                  hash={signer.get('key')}
                  matchId={matchId}
                />
                with a weight of {signer.get('weight')}.
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className={STYLES.noSigners}>
        <div className={STYLES.largeLabel}>
          There are no Signers for this item yet.
        </div>
      </div>
    )}
  </div>
);

Signers.propTypes = {
  matchId: string,
  signers: instanceOf(List),
};

Signers.defaultProps = {
  matchId: '',
};

export default Signers;
