import {
  stellarMainNet,
  stellarTestNet,
  stellarStrKey,
} from '../../config/stellar';
import * as types from './types';
import { redirect } from 'react-router-dom';

const requestSearchValue = () => ({
  type: types.REQUEST_SEARCH_VALUE,
  payload: { isFetchingSearchValue: true },
});

const getSearchValueError = error => ({
  type: types.GET_SEARCH_VALUE_ERROR,
  payload: { error, isFetchingSearchValue: false },
});

const getSearchValueSuccess = () => ({
  type: types.GET_SEARCH_VALUE_SUCCESS,
  payload: { isFetchingSearchValue: false },
});

export const getSearchValue = inputString => async (dispatch, getState) => {
  dispatch(requestSearchValue());

  const isTestNetEnabled = getState().developer.get('isTestNetEnabled');
  const isValidAccount = stellarStrKey.isValidEd25519PublicKey(inputString);
  const isTransactionHash = /^[0-9a-f]{64}$/i.test(inputString);
  const isOperationId = inputString.length === 17 || inputString.length === 18;
  const isLedgerId = /^\d+$/.test(inputString) && inputString.length < 11;
  const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;

  if (isValidAccount) {
    dispatch(getSearchValueSuccess());
    return redirect(`/accounts/${inputString}`);
  } else if (isTransactionHash) {
    try {
      await server
        .transactions()
        .transaction(inputString)
        .call();

      dispatch(getSearchValueSuccess());
      return redirect(`/transactions/${inputString}`);
    } catch (err) {
      return dispatch(getSearchValueError(err.response.status));
    }
  } else if (isOperationId) {
    try {
      await server
        .operations()
        .operation(inputString)
        .call();

      dispatch(getSearchValueSuccess());
      return redirect(`/operations/${inputString}`);
    } catch (err) {
      return dispatch(getSearchValueError(err.response.status));
    }
  } else if (isLedgerId) {
    try {
      await server
        .ledgers()
        .ledger(inputString)
        .call();

      dispatch(getSearchValueSuccess());
      return redirect(`/ledgers/${inputString}`);
    } catch (err) {
      return dispatch(getSearchValueError(err.response.status));
    }
  } else {
    return dispatch(getSearchValueError(400));
  }
};
