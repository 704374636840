import axios from 'axios';
import queryString from 'query-string';
import {
  REQUEST_COMMUNITY_PROJECTS,
  GET_COMMUNITY_PROJECTS_ERROR,
  GET_COMMUNITY_PROJECTS_SUCCESS,
} from './types';

const getCommunityProjectsSuccess = ({ projects, projectsCount }) => ({
  type: GET_COMMUNITY_PROJECTS_SUCCESS,
  payload: {
    projects,
    projectsCount,
    isFetchingProjects: false,
  },
});

const getCommunityProjectsError = error => ({
  type: GET_COMMUNITY_PROJECTS_ERROR,
  payload: {
    error,
    isFetchingProjects: false,
  },
});

const requestCommunityProjects = () => ({
  type: REQUEST_COMMUNITY_PROJECTS,
  payload: {
    isFetchingProjects: true,
  },
});

const buildProjectQueryString = queryParams =>
  Object.keys(queryParams)
    .map(key => queryString.stringify({ [key]: queryParams[key] }))
    .join('&');

export default queryParams => async dispatch => {
  dispatch(requestCommunityProjects());

  let URL = `${process.env.REACT_APP_API_GATEWAY_URL}/projects`;

  if (queryParams) {
    URL += `?${buildProjectQueryString(queryParams)}`;
  }

  try {
    const res = await axios({
      host: process.env.REACT_APP_API_GATEWAY_HOST,
      method: 'GET',
      url: URL,
      path: '/dev/projects',
    });

    dispatch(
      getCommunityProjectsSuccess({
        projects: res.data.projects,
        projectsCount: res.data.projectsCount,
      })
    );
  } catch (err) {
    dispatch(getCommunityProjectsError(err));
  }
};
