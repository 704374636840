import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { getAllAssets } from '../../actions/asset';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import GoogleAd from '../../components/GoogleAd';
import Assets from '../../components/assets/Assets';

import STYLES from './AssetsPage.module.scss';
import { withHook } from '../../util/withHook';

const determineOrder = (assets, assetsOrder) =>
  assetsOrder === 'asc' ? assets.reverse() : assets;

class AssetsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    assets: instanceOf(List),
    assetsOrder: string,
    isFetchingAssets: bool,
    isDevModeOn: bool,
    onToggleCodeModal: func.isRequired,
    getAllAssets: func.isRequired,
  };

  static defaultProps = {
    assetsOrder: 'desc',
    isDevModeOn: false,
    isFetchingAssets: false,
  };

  constructor(props) {
    super(props);

    this.state = { currentPage: 1 };

    this.assetsTop = React.createRef();
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/assets',
        title: 'Assets',
      });
    }

    this.props.getAllAssets();
  };

  getNextPage = () => {
    const { assets } = this.props;
    const { currentPage } = this.state;
    const pagingToken = assets.get(-1).get('paging_token');
    const assetsTop = ReactDOM.findDOMNode(this.assetsTop.current).offsetTop;

    this.props.getAllAssets(pagingToken, 'desc');
    window.scrollTo(0, assetsTop - 20);
    this.setState({ currentPage: currentPage + 1 });
  };

  getPreviousPage = () => {
    const { assets } = this.props;
    const { currentPage } = this.state;
    const pagingToken = assets.get(0).get('paging_token');
    const assetsTop = ReactDOM.findDOMNode(this.assetsTop.current).offsetTop;

    this.props.getAllAssets(pagingToken, 'asc');
    window.scrollTo(0, assetsTop - 20);
    this.setState({ currentPage: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = 'StellarUp - Assets';
    const description =
      'A list containing all of the recent Assets on the Stellar cryptocurrency network';
    const assetsUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={assetsUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      assets,
      assetsOrder,
      isFetchingAssets,
      isDevModeOn,
      onToggleCodeModal,
    } = this.props;
    const { currentPage } = this.state;

    return (
      <div className={STYLES.AssetsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header} ref={this.assetsTop}>
                <h2>ASSETS</h2>
              </div>
              <div>
                <Assets
                  assets={assets}
                  assetsOrder={assetsOrder}
                  currentPage={currentPage}
                  isDevModeOn={isDevModeOn}
                  toggleCodeModal={onToggleCodeModal}
                  isFetchingAssets={isFetchingAssets}
                  onGetNextPage={this.getNextPage}
                  onGetPreviousPage={this.getPreviousPage}
                />
                <div className={STYLES.adWrapBottom}>
                  <GoogleAd placement="Bottom" adSlot="7799510885" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  assets: SELECTORS.getAllAssets(state),
  assetsOrder: SELECTORS.getAssetsOrder(state),
  isFetchingAssets: SELECTORS.getIsFetchingAssets(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getAllAssets,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetsPage))
);
