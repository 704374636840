import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { MdCheckCircle, MdError } from 'react-icons/md';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsComponent.module.scss';

const ManageSellOfferDetails = ({ operationDetails }) => (
  <div className={STYLES.DetailsComponent}>
    <h3>
      Operation Type{' '}
      <InfoToolTip
        title="Manage Sell Offer"
        info="A “Manage Sell Offer” operation can create, update or delete a sell offer to trade assets in the Stellar network. It specifies an issuer, a price and amount of a given asset to buy or sell."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.mainDetails}>Manage Sell Offer</div>
    <h3>
      Transaction Hash{' '}
      <InfoToolTip
        title="Transaction Hash"
        info="A hex-encoded SHA-256 hash of the transaction’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/operations.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {operationDetails.get('transaction_hash')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Status</span>
        <InfoToolTip
          title="Status"
          info="Indicates if transaction was successful or not."
          placement="top"
          size="15px"
          link="https://www.stellar.org/developers/guides/concepts/transactions.html"
        />
        <span className={STYLES.value}>
          {operationDetails.get('transaction_successful') ? (
            <div className={STYLES.successLabel}>
              <MdCheckCircle className={STYLES.statusIcon} /> Successful
            </div>
          ) : (
            <div className={STYLES.failedLabel}>
              <MdError className={STYLES.statusIcon} /> Failed
            </div>
          )}
        </span>
      </div>
      {operationDetails && operationDetails.get('created_at') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Created at</span>
          <InfoToolTip
            title="Created at"
            info="The timestamp of when the operation was created."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {moment(operationDetails.get('created_at')).format('LLL')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('selling_asset_issuer') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Selling Asset Issuer</span>
          <InfoToolTip
            title="Selling Asset Issuer"
            info="The issuer of asset to sell."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('selling_asset_issuer')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('buying_asset_issuer') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Buying Asset Issuer</span>
          <InfoToolTip
            title="Buying Asset Issuer"
            info="The issuer of asset to buy."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.keyBreakValue}>
            {operationDetails.get('buying_asset_issuer')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('selling_asset_type') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Selling Asset</span>
          <InfoToolTip
            title="Selling Asset"
            info="The code of asset to sell."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('selling_asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('selling_asset_code')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('buying_asset_type') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Buying Asset</span>
          <InfoToolTip
            title="Buying Asset"
            info="The code of asset to buy."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('buying_asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('buying_asset_code')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('amount') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Amount</span>
          <InfoToolTip
            title="Amount"
            info="Amount of asset to be sold."
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('amount')}{' '}
            {operationDetails.get('selling_asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('selling_asset_code')}
          </span>
        </div>
      )}
      {operationDetails && operationDetails.get('price') && (
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Price</span>
          <InfoToolTip
            title="Price"
            info="Price of selling asset in units of buying asset"
            placement="top"
            size="15px"
            link="https://www.stellar.org/developers/guides/concepts/operations.html"
          />
          <span className={STYLES.value}>
            {operationDetails.get('price')}{' '}
            {operationDetails.get('selling_asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('selling_asset_code')}
            /
            {operationDetails.get('buying_asset_type') === 'native'
              ? 'XLM'
              : operationDetails.get('buying_asset_code')}
          </span>
        </div>
      )}
    </div>
  </div>
);

ManageSellOfferDetails.propTypes = {
  operationDetails: instanceOf(Map),
};

ManageSellOfferDetails.defaultProps = {
  operationDetails: new Map(),
};

export default ManageSellOfferDetails;
