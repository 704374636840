import React from 'react';
import { instanceOf, bool } from 'prop-types';
import { List } from 'immutable';

import Project from './Project';

import STYLES from './ProjectsList.module.scss';

const ProjectsList = ({
  projectsSR,
  isFetchingProjectsSR,
  projectsSRStatus,
  projectsCount,
}) => (
  <div className={STYLES.ProjectsList}>
    {isFetchingProjectsSR ? (
      <div>
        <div className="loading-spinner-animation" />
      </div>
    ) : (
      <div>
        {projectsSR && projectsSR.size > 0 ? (
          <div>
            {projectsSR.map(project => (
              <Project
                key={project.get('name')}
                name={project.get('name')}
                url={project.get('url')}
                github={project.get('github')}
                category={project.get('category')}
                license={project.get('license')}
                sbc={project.get('sbc')}
                platform={project.get('platform')}
                project={project}
              />
            ))}
          </div>
        ) : (
          <div>
            {projectsCount === 0 && (
              <div className={STYLES.noProjects}>
                <div className={STYLES.largeLabel}>
                  Sorry, no results were found.
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )}
  </div>
);

ProjectsList.propTypes = {
  projectsSR: instanceOf(List),
  isFetchingProjectsSR: bool,
};

export default ProjectsList;
