import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsSummary.module.scss';

const filterBalances = balances => {
  const stellar = balances.filter(
    balance => balance.get('asset_type') === 'native'
  );

  return stellar.get(0).get('balance');
};

const DetailsSummary = ({ accountDetails }) => (
  <div className={STYLES.DetailsSummary}>
    <div>
      <h3>
        Account ID{' '}
        <InfoToolTip
          title="Account ID"
          info="The public key that was first used to create the account. You can replace the key used for signing the account’s transactions with a different public key, but the original account ID will always be used to identify the account."
          placement="bottom"
          link="https://www.stellar.org/developers/guides/concepts/accounts.html"
        />
      </h3>
      <div className={STYLES.keyBreak}>
        {accountDetails && accountDetails.get('account_id')}
      </div>
      <h3>
        Current Balance{' '}
        <InfoToolTip
          title="Current Balance"
          info="The number of lumens held by the account. The balance is denominated in 1/10,000,000th of a lumen, the smallest divisible unit of a lumen."
          placement="bottom"
          link="https://www.stellar.org/developers/guides/concepts/accounts.html"
        />
      </h3>
      <div className={STYLES.mainDetails}>
        {accountDetails &&
          accountDetails.get('balances') &&
          filterBalances(accountDetails.get('balances'))}{' '}
        XLM
      </div>
      <div className={STYLES.otherDetails}>
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Home Domain</span>
          <InfoToolTip
            title="Home Domain"
            info="A domain name that can optionally be added to the account. Clients can look up a stellar.toml from this domain. "
            link="https://www.stellar.org/developers/guides/concepts/accounts.html"
            placement="top"
            size="15px"
          />
          <span className={STYLES.value}>
            {accountDetails && accountDetails.get('home_domain')
              ? accountDetails.get('home_domain')
              : 'not set'}
          </span>
        </div>
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Inflation Destination</span>
          <InfoToolTip
            title="Inflation Destination"
            info="Account designated to receive inflation. Every account with a balance of at least 100 XLM can vote to send inflation to a destination account."
            link="https://www.stellar.org/developers/guides/concepts/accounts.html"
            placement="top"
            size="15px"
          />
          <span className={STYLES.value}>
            {accountDetails && accountDetails.get('inflation_destination')
              ? accountDetails.get('inflation_destination')
              : 'not set'}
          </span>
        </div>
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Asset authorization required flag</span>
          <InfoToolTip
            title="Asset authorization required flag"
            info="Requires the issuing account to give other accounts permission before they can hold the issuing account’s credit."
            link="https://www.stellar.org/developers/guides/concepts/accounts.html"
            placement="top"
            size="15px"
          />
          <span className={STYLES.value}>
            {accountDetails &&
            accountDetails.get('flags') &&
            accountDetails.getIn(['flags', 'auth_required']) === true
              ? 'true'
              : 'false'}
          </span>
        </div>
        <div className={STYLES.keyValue}>
          <span className={STYLES.key}>Asset authorization revocable flag</span>
          <InfoToolTip
            title="Asset authorization revocable flag"
            info="Allows the issuing account to revoke its credit held by other accounts."
            link="https://www.stellar.org/developers/guides/concepts/accounts.html"
            placement="top"
            size="15px"
          />
          <span className={STYLES.value}>
            {accountDetails &&
            accountDetails.get('flags') &&
            accountDetails.getIn(['flags', 'auth_revocable']) === true
              ? 'true'
              : 'false'}
          </span>
        </div>
      </div>
    </div>
  </div>
);

DetailsSummary.propTypes = {
  accountDetails: instanceOf(Map),
};

DetailsSummary.defaultProps = {};

export default DetailsSummary;
