import React, { Component } from 'react';
import { object } from 'prop-types';
// import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/lib/Button';

import STYLES from './NotFoundPage.module.scss';

class NotFoundPage extends Component {
  static propTypes = {
    location: object.isRequired,
    staticContext: object,
  };

  static defaultProps = {
    staticContext: {},
  };
  // renderHelmet() {
  //   const { transactionDetails, match: { url } } = this.props;
  //   // const title = intl.formatMessage({ id: 'accountpage.title' });
  //
  //   const title = 'Stellar Orbit - 404';
  //   const description = 'A 404 not found page to indicate that a ';
  //   const transactionUrl = `https://www.stellarorbit.com${url}`;
  //
  //   return (
  //     <Helmet>
  //       <title>{title}</title>
  //       <meta property="og:title" content={title} />
  //       <meta property="og:type" content="website" />
  //       <meta property="og:url" content={transactionUrl} />
  //       <meta property="og:description" content={description} />
  //       <meta property="og:determiner" content="" />
  //       <meta property="og:locale" content="en_US" />
  //       <meta property="og:ttl" content="345600" />
  //     </Helmet>
  //   );
  // }

  render() {
    const { staticContext } = this.props;
    staticContext.notFound = true;

    return (
      <div className={STYLES.NotFoundPage}>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div>
                <h2>Whoops! Looks like you got lost.</h2>
                <h3>We couldn&apos;t find what you were looking for.</h3>
                <Link to="/">
                  <Button className={STYLES.homeButton}>Take Me Home</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(NotFoundPage);
