import React, { Component } from 'react';
import { object } from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import ReactGA from 'react-ga4';

import Collapse from 'react-bootstrap/lib/Collapse';
import Well from 'react-bootstrap/lib/Well';
import { MdKeyboardArrowDown } from 'react-icons/md';

import HELP_MODAL_SECTIONS from '../../constants/help-modal-sections';

import STYLES from './AboutPage.module.scss';
import { withHook } from '../../util/withHook';

const determineArrowClass = (activeTab, sectionId) =>
  cx({
    [STYLES.arrowIcon]: activeTab !== sectionId,
    [STYLES.activeArrowIcon]: activeTab === sectionId,
  });

class AboutPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
  };

  state = {
    activeTab: null,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: '/about',
        title: 'About',
      });
    }
  };

  toggleActiveTab = prop => {
    const { activeTab } = this.state;

    if (activeTab === prop) return this.setState({ activeTab: null });

    this.setState({ activeTab: prop });
  };

  renderHelmet = () => {
    const {
      match: { url },
    } = this.props;

    const title = 'StellarUp - About';
    const description =
      'The about page includes everything you want to know about StellarUp. From how the website is structured to contact information.';
    const aboutUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={aboutUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const { activeTab } = this.state;

    return (
      <div className={STYLES.AboutPage}>
        {this.renderHelmet()}
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>About StellarUp</h2>
              </div>
              <div className={STYLES.aboutWrap}>
                <div className={STYLES.aboutContent}>
                  <p className={STYLES.intro}>
                    StellarUp is mobile friendly block explorer and learning
                    resource for existing and aspiring users of the Stellar
                    Network. Providing useful data visualization tools, and
                    concise tutorials to help users stay current and effective.
                  </p>
                  <h3>Website</h3>
                  {HELP_MODAL_SECTIONS.map(section => (
                    <div key={section.id}>
                      <div
                        className={STYLES.accordianTitle}
                        onClick={this.toggleActiveTab.bind(null, section.id)}>
                        <h5>{section.title}</h5>
                        <MdKeyboardArrowDown
                          className={determineArrowClass(activeTab, section.id)}
                        />
                      </div>
                      <Collapse in={activeTab === section.id}>
                        <div>
                          <Well className={STYLES.well}>
                            <p>{section.content}</p>
                            {section.link && (
                              <div>
                                <p>
                                  If you would like to learn more about{' '}
                                  {section.linkTitle}, the Stellar team has put
                                  together a great resource that can be found
                                  at:{' '}
                                </p>
                                <p>
                                  <a
                                    href={section.link}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {section.link}
                                  </a>
                                  .
                                </p>
                              </div>
                            )}
                          </Well>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                  <h3>Contact</h3>
                  <p>
                    Have a question about StellarUp? Have a great idea for us?
                    Feel free to reach out to us at{' '}
                    <a href="mailto:contact.stellarup@gmail.com">
                      contact.stellarup@gmail.com
                    </a>{' '}
                    and we will get back to you as soon as we can.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHook(injectIntl(AboutPage));
