import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { intlReducer } from 'react-intl-redux';
import uiReducer from './uiReducer';
// import userReducer from './user';
import coinReducer from './coinReducer';
import assetReducer from './assetReducer';
import searchReducer from './searchReducer';
import ledgerReducer from './ledgerReducer';
import accountReducer from './accountReducer';
import operationReducer from './operationReducer';
import developerReducer from './developerReducer';
import transactionReducer from './transactionReducer';
import communityReducer from './communityReducer';

export default combineReducers({
  ui: uiReducer,
  intl: intlReducer,
  // user: userReducer,
  coin: coinReducer,
  asset: assetReducer,
  search: searchReducer,
  ledger: ledgerReducer,
  routing: routerReducer,
  account: accountReducer,
  operation: operationReducer,
  developer: developerReducer,
  community: communityReducer,
  transaction: transactionReducer,
});
