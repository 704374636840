import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';

import InfoToolTip from '../../../components/InfoToolTip';

import STYLES from './DetailsSummary.module.scss';

const DetailsSummary = ({ ledgerDetails }) => (
  <div className={STYLES.DetailsSummary}>
    <h3>
      Ledger Hash{' '}
      <InfoToolTip
        title="Ledger Hash"
        info="A hex-encoded SHA-256 hash of the ledger’s XDR-encoded form."
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/ledger.html"
      />
    </h3>
    <div className={STYLES.keyBreakMain}>
      {ledgerDetails && ledgerDetails.get('hash')}
    </div>
    <h3>
      Ledger Sequence{' '}
      <InfoToolTip
        title="Ledger Sequence"
        info="Sequence number of this ledger"
        placement="bottom"
        link="https://www.stellar.org/developers/guides/concepts/ledger.html"
      />
    </h3>
    <div className={STYLES.mainDetails}>
      {ledgerDetails && ledgerDetails.get('sequence')}
    </div>
    <div className={STYLES.otherDetails}>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Closed at</span>
        <InfoToolTip
          title="Closed at"
          info="When this ledger was closed."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails &&
            moment(ledgerDetails.get('closed_at')).format('MMM DD YYYY')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Total lumens</span>
        <InfoToolTip
          title="Total lumens"
          info="The total number of lumens in circulation."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('total_coins')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Base fee in stroops</span>
        <InfoToolTip
          title="Base fee in stroops"
          info="The fee the network charges per operation in a transaction. Expressed in stroops."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('base_fee_in_stroops')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Base reserve in stroops</span>
        <InfoToolTip
          title="Base reserve in stroops"
          info="The reserve the network uses when calculating an account’s minimum balance. Expressed in stroops."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('base_reserve_in_stroops')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Fee pool</span>
        <InfoToolTip
          title="Fee pool"
          width="330px"
          info="The sum of all transaction fees (in lumens) since the last inflation operation. They are redistributed during inflation."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('fee_pool')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Operations</span>
        <InfoToolTip
          title="Operations"
          info="The number of operations applied in this ledger."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('operation_count')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Successful Transactions</span>
        <InfoToolTip
          title="Successful Transactions"
          info="The number of successful transactions in this ledger."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('successful_transaction_count')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Failed Transactions</span>
        <InfoToolTip
          title="Failed Transactions"
          info="The number of failed transactions in this ledger."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('failed_transaction_count')}
        </span>
      </div>
      <div className={STYLES.keyValue}>
        <span className={STYLES.key}>Protocol version</span>
        <InfoToolTip
          title="Protocol version"
          info="The protocol version that the stellar network was running when this ledger was committed."
          link="https://www.stellar.org/developers/guides/concepts/ledger.html"
          placement="top"
          size="15px"
        />
        <span className={STYLES.value}>
          {ledgerDetails && ledgerDetails.get('protocol_version')}
        </span>
      </div>
    </div>
  </div>
);

DetailsSummary.propTypes = {
  ledgerDetails: instanceOf(Map),
};

DetailsSummary.defaultProps = {
  ledgerDetails: new Map(),
};

export default DetailsSummary;
