import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_OPERATION_EFFECTS,
  GET_OPERATION_EFFECTS_ERROR,
  GET_OPERATION_EFFECTS_SUCCESS,
} from './types';

const getOperationEffectsSuccess = ({
  operationEffects,
  operationEffectsOrder,
  isMoreOperationEffects,
}) => ({
  type: GET_OPERATION_EFFECTS_SUCCESS,
  payload: {
    operationEffects,
    operationEffectsOrder,
    isMoreOperationEffects,
    isFetchingOperationEffects: false,
  },
});

const getOperationEffectsError = error => ({
  type: GET_OPERATION_EFFECTS_ERROR,
  payload: { error, isFetchingOperationEffects: false },
});

const requestOperationEffects = () => ({
  type: REQUEST_OPERATION_EFFECTS,
  payload: { isFetchingOperationEffects: true },
});

export default (operationId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestOperationEffects());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const operationsOrder = order ? order : 'desc';

    const res = await server
      .effects()
      .cursor(cursor)
      .order(operationsOrder)
      .limit(10)
      .forOperation(operationId)
      .call();

    const nextPage =
      operationsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getOperationEffectsSuccess({
        operationEffects: res.records,
        operationEffectsOrder: operationsOrder,
        isMoreOperationEffects: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getOperationEffectsError(err));
  }
};
