import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_TRANSACTION_PAYMENTS,
  GET_TRANSACTION_PAYMENTS_ERROR,
  GET_TRANSACTION_PAYMENTS_SUCCESS,
} from './types';

const getTransactionPaymentsSuccess = ({
  transactionPayments,
  transactionPaymentsOrder,
  isMoreTransactionPayments,
}) => ({
  type: GET_TRANSACTION_PAYMENTS_SUCCESS,
  payload: {
    transactionPayments,
    transactionPaymentsOrder,
    isMoreTransactionPayments,
    isFetchingTransactionPayments: false,
  },
});

const getTransactionPaymentsError = error => ({
  type: GET_TRANSACTION_PAYMENTS_ERROR,
  payload: { error, isFetchingTransactionPayments: false },
});

const requestTransactionPayments = () => ({
  type: REQUEST_TRANSACTION_PAYMENTS,
  payload: { isFetchingTransactionPayments: true },
});

export default (transactionId, pagingToken, order) => async (
  dispatch,
  getState
) => {
  dispatch(requestTransactionPayments());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const paymentsOrder = order ? order : 'desc';

    const res = await server
      .payments()
      .cursor(cursor)
      .order(paymentsOrder)
      .limit(10)
      .forTransaction(transactionId)
      .call();
    const nextPage =
      paymentsOrder === 'desc' ? await res.next() : await res.prev();

    dispatch(
      getTransactionPaymentsSuccess({
        transactionPayments: res.records,
        transactionPaymentsOrder: paymentsOrder,
        isMoreTransactionPayments: !!nextPage.records.length,
      })
    );
  } catch (err) {
    dispatch(getTransactionPaymentsError(err));
  }
};
