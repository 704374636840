import * as types from './types';

export const toggleSidebar = sidebarState => ({
  type: types.TOGGLE_SIDEBAR,
  payload: {
    isSidebarOpen: !sidebarState,
  },
});

export const setGraphTimePeriod = graphTimePeriod => ({
  type: types.SET_GRAPH_TIME_PERIOD,
  payload: {
    graphTimePeriod,
  },
});

export const toggleCodeModal = activeCodeModal => ({
  type: types.TOGGLE_CODE_MODAL,
  payload: {
    activeCodeModal,
  },
});
