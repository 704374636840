import { Map } from 'immutable';
import * as types from '../actions/coin/types';

const ACTIONS = {
  // Stellar Coin
  [types.REQUEST_COIN_STATS]: (state, action) => {
    const { isFetchingCoinStats } = action.payload;
    return state.merge({ isFetchingCoinStats });
  },
  [types.GET_COIN_STATS_ERROR]: (state, action) => {
    const { error, isFetchingCoinStats } = action.payload;
    return state.merge({ error, isFetchingCoinStats });
  },
  [types.GET_COIN_STATS_SUCCESS]: (state, action) => {
    const { coinStats, isFetchingCoinStats } = action.payload;
    return state.merge({ coinStats, isFetchingCoinStats });
  },
  // Coin History
  [types.REQUEST_COIN_HISTORY]: (state, action) => {
    const { isFetchingCoinHistory } = action.payload;
    return state.merge({ isFetchingCoinHistory });
  },
  [types.GET_COIN_HISTORY_ERROR]: (state, action) => {
    const { error, isFetchingCoinHistory } = action.payload;
    return state.merge({ error, isFetchingCoinHistory });
  },
  [types.GET_COIN_HISTORY_SUCCESS]: (state, action) => {
    const {
      weekHistory,
      monthHistory,
      yearHistory,
      isFetchingCoinHistory,
    } = action.payload;
    return state.merge({
      weekHistory,
      monthHistory,
      yearHistory,
      isFetchingCoinHistory,
    });
  },
};

export default function coinsReducer(state = new Map(), action) {
  return ACTIONS[action.type] ? ACTIONS[action.type](state, action) : state;
}
