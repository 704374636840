import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ALL_LEDGERS,
  GET_ALL_LEDGERS_ERROR,
  GET_ALL_LEDGERS_SUCCESS,
} from './types';

const getAllLedgersSuccess = ({ ledgers, ledgersOrder }) => ({
  type: GET_ALL_LEDGERS_SUCCESS,
  payload: {
    ledgers,
    ledgersOrder,
    isFetchingLedgers: false,
  },
});

const getAllLedgersError = error => ({
  type: GET_ALL_LEDGERS_ERROR,
  payload: { error, isFetchingLedgers: false },
});

const requestAllLedgers = () => ({
  type: REQUEST_ALL_LEDGERS,
  payload: { isFetchingLedgers: true },
});

export default (pagingToken, order) => async (dispatch, getState, api) => {
  dispatch(requestAllLedgers());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const ledgersOrder = order ? order : 'desc';

    const res = await server
      .ledgers()
      .cursor(cursor)
      .order(ledgersOrder)
      .limit(10)
      .call();

    dispatch(
      getAllLedgersSuccess({
        ledgers: res.records,
        ledgersOrder,
      })
    );
  } catch (err) {
    dispatch(getAllLedgersError(err));
  }
};
