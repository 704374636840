import { stellarMainNet, stellarTestNet } from '../../config/stellar';
import {
  REQUEST_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_ERROR,
  GET_ALL_TRANSACTIONS_SUCCESS,
} from './types';

const getAllTransactionsSuccess = ({ transactions, transactionsOrder }) => ({
  type: GET_ALL_TRANSACTIONS_SUCCESS,
  payload: {
    transactions,
    transactionsOrder,
    isFetchingTransactions: false,
  },
});

const getAllTransactionsError = error => ({
  type: GET_ALL_TRANSACTIONS_ERROR,
  payload: { error, isFetchingTransactions: false },
});

const requestAllTransactions = () => ({
  type: REQUEST_ALL_TRANSACTIONS,
  payload: { isFetchingTransactions: true },
});

export default (pagingToken, order) => async (dispatch, getState) => {
  dispatch(requestAllTransactions());

  try {
    const { isTestNetEnabled } = getState().developer;
    const server = isTestNetEnabled ? stellarTestNet : stellarMainNet;
    const cursor = pagingToken ? pagingToken : '';
    const transactionsOrder = order ? order : 'desc';

    const res = await server
      .transactions()
      .cursor(cursor)
      .order(transactionsOrder)
      .limit(10)
      .call();

    dispatch(
      getAllTransactionsSuccess({
        transactions: res.records,
        transactionsOrder,
      })
    );
  } catch (e) {
    dispatch(getAllTransactionsError(e));
  }
};
