import { useLocation, useParams } from 'react-router-dom';

export const withHook = Component => {
  return function WrappedComponent(props) {
    const params = useParams();
    const location = useLocation();

    const match = { params, url: location.pathname };

    return <Component {...props} match={match} />;
  };
};
