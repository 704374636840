import React, { Component } from 'react';
import { instanceOf, object, string, bool, func } from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import {
  getLedgerDetails,
  getLedgerEffects,
  getLedgerOperations,
  getLedgerTransactions,
  getLedgerPayments,
} from '../../actions/ledger';
import { toggleCodeModal } from '../../actions/ui';
import * as SELECTORS from '../../selectors';

import InfoToolTip from '../../components/InfoToolTip';
import GoogleAd from '../../components/GoogleAd';
import CodeModal from '../../components/CodeModal';
import CodeModalToggle from '../../components/CodeModalToggle';
import TabbedList from './components/TabbedList';
import DetailsSummary from './components/DetailsSummary';

import STYLES from './LedgerDetailsPage.module.scss';
import { withHook } from '../../util/withHook';

class LedgerDetailsPage extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    ledgerDetails: instanceOf(Map),
    ledgerEffects: instanceOf(List),
    ledgerOperations: instanceOf(List),
    ledgerTransactions: instanceOf(List),
    ledgerPayments: instanceOf(List),
    isDevModeOn: bool,
    ledgerOperationsOrder: string,
    ledgerEffectsOrder: string,
    ledgerTransactionsOrder: string,
    ledgerPaymentsOrder: string,
    isMoreLedgerOperations: bool,
    isMoreLedgerEffects: bool,
    isMoreLedgerTransactions: bool,
    isMoreLedgerPayments: bool,
    isFetchingLedgerDetails: bool,
    isFetchingLedgerEffects: bool,
    isFetchingLedgerOperations: bool,
    isFetchingLedgerTransactions: bool,
    isFetchingLedgerPayments: bool,
    getLedgerDetails: func.isRequired,
    getLedgerEffects: func.isRequired,
    getLedgerOperations: func.isRequired,
    getLedgerTransactions: func.isRequired,
    getLedgerPayments: func.isRequired,
    onToggleCodeModal: func.isRequired,
  };

  static defaultProps = {
    ledgerDetails: new Map(),
    ledgerEffects: new List(),
    ledgerOperations: new List(),
    ledgerTransactions: new List(),
    ledgerPayments: new List(),
    ledgerOperationsOrder: 'desc',
    ledgerEffectsOrder: 'desc',
    ledgerTransactionsOrder: 'desc',
    ledgerPaymentsOrder: 'desc',
    isMoreLedgerOperations: false,
    isMoreLedgerEffects: false,
    isMoreLedgerTransactions: false,
    isMoreLedgerPayments: false,
    isDevModeOn: false,
    isFetchingLedgerDetails: false,
    isFetchingLedgerEffects: false,
    isFetchingLedgerOperations: false,
    isFetchingLedgerTransactions: false,
    isFetchingLedgerPayments: false,
  };

  state = {
    ledgerEffectsPage: 1,
    ledgerOperationsPage: 1,
    ledgerTransactionsPage: 1,
    ledgerPaymentsPage: 1,
  };

  componentDidMount = () => {
    const {
      match: { params, url },
    } = this.props;

    window.scrollTo(0, 0);

    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: url,
        title: 'Ledger Details',
      });
    }

    this.props.getLedgerDetails(params.ledgerId);
    this.props.getLedgerEffects(params.ledgerId);
    this.props.getLedgerOperations(params.ledgerId);
    this.props.getLedgerTransactions(params.ledgerId);
    this.props.getLedgerPayments(params.ledgerId);
  };

  getNextPage = prop => {
    const {
      match: { params },
    } = this.props;

    const category = this.props[`ledger${prop}`];
    const currentPage = this.state[`ledger${prop}Page`];
    const pagingToken = category.get(-1).get('paging_token');

    this.props[`getLedger${prop}`](params.ledgerId, pagingToken, 'desc');
    this.setState({ [`ledger${prop}Page`]: currentPage + 1 });
  };

  getPreviousPage = prop => {
    const {
      match: { params },
    } = this.props;
    const category = this.props[`ledger${prop}`];
    const currentPage = this.state[`ledger${prop}Page`];
    const pagingToken = category.get(0).get('paging_token');

    this.props[`getLedger${prop}`](params.ledgerId, pagingToken, 'asc');
    this.setState({ [`ledger${prop}Page`]: currentPage - 1 });
  };

  renderHelmet = () => {
    const {
      ledgerDetails,
      match: { url },
    } = this.props;
    const ledgerSequence = ledgerDetails && ledgerDetails.sequence;
    // const title = intl.formatMessage({ id: 'accountpage.title' });

    const title = `StellarUp - Ledger ${ledgerSequence}`;
    const description = `A detailed look into Stellar Lumens (XLM) cryptocurrency ledger ${ledgerSequence}. Includes information on ledger operations, transactions, effects, payments, and more.`;
    const ledgerUrl = `https://www.stellarup.com${url}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ledgerUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:determiner" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:ttl" content="345600" />
      </Helmet>
    );
  };

  render() {
    const {
      ledgerOperationsPage,
      ledgerEffectsPage,
      ledgerTransactionsPage,
      ledgerPaymentsPage,
    } = this.state;
    const {
      ledgerDetails,
      ledgerEffects,
      ledgerOperations,
      ledgerTransactions,
      ledgerPayments,
      isDevModeOn,
      ledgerOperationsOrder,
      ledgerEffectsOrder,
      ledgerTransactionsOrder,
      ledgerPaymentsOrder,
      isMoreLedgerOperations,
      isMoreLedgerEffects,
      isMoreLedgerTransactions,
      isMoreLedgerPayments,
      isFetchingLedgerDetails,
      isFetchingLedgerEffects,
      isFetchingLedgerOperations,
      isFetchingLedgerTransactions,
      isFetchingLedgerPayments,
      onToggleCodeModal,
    } = this.props;

    return (
      <div className={STYLES.LedgerDetailsPage}>
        {this.renderHelmet()}
        <div className={STYLES.adWrap}>
          <GoogleAd placement="Top" adSlot="7799510885" />
        </div>
        <div className={STYLES.pageWrapper}>
          <div className={STYLES.contentWrapper}>
            <div className={STYLES.content}>
              <div className={STYLES.header}>
                <h2>Ledger Details</h2>
                <InfoToolTip
                  title="Ledger"
                  info="A ledger represents the state of the Stellar universe at a given point in time. It contains the list of all the accounts and balances, all the orders in the distributed exchange, and any other data that persists."
                  link="https://www.stellar.org/developers/guides/concepts/ledger.html"
                  width="300px"
                  placement="bottom"
                  size="20px"
                />
                {isDevModeOn && (
                  <CodeModalToggle
                    id={ledgerDetails.get('id')}
                    toggleCodeModal={onToggleCodeModal}
                    buttonSize="large"
                  />
                )}
              </div>
              {isFetchingLedgerDetails ? (
                <div className={STYLES.detailsLoading}>
                  <div className="loading-spinner-animation" />
                </div>
              ) : (
                <div className={STYLES.detailWrap}>
                  <DetailsSummary ledgerDetails={ledgerDetails} />
                  <TabbedList
                    ledgerEffects={ledgerEffects}
                    ledgerEffectsPage={ledgerEffectsPage}
                    ledgerOperations={ledgerOperations}
                    ledgerTransactions={ledgerTransactions}
                    ledgerTransactionsPage={ledgerTransactionsPage}
                    ledgerPayments={ledgerPayments}
                    ledgerPaymentsPage={ledgerPaymentsPage}
                    ledgerOperationsPage={ledgerOperationsPage}
                    ledgerOperationsOrder={ledgerOperationsOrder}
                    ledgerEffectsOrder={ledgerEffectsOrder}
                    ledgerTransactionsOrder={ledgerTransactionsOrder}
                    ledgerPaymentsOrder={ledgerPaymentsOrder}
                    isMoreLedgerOperations={isMoreLedgerOperations}
                    isMoreLedgerEffects={isMoreLedgerEffects}
                    isMoreLedgerTransactions={isMoreLedgerTransactions}
                    isMoreLedgerPayments={isMoreLedgerPayments}
                    isDevModeOn={isDevModeOn}
                    isFetchingLedgerEffects={isFetchingLedgerEffects}
                    isFetchingLedgerOperations={isFetchingLedgerOperations}
                    isFetchingLedgerTransactions={isFetchingLedgerTransactions}
                    isFetchingLedgerPayments={isFetchingLedgerPayments}
                    toggleCodeModal={onToggleCodeModal}
                    onGetNextPage={this.getNextPage}
                    onGetPreviousPage={this.getPreviousPage}
                  />
                  {isDevModeOn && (
                    <CodeModal name="ledgerDetails" code={ledgerDetails} />
                  )}
                  <div className={STYLES.adWrapBottom}>
                    <GoogleAd placement="Bottom" adSlot="7799510885" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ledgerDetails: SELECTORS.getLedgerDetails(state),
  ledgerEffects: SELECTORS.getLedgerEffects(state),
  ledgerOperations: SELECTORS.getLedgerOperations(state),
  ledgerTransactions: SELECTORS.getLedgerTransactions(state),
  ledgerPayments: SELECTORS.getLedgerPayments(state),
  ledgerOperationsOrder: SELECTORS.getLedgerOperationsOrder(state),
  ledgerEffectsOrder: SELECTORS.getLedgerEffectsOrder(state),
  ledgerTransactionsOrder: SELECTORS.getLedgerTransactionsOrder(state),
  ledgerPaymentsOrder: SELECTORS.getLedgerPaymentsOrder(state),
  isMoreLedgerOperations: SELECTORS.getIsMoreLedgerOperations(state),
  isMoreLedgerEffects: SELECTORS.getIsMoreLedgerEffects(state),
  isMoreLedgerTransactions: SELECTORS.getIsMoreLedgerTransactions(state),
  isMoreLedgerPayments: SELECTORS.getIsMoreLedgerPayments(state),
  isFetchingLedgerDetails: SELECTORS.getIsFetchingLedgerDetails(state),
  isFetchingLedgerEffects: SELECTORS.getIsFetchingLedgerEffects(state),
  isFetchingLedgerOperations: SELECTORS.getIsFetchingLedgerOperations(state),
  isFetchingLedgerTransactions: SELECTORS.getIsFetchingLedgerTransactions(
    state
  ),
  isFetchingLedgerPayments: SELECTORS.getIsFetchingLedgerPayments(state),
  isDevModeOn: SELECTORS.getIsDevModeOn(state),
});

const mapDispatchToProps = {
  getLedgerDetails,
  getLedgerEffects,
  getLedgerOperations,
  getLedgerTransactions,
  getLedgerPayments,
  onToggleCodeModal: toggleCodeModal,
};

export default withHook(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(LedgerDetailsPage))
);
